import Vue from 'vue';
import { Store } from 'vuex';

import { hasUCConsentBanner } from '@/helpers/tracking';
import { removeDuplicateByType } from '@/helpers/tracking/util';

import { ApplicationContextInput, ObjectType } from '@/@types/graphql-types';

import { OnboardingStatus } from '@/enums/onboarding-status';

import Provider from '@/interfaces/provider';
import { HtmlContentMovieDetails, UrlMetadataResponse } from '@/interfaces/responses/url-metadata';
import { Episode, NewTitle, Season, TitleObjectType } from '@/interfaces/titles';
import { until } from '@vueuse/core';

import {
	LegacySnowplowPushContext,
	SnowplowCampaignContextV1,
	SnowplowCampaignContextV2,
	SnowplowContext,
	SnowplowDevelopmentTestContext,
	SnowplowFilterContext,
	SnowplowFreemiumContext,
	SnowplowLoginContext,
	SnowplowModuleContext,
	SnowplowPackageContext,
	SnowplowPageTypeContext,
	SnowplowPushContext,
	SnowplowTitleContext,
	SnowplowTitleContextGraphql,
	SnowplowAudienceContext,
	SnowplowRecommenderContext,
	SnowplowHashedLoggedInUserContext,
} from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingProvider, TrackingProviderPropertiesInterface } from '@/helpers/tracking/providers/tracking-provider';

import { TitleListName } from '@/interfaces/responses/title-list';
import { ReadyType } from '@/stores/ready.store';
import { Consent, CONSENT_PARTNERS, EMAIL_HASH_KEY } from '@/stores/user.store';

import { PageType } from '@/routing/config';
import { APP_COMMIT_HASH, APP_PLATFORM, APP_VERSION } from '@/stores/app.store';
import { getVm } from '@/helpers/vm-helper';
import { TitleContentType } from '@/interfaces/snowplow/title-context';
import { TitleDetailState } from '@/stores/title-details.store';
import { paToParentIDMap } from './util/paParentMapping';
import { Route } from 'vue-router';
import { useUserStats } from '@/helpers/composables/useUserStats';

interface TrackingSession {
	context: SnowplowCampaignContextV1 | SnowplowCampaignContextV2 | LegacySnowplowPushContext | SnowplowPushContext;
	timestamp: number;
}

export type TrackingListEventPayload = { action: string; property?: string | 'similartitles' };

export interface GetTitleContextArguments {
	jwEntityId?: string;
	titleId: number;
	objectType: ObjectType;
	seasonNumber?: number;
	episodeNumber?: number;
	contentType?: TitleContentType | undefined;
}

/**
 * Simple wrapper around different event tracking services which provides
 * a streamlined interface for tracking events and page views.
 */
class TrackingHelperClass {
	private trackers: TrackingProvider[] = [];
	private store: Store<any>;
	private sessions: TrackingSession[] = [];
	private sessionDuration = 60 * 30 * 1000; // 30 minutes
	private notBounced = false;

	private isInitialized = false;
	private resolveInitializedPromise: (value: boolean) => void;
	isInitializedPromise = new Promise<boolean>(res => (this.resolveInitializedPromise = res));

	private setInitialized() {
		this.resolveInitializedPromise(true);
		this.isInitialized = true;
	}

	/**
	 * interim solution to resets the global state of the tracking helper.
	 * in a perfect world we have the trackinghelper included/merged and reinitialised in the tracking store.
	 */
	resetState() {
		this.sessions = [];
		this.notBounced = false;
	}

	get isDisabled(): boolean {
		return !!process.env.VUE_APP_BOOK;
	}

	setStore(store: Store<any>) {
		this.store = store;
	}

	/**
	 * jwId has been resolved and will now be used to initialize all the trackers and process the queue.
	 */
	async initializeTrackers(trackers: TrackingProvider[], force: boolean = false) {
		const { store } = this;
		const { language } = store.state;
		const _hasUCConsentBanner = hasUCConsentBanner(language.webLocale);

		// await with loading tracking libs until any consent is accepted or rejected
		if (!global.VUE_APP_TESTS_E2E && _hasUCConsentBanner) {
			if (!force) await Vue.$jw.ready?.waitFor(ReadyType.CONSENT_PARTNERS_ANSWERED);
		}

		const initTrackerPromises = trackers
			.filter(
				tracker =>
					// either it's not in a GDPR country
					!_hasUCConsentBanner ||
					// or it is and its DPS has been consented to
					(_hasUCConsentBanner &&
						(tracker.dataProcessingService === null ||
							store.getters['user/dpsConsents']![tracker.dataProcessingService]))
			)
			.map(async (tracker: TrackingProvider): Promise<TrackingProvider | boolean> => {
				try {
					await tracker.initialize();
					return tracker;
				} catch (err) {
					console.error('[Tracker runtime error: initialize', err);
					return true;
				}
			});

		this.trackers = (await Promise.all(initTrackerPromises)).filter(t => t !== true) as TrackingProvider[];
		this.setInitialized();

		// possibly add campaign contexts
		await this.handleAddingCampaignContexts();

		this.processQueue();
	}

	async registerTracker(newTracker: TrackingProvider) {
		// check if tracker is already initialized
		const existingTracker = this.trackers.find(tracker => tracker.constructor === newTracker.constructor);
		if (existingTracker) return;

		await newTracker.initialize();
		this.trackers.push(newTracker);
	}

	async unRegisterTracker(tracker: TrackingProvider) {
		// filter out tracker to be unregistered
		this.trackers = this.trackers.filter(existingTracker => existingTracker.constructor !== tracker.constructor);
	}

	private async processQueue() {
		// MERGE NOTE: re enable this for snowplow upgrade as experiment
		// await Vue.$jw.ready?.waitFor(ReadyType.SNOWPLOW_INIT);

		const { store } = this;
		if (this.isInitialized) {
			// most events are the start of more CPU intense tasks.
			// in order to not block main thread even more, we'll await until the most critical tasks are done (500ms drove better results than $nextTick).
			await new Promise(resolve => setTimeout(resolve, 500));

			// page views
			store.getters['tracking/getQueueOf']('pageViews').forEach((properties: any) => {
				this.trackers.forEach((tracker: TrackingProvider) => {
					try {
						tracker.trackPage.apply(tracker, [properties]);
					} catch (err) {
						console.error('[Tracker runtime error: pageViews', err);
					}
				});
			});

			this.store.dispatch('tracking/resetQueue', 'pageViews');

			// events
			store.getters['tracking/getQueueOf']('events').forEach((event: any) => {
				const { category, properties, contexts } = event;
				this.trackers.forEach((tracker: TrackingProvider) => {
					try {
						tracker.trackEvent(category, properties, contexts);
					} catch (err) {
						console.error('[Tracker runtime error: events', err);
					}
				});
			});

			this.store.dispatch('tracking/resetQueue', 'events');
		}
	}

	async trackPage(path: string, additionalContexts: SnowplowContext[] = []) {
		const { store } = this;
		const { routing, tracking, titleDetails } = this.store.state;

		const title = routing.urlMetadata?.meta_title || 'Justwatch';
		const titleId = titleDetails.titleContextArguments?.jwEntityId;

		// wait for metaDataPromise
		await routing.urlMetadataPromise;

		const activeRoute = (routing.activeRoute || {}) as Route;

		// Refferal Module Context
		const referralModuleContext: SnowplowModuleContext = store.getters['titleDetails/refferalModuleContext'];
		const pageContexts: SnowplowContext[] = referralModuleContext ? [referralModuleContext] : [];
		let contexts: SnowplowContext[] = await this.getContexts(pageContexts, path);

		if (additionalContexts.length) {
			contexts = contexts.concat(additionalContexts);
		}

		if (tracking.pagesVisited > 1) {
			await this.checkNotBounced();
		}

		const isLoggedIn = store.getters['user/isLoggedIn'];

		// for now titledetailpage events are fired from the page component, so activeRoute should always be available.
		// Otherwise it may make sense to use metaContexts.includes('title')

		const pageHasPermanentAudiences =
			activeRoute.name?.includes('detail') || activeRoute.name?.includes('subgenre');
		if (pageHasPermanentAudiences && isLoggedIn) {
			const userContexts = this.getLoggedInUserContexts();
			contexts = contexts.concat(userContexts);
		}

		const permanent_audiences = store.getters['titleDetails/getPermanentAudiences'];

		if (permanent_audiences.length) {
			const audienceContext = await this.getAudienceContext(permanent_audiences);
			contexts = contexts.concat(audienceContext);
		}

		// add subgenre contexts separately
		const permanent_audiences_subgenres = store.getters['user/getPermanentAudiencesSubgenres'];

		if (permanent_audiences_subgenres.length && activeRoute.name?.includes('subgenre')) {
			const audienceContext = await this.getAudienceContext(permanent_audiences_subgenres);
			contexts = contexts.concat(audienceContext);
		}

		const pageViewEvent = { path, title, permanent_audiences, contexts, titleId };
		this.store.dispatch('tracking/addPageView', pageViewEvent);

		this.processQueue();
	}

	// fire a dummy pageView event with pageType 'VIEW_AUDIENCE_REFRESH' on first pageView to send historic Permanent Audiences
	async trackAudienceRefresh(path: string, additionalContexts: SnowplowContext[] = []) {
		const { routing, titleDetails } = this.store.state;
		const title = routing.urlMetadata?.meta_title || 'Justwatch';
		const titleId = titleDetails.titleContextArguments?.jwEntityId;

		await routing.urlMetadataPromise;

		const referralModuleContext: SnowplowModuleContext = this.store.getters['titleDetails/refferalModuleContext'];
		const pageContexts: SnowplowContext[] = referralModuleContext ? [referralModuleContext] : [];
		let contexts: SnowplowContext[] = this.getContextsWithForcedPageType(pageContexts, 'VIEW_AUDIENCE_REFRESH');

		if (additionalContexts.length) {
			contexts = contexts.concat(additionalContexts);
		}

		const pageViewEvent = { path, title, permanent_audiences: [], contexts, titleId };
		this.store.dispatch('tracking/addPageView', pageViewEvent);

		this.processQueue();
	}

	refreshPermanentAudiences(userPermanentAudiences: string[]) {
		// new audiences get added as a user visits pages
		const sessionPermanentAudiences: string[] = this.store.getters['titleDetails/getPermanentAudiences'];
		const permanentAudiences = new Set([...userPermanentAudiences, ...sessionPermanentAudiences]);
		this.trackers.forEach((tracker: TrackingProvider) => {
			try {
				tracker.refreshPermanentAudiences.apply(tracker, [[...permanentAudiences]]);
			} catch (err) {
				console.error('[Tracker runtime error: refreshPermanentAudiences', err);
			}
		});
	}

	refreshPermanentAudiencesSubgenres(userPermanentAudiences: string[]) {
		// new audiences get added as a user visits pages
		const sessionPermanentAudiences: string[] = this.store.getters['user/getPermanentAudiencesSubgenres'];
		const permanentAudiences = new Set([...userPermanentAudiences, ...sessionPermanentAudiences]);
		this.trackers.forEach((tracker: TrackingProvider) => {
			try {
				tracker.refreshPermanentAudiencesSubgenres.apply(tracker, [[...permanentAudiences]]);
			} catch (err) {
				console.error('[Tracker runtime error: refreshPermanentAudiencesSubgenres', err);
			}
		});
	}

	/**
	 * Tracks a structured event.
	 * NOTE: Use without await, as it doesn't make sense for user to wait on this.
	 * @TODO check if tracking is disabled in consents
	 */
	async trackEvent(
		category: string,
		properties: TrackingProviderPropertiesInterface,
		contexts: SnowplowContext[] = [],
		force: boolean = false
	) {
		if (this.isDisabled) {
			return;
		}

		const { routing } = this.store.state;

		// argument validation
		if (!category) {
			throw new Error('Expected "category" to be a non-empty string value');
		}

		// make sure the correct urlMetadata is loaded and is not fired too early, too
		if (!force) await routing.urlMetadataPromise;

		properties.path = routing.activeRoute?.fullPath;
		if (!properties.path) {
			return;
		}
		contexts = await this.getContexts(contexts, properties.path, properties.useLastRoute);

		const trackEvent = { category, properties, contexts };

		// interacting with the consent modal should not count towards our Boune measurements
		if (category === 'consent') properties.nonInteraction = true;

		if (!properties.nonInteraction) {
			await this.checkNotBounced();
		}

		this.store.dispatch('tracking/addTrackEvent', trackEvent);

		// not bounced events are triggered by other events already,
		// so they don't need to additionally processQueue, it's done by the initiator already.
		if (category !== 'not_bounced') {
			this.processQueue();
		}

		// TODO: remove the if-statement as soon as the reason for this problem
		// snowplow tracking error is figured out
		// reference trello card 121-snowplow-tracking-erroneous-home-page-view-events
		// if (category === 'home_page_view' && properties.path !== '/') {
		// 	const currentEvent = {
		// 		category,
		// 		properties,
		// 	};
		// 	captureMessageForSentry('Home page view fired with wrong path', currentEvent);
		// 	console.log('Home page view fired with wrong path', properties.path, currentEvent);
		// }
	}

	async trackConsent(consents: Record<Consent, boolean>) {
		if (typeof window === 'undefined') {
			return Promise.resolve('Unknown');
		}

		(Object.keys(consents) as Consent[]).forEach(consentName => {
			const consentType = consents[consentName] ? 'trackConsentGranted' : 'trackConsentWithdrawn';
			const consentId = CONSENT_PARTNERS[consentName].id;
			const all = false;
			const version = '1';
			const name = null;
			const description = null;
			const date = new Date();
			date.setMonth(date.getMonth() + 50);
			const expiry = date.toISOString();

			if (consentType === 'trackConsentGranted') {
				window.snowplow(consentType, consentId, version, name, description, expiry, []);
			} else if (consentType === 'trackConsentWithdrawn') {
				window.snowplow(consentType, all, consentId, version, name, description, []);
			}
		});
	}

	async trackFilterToggleEvent(
		eventProperties: { label: string; property: any; value?: 0 | 1 },
		isPremiumFilter: boolean
	) {
		const { label, property, value } = eventProperties;

		const contexts = [];
		if (isPremiumFilter) {
			const { getFreemiumContext } = await import('@/helpers/iap-helper');
			contexts.push(getFreemiumContext());
		}

		this.trackEvent(
			'userinteraction',
			{
				action: 'filter_toggled',
				label,
				property: property ?? 'all',
				value,
			},
			contexts
		);
	}

	async trackListEvent(
		listName: TitleListName,
		listAction: 'add' | 'remove',
		payload: TrackingListEventPayload,
		contexts: SnowplowContext[]
	) {
		this.trackEvent(`${listName}_${listAction}`, payload, contexts);
	}

	async trackFreemiumEvent(
		category: string,
		properties: TrackingProviderPropertiesInterface,
		contexts: SnowplowContext[] = []
	) {
		const { getFreemiumContext } = await import('@/helpers/iap-helper');
		this.trackEvent(category, properties, [getFreemiumContext(), ...contexts]);
	}

	async getContexts(
		contexts: SnowplowContext[],
		pathname?: string,
		useLastRoute = false
	): Promise<SnowplowContext[]> {
		// create an array of Snowplow context type (strings)
		const typeList = contexts.map(({ schema }) => schema);

		const baseContexts = await this.getBaseContexts(pathname, useLastRoute);
		const filteredBaseContexts = baseContexts.filter(removeDuplicateByType(typeList));
		const additionalContexts = this.getAdditionalContexts().filter(removeDuplicateByType(typeList));

		return [...contexts, ...filteredBaseContexts, ...additionalContexts];
	}

	async getBaseContexts(pathname?: string, useLastRoute?: boolean): Promise<SnowplowContext[]> {
		const { store } = this;
		const baseContexts: SnowplowContext[] = [];

		if (pathname) {
			const pageTypeContext: SnowplowPageTypeContext = await this.getPageTypeContext(pathname, useLastRoute);
			baseContexts.push(pageTypeContext);
		}

		// TEST CONTEXT FOR DEV AND STAGING ENVIRONMENTS
		if (JW_CONFIG.DOMAIN === 'moviecycle.com') {
			const testIdentifier = 'development';
			const testContext = new SnowplowDevelopmentTestContext(true, testIdentifier);
			baseContexts.push(testContext);
		}

		// PROPERTY BASED
		// - login
		if (store.state.user.jwLoginId) {
			baseContexts.push(new SnowplowLoginContext(store.state.user.jwLoginId));
		}

		return baseContexts;
	}

	getLoggedInUserContexts(): SnowplowContext[] {
		if (!process.client) return [];
		// get hashed user email on title pages
		const hash = JSON.parse(localStorage.getItem(EMAIL_HASH_KEY) || 'null');
		if (!hash) return [];

		return [new SnowplowHashedLoggedInUserContext(hash)];
	}

	getContextsWithForcedPageType(contexts: SnowplowContext[], pageType: string): SnowplowContext[] {
		const { store } = this;
		const baseContexts: SnowplowContext[] = [];
		// create an array of Snowplow context type (strings)
		const typeList = contexts.map(({ schema }) => schema);

		// PAGE CONTEXT
		if (pageType) {
			const pageTypeContext: SnowplowPageTypeContext = this.getForcedPageTypeContext(pageType);
			baseContexts.push(pageTypeContext);
		}

		// TEST CONTEXT FOR DEV AND STAGING ENVIRONMENTS
		if (JW_CONFIG.DOMAIN === 'moviecycle.com') {
			const testIdentifier = 'development';
			const testContext = new SnowplowDevelopmentTestContext(true, testIdentifier);
			baseContexts.push(testContext);
		}

		// PROPERTY BASED
		// - login
		if (store.state.user.jwLoginId) {
			baseContexts.push(new SnowplowLoginContext(store.state.user.jwLoginId));
		}

		const filteredBaseContexts = baseContexts.filter(removeDuplicateByType(typeList));

		return [...contexts, ...filteredBaseContexts];
	}

	getAdditionalContexts(): SnowplowContext[] {
		const { store } = this;
		const additionalContexts: SnowplowContext[] = [];

		// ROUTE BASED
		// - filter
		const activeRoute = store.state.routing.activeRoute || {};
		const routeBasedContexts = (activeRoute.meta || {}).contexts || [];
		if (routeBasedContexts.includes('filter')) {
			const filters = store.getters['filter/currentFilters'];
			const providerShortNames = store.state.constant.providers.map((item: Provider) => item.shortName);
			const locale = store.getters['language/locale'];
			const urlMetadata = store.getters['routing/urlMetadata'];
			const pageId = urlMetadata.id;
			const routeQuery = this.store.getters['routing/queryParams'];
			const filterContext = new SnowplowFilterContext(filters, routeQuery, pageId, locale, providerShortNames);

			additionalContexts.push(filterContext);
		}

		// - title
		if (routeBasedContexts.includes('title')) {
			const titleDetails: TitleDetailState = store.state.titleDetails;
			if (titleDetails.titleContextArguments) {
				const { titleId, objectType, seasonNumber, episodeNumber, contentType } =
					titleDetails.titleContextArguments;

				additionalContexts.push(
					this.getTitleContextGraphql(titleId, objectType, seasonNumber, episodeNumber, contentType)
				);
			}
		}

		// MODULE BASED (RECOMMENDATIONS)
		const activeModuleIndex = store.state.module.activeModuleIndex;
		if (activeModuleIndex !== null) {
			const activeModule = store.getters['module/modules'][activeModuleIndex];
			const moduleContext = new SnowplowModuleContext(
				activeModule.id,
				activeModuleIndex,
				activeModule.template.technical_name,
				activeModule.discovery_session_id,
				activeModule.template!.anchor
			);
			additionalContexts.push(moduleContext);
		}

		// SESSION BASED
		const now = Date.now();
		this.sessions
			.filter(session => now - this.sessionDuration < session.timestamp)
			.forEach(session => {
				// add session context
				additionalContexts.push(session.context);
				// refresh session timestamp
				session.timestamp = now;
			});

		// PRO SUBSCRIPTION BASED
		if (store.getters['user/isPremium']) {
			additionalContexts.push(this.getFreemiumContext());
		}

		return additionalContexts;
	}

	addSessionContext(context: TrackingSession['context']) {
		this.sessions.push({
			context,
			timestamp: Date.now(),
		});
	}

	getDomainSessionId(): Promise<string | null> {
		if (typeof window === 'undefined') {
			return Promise.resolve(null);
		}

		return new Promise(async resolve => {
			await Vue.$jw.ready?.waitFor(ReadyType.SNOWPLOW_INIT);

			window.snowplow(function () {
				// @ts-ignore Typescript is being annoying here
				const { cf } = this;
				const domainUserInfo = cf?.getDomainUserInfo();
				const domainSessionId = domainUserInfo?.[6] ?? null;
				resolve(domainSessionId);
			});
		});
	}

	/**
	 * Correctly setup and return title context
	 *
	 * @param {NewTitle | PopularTitle} title
	 * @param {Season} season {optional}
	 * @param {Episode} episode {optional}
	 *
	 * @returns {SnowplowTitleContext} context
	 */
	getTitleContext(
		title: Pick<NewTitle, 'id' | 'object_type' | 'season_number'>,
		season?: Pick<Season, 'season_number'>,
		episode?: Pick<Episode, 'episode_number'>
	) {
		// Need these defaults because of TS complains
		const id: number = title.id,
			object_type: TitleObjectType = title.object_type;

		let season_number = null,
			episode_number = null;

		if (season && season.season_number) {
			season_number = season.season_number;
		}

		if (episode?.episode_number) {
			episode_number = episode.episode_number;
		}

		return new SnowplowTitleContext(id, object_type, season_number, episode_number);
	}

	/**
	 * Set the permanent audience, and their parent audiences
	 * into a Snowplow context array
	 *
	 * @param {string[]} permanentAudiences
	 *
	 * @returns {SnowplowAudienceContext[]} baseContexts
	 */
	async getAudienceContext(permanentAudiences: string[]) {
		const baseContexts: SnowplowContext[] = [];
		const prefix = 'pa:' as const;
		const parentAudienceUniqueListIds: string[] = [];

		const {
			loading,
			permanentAudiences: historicPermanentAudiences,
			parentPermanentAudiences: historicParentPermanentAudiences,
		} = useUserStats();

		await until(loading).toBe(false);

		permanentAudiences.forEach((audience: string) => {
			baseContexts.push(
				new SnowplowAudienceContext(
					'permanent_audience',
					audience,
					null,
					historicPermanentAudiences.value.includes(audience) ? 'old' : 'fresh'
				)
			);
			const audienceID = audience.split(prefix)?.at(-1);

			if (!audienceID) return;

			const parentAudiences = paToParentIDMap[audienceID];
			if (!parentAudiences) return;

			parentAudiences.forEach((parentAudience: { listId: string; numericalId: number }) => {
				if (!parentAudienceUniqueListIds.includes(parentAudience.listId)) {
					baseContexts.push(
						new SnowplowAudienceContext(
							'parent_permanent_audience',
							`${prefix}${parentAudience.listId}`, // Transform with the pa: prefix
							parentAudience.numericalId.toString(),

							historicParentPermanentAudiences.value.includes(`${prefix}${parentAudience.listId}`)
								? 'old'
								: 'fresh'
						)
					);
					parentAudienceUniqueListIds.push(parentAudience.listId);
				}
			});
		});

		return baseContexts;
	}

	getTitleContextGraphql(
		titleObjectId: number,
		titleObjectType: ObjectType,
		seasonNumber?: number,
		episodeNumber?: number,
		contentType?: TitleContentType | undefined
	) {
		return new SnowplowTitleContextGraphql(
			titleObjectId,
			titleObjectType,
			seasonNumber ?? null,
			episodeNumber ?? null,
			contentType
		);
	}

	getFreemiumContext() {
		const isHideSeenDislikedEnabled: boolean = this.store.getters['user/isHideSeenDislikedTitlesEnabled'];

		return new SnowplowFreemiumContext(isHideSeenDislikedEnabled);
	}

	getPackageContext(
		packageId: number,
		packageName: string,
		parentType?: null | 'package' | 'bundle',
		parentId?: number,
		parentName?: string
	) {
		return new SnowplowPackageContext(packageId, packageName, parentType, parentId, parentName);
	}

	getRecommenderContext(
		source: string,
		source_title?: string | null,
		source_season_number?: number | null,
		influenced?: boolean | null,
		composition_id?: string | null
	) {
		return new SnowplowRecommenderContext(source, source_title, source_season_number, influenced, composition_id);
	}

	/**
	 * Correctly setup and return page type context for a given page
	 *
	 *
	 * @returns {SnowplowPageTypeContext} context
	 */
	async getPageTypeContext(pathname: string, useLastRoute = false): Promise<SnowplowPageTypeContext> {
		const { store } = this;
		const language: string = store.getters['language/language'];
		const country: string = store.getters['language/country'];
		const routeMetaData = useLastRoute ? store.state.routing.lastUrlMetadata : store.state.routing.urlMetadata;
		const metaData: UrlMetadataResponse<HtmlContentMovieDetails> = routeMetaData;

		const pageType = getPageType(metaData);
		return new SnowplowPageTypeContext(pageType, country, language);
	}

	/**
	 * Overwrite pageType, for AudienceRefresh
	 *
	 *
	 * @returns {SnowplowPageTypeContext} context
	 */
	getForcedPageTypeContext(pageType: string): SnowplowPageTypeContext {
		const { store } = this;
		const language: string = store.getters['language/language'];
		const country: string = store.getters['language/country'];

		return new SnowplowPageTypeContext(pageType, country, language);
	}

	/**
	 * check if not_bounced needs to be sent as well.
	 */
	private async checkNotBounced() {
		if (!this.notBounced) {
			this.notBounced = true;
			// await this part, so we can still add not_bounced to the queue before we processQueue.
			await this.trackEvent('not_bounced', {
				nonInteraction: true,
			});
		}
	}

	/**
	 * checks if campaign contexts should be added for this session.
	 */
	private handleAddingCampaignContexts() {
		if (typeof window === 'undefined') {
			return;
		}

		const uri = window.location.search.substring(1);
		const params = new URLSearchParams(uri);

		if (params.get('uct_b')) {
			// add campaign context v2
			const campaignContext = new SnowplowCampaignContextV2(
				params.get('uct_b')!,
				params.get('uct_s')!,
				params.get('uct_i')!,
				params.get('uct_at')!,
				params.get('uct_tn')!,
				`${window.location.protocol}//${window.location.host}${window.location.pathname}`,
				params.get('uct_p'),
				params.get('uct_sid') ? parseInt(params.get('uct_sid')!) : null,
				params.get('uct_cid') ? parseInt(params.get('uct_cid')!) : null,
				params.get('uct_adgid') ? parseInt(params.get('uct_adgid')!) : null,
				params.get('uct_crid') ? parseInt(params.get('uct_crid')!) : null,
				params.get('uct_adid') ? parseInt(params.get('uct_adid')!) : null,
				params.get('uct_ssid') ? parseInt(params.get('uct_ssid')!) : null,
				params.get('uct_scid') ? parseInt(params.get('uct_scid')!) : null,
				params.get('uct_eccid') ? parseInt(params.get('uct_eccid')!) : null
			);
			this.addSessionContext(campaignContext);
		}
	}
}

// return singleton of trackinghelper
export const TrackingHelper = new TrackingHelperClass();

let TrackingAppId = `${APP_VERSION}-${APP_PLATFORM}#${APP_COMMIT_HASH}`;
if (global.IS_CANARY) {
	console.debug('[CANARY RELEASE]');
	TrackingAppId += `:canary`;
}

const ApplicationContext: ApplicationContextInput = {
	appID: TrackingAppId,
	platform: APP_PLATFORM,
	version: APP_VERSION,
	build: APP_COMMIT_HASH,
	isTestBuild: JW_CONFIG.DOMAIN === 'justwatch.com' ? false : true,
	// OPTIONAL: extras: json string of k:v pairs for extra information
};

const getPageType = (urlMetadata: UrlMetadataResponse | null) => {
	const { $route, $store } = getVm();
	const { pageType } = $route.meta;

	// for pages where the technicalName is different to the pageType.
	// the $route isnt set when the first pageView event is fired, so we need to set this using the technical name
	const pageTypeMapping = {
		VIEW_SUBGENRE_MOVIE: 'VIEW_POPULAR_SUBGENRE_MOVIES',
		VIEW_SUBGENRE_SHOWS: 'VIEW_POPULAR_SUBGENRE_SHOWS',
		VIEW_ARTICLE: 'VIEW_ARTICLE',
	} as const;

	type pageTypeMappingKeys = keyof typeof pageTypeMapping;

	const pageTypeNew = Object.keys(pageTypeMapping).find(key =>
		(urlMetadata?.page?.technical_name || '').startsWith(key)
	);

	if (pageTypeNew) return pageTypeMapping[pageTypeNew as pageTypeMappingKeys];

	if (urlMetadata?.page?.technical_name) return urlMetadata.page.technical_name;

	if (urlMetadata?.object_type) return urlMetadata.object_type;

	if (pageType === PageType.HOME) {
		const onboardingStatus: OnboardingStatus | null = $store.state.user.onboardingStatus;
		return `VIEW_${pageType}_${onboardingStatus}`;
	}
	return `VIEW_${pageType}`;
};

export { getPageType, TrackingAppId, ApplicationContext };
