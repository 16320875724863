import { Component, NavigationGuardNext, Route, RouteConfig as VueRouteConfig } from 'vue-router/types/router';

import {
	CollegesMostSuperBowlPlayers,
	CuratedList,
	ExpansionSeasonsMLS,
	NBAPlayoffs,
	New,
	Oscars2023,
	PlotRecommender,
	Popular,
	Search,
	StreamGreen,
	StreamingCharts,
	SubscriberForm,
	Talent,
	TastyAds,
	TitleDetail,
	TvRegistration,
	Upcoming,
	Valentine2023,
	Watchlist,
	WhatCulture,
	StatisticPage,
	GlobalPress,
} from '@/lazy-load-components';

import { WEB_LOCALES } from '@/constants/web-locales.constant';

import { CollectionType } from '@/enums/collection-type';
import { WebLocale } from '@/enums/web-locale';

import { TitleObjectType } from '@/interfaces/titles';

import { NewPageType } from '@/@types/graphql-types';
import { FilterCollection } from '@/stores/filter.store';
import type { Subgenre } from '@/constants/types';
import type { Store } from 'vuex';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

export enum PageType {
	EDITORIAL = 'EDITORIAL',
	EDITORIAL_ARTICLE = 'EDITORIAL_ARTICLE',
	HOME = 'HOME',
	NEW = 'NEW',
	LEAVING_SOON = 'LEAVING_SOON',
	COMING_SOON = 'COMING_SOON',
	POPULAR = 'POPULAR',
	POPULAR_MOVIES = 'POPULAR_MOVIES',
	POPULAR_TVSHOWS = 'POPULAR_TVSHOWS',
	PRICEDROP = 'ON_SALE',
	PROVIDER_POPULAR = 'PROVIDER_POPULAR',
	PROVIDER_POPULAR_MOVIES = 'PROVIDER_POPULAR_MOVIES',
	PROVIDER_POPULAR_TVSHOWS = 'PROVIDER_POPULAR_SHOWS',
	SEARCH = 'SEARCH',
	SHARED_LIST = 'SHARED_LIST',
	SPORTS = 'SPORTS',
	TITLE_DETAIL = 'TITLE_DETAIL',
	UPCOMING = 'UPCOMING',
	WATCHLIST = 'WATCHLIST',
	STREAMING_CHARTS = 'STREAMING_CHARTS',
	//SUBGENRE
	SUBGENRE_POPULAR = 'POPULAR_SUBGENRE',
	SUBGENRE_POPULAR_MOVIES = 'POPULAR_SUBGENRE_MOVIES',
	SUBGENRE_POPULAR_TVSHOWS = 'POPULAR_SUBGENRE_SHOWS',
}

export interface RouterMeta {
	hasFilterBar?: boolean;
	collectionType?: CollectionType;
	// To distinguish different subpages of New
	newPageType?: NewPageType;
	// filters that will be implicitly loaded when the url initially loads
	initialRouteState?: Partial<FilterCollection>;
	contexts?: ('filter' | 'title')[]; // these route based contexts will be added additionally to every event
	// To-DO: remove tab prop and migrate to pageType
	tab:
		| 'coming-soon' // which target the route shall be loaded into
		| 'detail'
		| 'home'
		| 'landingpage'
		| 'leaving-soon'
		| 'lists'
		| 'new'
		| 'on-sale'
		| 'popular'
		| 'search'
		| 'sharedlist'
		| 'sports'
		| 'static'
		| 'talent'
		| 'tv'
		| 'upcoming';
	pageType: PageType;
	data?: any; // arbitrary data currently in use for <ion-tab>'s pages that share the same tab and need a way to distinguish them
}

export interface RouteConfig {
	component?: Component;
	beforeEnter?: any;
	urls: Partial<Record<WebLocale, string>>;
	meta?: RouterMeta;
	redirect?: Partial<Record<WebLocale, string>>;
}

export type RouteNames = keyof typeof routeConfig;
export type ResolvedRouteConfig = Record<RouteNames, RouteConfig>;

const beforeEnterSubgenres = async (to: Route, _from: Route, next: NavigationGuardNext<Vue>, store: Store<any>) => {
	if (store.state.constant.subgenresList.length === 0 && store.state.language.webLocale === WebLocale.us) {
		await store.dispatch('constant/fetchSubgenres');
	}
	if (to.params.subgenre == null && to.query.subgenres == null) {
		next();
		return;
	}
	// can still be undefined if not in the US
	const currentSubgenreFilter = !!to.query.subgenres
		? to.query.subgenres
		: (store.state.constant.subgenresList as Subgenre[]).find(s => s.slug === to.params.subgenre)?.shortName;
	if (currentSubgenreFilter) {
		await store.dispatch('filter/setFilterValue', {
			filterName: 'subgenres',
			filterValue: [currentSubgenreFilter],
			collectionType: CollectionType.POPULAR,
			updateRouteState: false,
		});
	}

	next();
};

export const routeConfig = {
	// --- POPULAR ---
	'app.titles.popular.european': {
		component: Popular,
		urls: {
			ad: 'made-in-europe',
			ae: 'made-in-europe',
			ag: 'made-in-europe',
			al: 'made-in-europe',
			az: 'made-in-europe',
			ao: 'made-in-europe',
			ar: 'made-in-europe',
			at: 'made-in-europe',
			au: 'made-in-europe',
			ba: 'made-in-europe',
			bb: 'made-in-europe',
			be: 'made-in-europe',
			bf: 'made-in-europe',
			bg: 'made-in-europe',
			bh: 'made-in-europe',
			bm: 'made-in-europe',
			br: 'made-in-europe',
			bs: 'made-in-europe',
			by: 'made-in-europe',
			bz: 'made-in-europe',
			ca: 'made-in-europe',
			cd: 'made-in-europe',
			ch: 'made-in-europe',
			ci: 'made-in-europe',
			cl: 'made-in-europe',
			cm: 'made-in-europe',
			co: 'made-in-europe',
			cu: 'made-in-europe',
			cv: 'made-in-europe',
			cy: 'made-in-europe',
			cz: 'made-in-europe',
			de: 'made-in-europe',
			dk: 'made-in-europe',
			do: 'made-in-europe',
			dz: 'made-in-europe',
			ec: 'made-in-europe',
			ee: 'made-in-europe',
			eg: 'made-in-europe',
			es: 'made-in-europe',
			fi: 'made-in-europe',
			fj: 'made-in-europe',
			fr: 'made-in-europe',
			gf: 'made-in-europe',
			gg: 'made-in-europe',
			gh: 'made-in-europe',
			gi: 'made-in-europe',
			gq: 'made-in-europe',
			gr: 'made-in-europe',
			gy: 'made-in-europe',
			hk: 'made-in-europe',
			hr: 'made-in-europe',
			hu: 'made-in-europe',
			id: 'made-in-europe',
			ie: 'made-in-europe',
			il: 'made-in-europe',
			in: 'made-in-europe',
			iq: 'made-in-europe',
			is: 'made-in-europe',
			it: 'made-in-europe',
			jm: 'made-in-europe',
			jo: 'made-in-europe',
			jp: 'made-in-europe',
			ke: 'made-in-europe',
			kr: 'made-in-europe',
			kw: 'made-in-europe',
			lb: 'made-in-europe',
			lc: 'made-in-europe',
			li: 'made-in-europe',
			lt: 'made-in-europe',
			lu: 'made-in-europe',
			lv: 'made-in-europe',
			ly: 'made-in-europe',
			ma: 'made-in-europe',
			mc: 'made-in-europe',
			md: 'made-in-europe',
			me: 'made-in-europe',
			mg: 'made-in-europe',
			mk: 'made-in-europe',
			ml: 'made-in-europe',
			mt: 'made-in-europe',
			mu: 'made-in-europe',
			mw: 'made-in-europe',
			mx: 'made-in-europe',
			my: 'made-in-europe',
			mz: 'made-in-europe',
			ne: 'made-in-europe',
			ng: 'made-in-europe',
			ni: 'made-in-europe',
			nl: 'made-in-europe',
			no: 'made-in-europe',
			nz: 'made-in-europe',
			om: 'made-in-europe',
			pa: 'made-in-europe',
			pe: 'made-in-europe',
			pf: 'made-in-europe',
			pg: 'made-in-europe',
			ph: 'made-in-europe',
			pk: 'made-in-europe',
			pl: 'made-in-europe',
			ps: 'made-in-europe',
			pt: 'made-in-europe',
			qa: 'made-in-europe',
			ro: 'made-in-europe',
			rs: 'made-in-europe',
			ru: 'made-in-europe',
			sa: 'made-in-europe',
			sc: 'made-in-europe',
			se: 'made-in-europe',
			sg: 'made-in-europe',
			sk: 'made-in-europe',
			si: 'made-in-europe',
			sm: 'made-in-europe',
			sn: 'made-in-europe',
			sv: 'made-in-europe',
			tc: 'made-in-europe',
			td: 'made-in-europe',
			th: 'made-in-europe',
			tn: 'made-in-europe',
			tr: 'made-in-europe',
			tt: 'made-in-europe',
			tw: 'made-in-europe',
			tz: 'made-in-europe',
			ua: 'made-in-europe',
			ug: 'made-in-europe',
			uk: 'made-in-europe',
			us: 'made-in-europe',
			uy: 'made-in-europe',
			va: 'made-in-europe',
			ve: 'made-in-europe',
			xk: 'made-in-europe',
			ye: 'made-in-europe',
			za: 'made-in-europe',
			zm: 'made-in-europe',
			zw: 'made-in-europe',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.POPULAR,
			contexts: ['filter'],
			tab: 'popular',
			pageType: PageType.POPULAR,
		},
		beforeEnter: beforeEnterSubgenres,
	},
	'app.titles.popular.list': {
		component: Popular,
		urls: Object.keys(WEB_LOCALES).reduce((root: any, webLocale) => {
			root[webLocale] = '';
			return root;
		}, {}),
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.POPULAR,
			initialRouteState: {},
			contexts: ['filter'],
			tab: 'popular',
			pageType: PageType.POPULAR,
		},
		beforeEnter: beforeEnterSubgenres,
	},
	'app.titles.popular.movie': {
		component: Popular,
		urls: {
			ad: 'pellicules',
			ae: 'movies',
			ag: 'movies',
			al: 'filmat',
			ao: 'filmes',
			ar: 'peliculas',
			at: 'Filme',
			au: 'movies',
			az: 'filmlər',
			ba: 'filmovi',
			bb: 'movies',
			be: 'films',
			bf: 'films',
			bg: 'филми',
			bh: 'movies',
			bm: 'movies',
			bo: 'peliculas',
			br: 'filmes',
			bs: 'movies',
			by: 'фільмы',
			bz: 'movies',
			ca: 'movies',
			cd: 'films',
			ch: 'Filme',
			ci: 'films',
			cl: 'peliculas',
			cm: 'movies',
			co: 'peliculas',
			cr: 'peliculas',
			cu: 'peliculas',
			cv: 'filmes',
			cy: 'ταινίες',
			cz: 'filmy',
			de: 'Filme',
			dk: 'movies',
			do: 'peliculas',
			dz: 'movies',
			ec: 'peliculas',
			ee: 'filmid',
			eg: 'movies',
			es: 'peliculas',
			fi: 'elokuvat',
			fj: 'movies',
			fr: 'films',
			gf: 'films',
			gg: 'movies',
			gh: 'movies',
			gi: 'movies',
			gq: 'peliculas',
			gr: 'ταινίες',
			gt: 'peliculas',
			gy: 'movies',
			hk: '電影',
			hn: 'peliculas',
			hr: 'filmovi',
			hu: 'filmek',
			id: 'movies',
			ie: 'movies',
			il: 'movies',
			in: 'movies',
			iq: 'movies',
			is: 'kvikmyndir',
			it: 'film',
			jm: 'movies',
			jo: 'movies',
			jp: '映画',
			ke: 'movies',
			kr: '영화',
			kw: 'movies',
			lb: 'movies',
			lc: 'movies',
			li: 'Filme',
			lt: 'filmai',
			lu: 'films',
			lv: 'filmas',
			ly: 'movies',
			ma: 'movies',
			mc: 'films',
			md: 'filme',
			me: 'filmovi',
			mg: 'films',
			mk: 'филмови',
			ml: 'films',
			mt: 'films',
			mu: 'films',
			mw: 'movies',
			mx: 'peliculas',
			my: 'movies',
			mz: 'filmes',
			ne: 'films',
			ng: 'movies',
			ni: 'peliculas',
			nl: 'movies',
			no: 'movies',
			nz: 'movies',
			om: 'movies',
			pa: 'peliculas',
			pe: 'peliculas',
			pf: 'films',
			pg: 'movies',
			ph: 'movies',
			pk: 'movies',
			pl: 'filmy',
			ps: 'movies',
			pt: 'filmes',
			py: 'peliculas',
			qa: 'movies',
			ro: 'filme',
			rs: 'filmovi',
			ru: 'фильмы',
			sa: 'movies',
			sc: 'films',
			se: 'filmer',
			sg: 'movies',
			si: 'filmi',
			sk: 'filmy',
			sm: 'film',
			sn: 'films',
			sv: 'peliculas',
			tc: 'movies',
			td: 'movies',
			th: 'movies',
			tn: 'movies',
			tr: 'filmler',
			tt: 'movies',
			tw: '電影',
			tz: 'filamu',
			ua: 'фільми',
			ug: 'movies',
			uk: 'movies',
			us: 'movies',
			uy: 'peliculas',
			va: 'film',
			ve: 'peliculas',
			xk: 'filmat',
			ye: 'movies',
			za: 'movies',
			zm: 'movies',
			zw: 'movies',
		},
		redirect: {
			dk: 'film',
			id: 'film',
			lt: 'filmai',
			lv: 'filmas',
			my: 'filem',
			no: 'filmer',
			se: 'filmer',
			th: 'ภาพยนต์',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.POPULAR,
			initialRouteState: { content_types: [TitleObjectType.MOVIE] },
			contexts: ['filter'],
			tab: 'popular',
			pageType: PageType.POPULAR_MOVIES,
		},
		beforeEnter: beforeEnterSubgenres,
	},
	'app.titles.popular.show': {
		component: Popular,
		urls: {
			ad: 'series',
			ae: 'tv-shows',
			ag: 'tv-shows',
			al: 'seriale-televizive',
			az: 'televiziya-şouları',
			ao: 'series',
			ar: 'series',
			at: 'Serien',
			au: 'tv-shows',
			ba: 'tv-serije',
			bb: 'tv-shows',
			be: 'series',
			bf: 'series',
			bg: 'сериали',
			bh: 'tv-shows',
			bm: 'tv-shows',
			bo: 'series',
			br: 'series',
			bs: 'tv-shows',
			by: 'сезон',
			bz: 'tv-shows',
			ca: 'tv-shows',
			cd: 'series',
			ch: 'Serien',
			ci: 'series',
			cl: 'series',
			cm: 'tv-shows',
			co: 'series',
			cr: 'series',
			cu: 'sericu',
			cv: 'series',
			cy: 'τηλεοπτικές-σειρές',
			cz: 'serialy',
			de: 'Serien',
			dk: 'tv-series',
			do: 'serido',
			dz: 'tv-shows',
			ec: 'series',
			ee: 'seriaalid',
			eg: 'tv-shows',
			es: 'series',
			fi: 'sarjat',
			fj: 'tv-shows',
			fr: 'series',
			gf: 'series',
			gg: 'tv-shows',
			gh: 'tv-shows',
			gi: 'tv-shows',
			gq: 'serigq',
			gr: 'τηλεοπτικές-σειρές',
			gt: 'series',
			gy: 'tv-shows',
			hk: '電視節目',
			hn: 'series',
			hr: 'serije',
			hu: 'tv-sorozat',
			id: 'tv-shows',
			ie: 'tv-series',
			il: 'tv-shows',
			in: 'tv-shows',
			iq: 'tv-shows',
			is: 'þættir',
			it: 'serie-tv',
			jm: 'tv-shows',
			jo: 'tv-shows',
			jp: 'テレビ番組',
			ke: 'tv-shows',
			kr: 'TV-프로그램',
			kw: 'tv-shows',
			lb: 'tv-shows',
			lc: 'tv-shows',
			li: 'Serien',
			lt: 'tv-laidos',
			lu: 'series',
			lv: 'tv-pārraide',
			ly: 'tv-shows',
			ma: 'tv-shows',
			mc: 'series',
			md: 'seriale',
			me: 'tv-programi',
			mg: 'series',
			mk: 'тв-серии',
			ml: 'series',
			mt: 'serje-televiżivi',
			mu: 'series',
			mw: 'tv-shows',
			mx: 'series',
			my: 'tv-shows',
			mz: 'series',
			ne: 'series',
			ng: 'tv-shows',
			ni: 'serini',
			nl: 'tv-series',
			no: 'tv-series',
			nz: 'tv-shows',
			om: 'tv-shows',
			pa: 'seripa',
			pe: 'series',
			pf: 'series',
			pg: 'tv-series',
			ph: 'tv-shows',
			pk: 'tv-shows',
			pl: 'seriale',
			ps: 'tv-shows',
			pt: 'series',
			py: 'series',
			qa: 'tv-shows',
			ro: 'seriale',
			rs: 'tv-programi',
			ru: 'сериалы',
			sc: 'series',
			sa: 'tv-shows',
			se: 'tv-serier',
			sg: 'tv-shows',
			sk: 'seriály',
			si: 'serije',
			sm: 'serie-tv',
			sn: 'series',
			sv: 'serisv',
			tc: 'tv-shows',
			td: 'tv-shows',
			th: 'tv-shows',
			tn: 'tv-shows',
			tr: 'tv-dizileri',
			tt: 'tv-shows',
			tw: '電視節目',
			tz: 'maonyesho-ya-tv',
			ua: 'телесеріали',
			ug: 'tv-shows',
			uk: 'tv-series',
			us: 'tv-shows',
			uy: 'seriuy',
			va: 'serie-tv',
			ve: 'series',
			xk: 'seriale-televizive',
			ye: 'tv-shows',
			za: 'tv-series',
			zm: 'tv-shows',
			zw: 'tv-shows',
		},
		redirect: {
			dk: 'tv-serier',
			id: 'acara-tv',
			lt: 'tv-laidos',
			lv: 'tv-pārraide',
			my: 'rancangan-tv',
			no: 'tv-serier',
			se: 'tv-serier',
			th: 'รายการทีวี',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.POPULAR,
			initialRouteState: { content_types: [TitleObjectType.SHOW] },
			contexts: ['filter'],
			tab: 'popular',
			pageType: PageType.POPULAR_TVSHOWS,
		},
		beforeEnter: beforeEnterSubgenres,
	},
	'app.titles.popular.provider': {
		component: Popular,
		urls: {
			ad: 'plataforma/:provider',
			ae: 'provider/:provider',
			ag: 'provider/:provider',
			al: 'ofruesi/:provider',
			az: 'provayder/:provider',
			ao: 'provedor/:provider',
			ar: 'proveedor/:provider',
			at: 'Anbieter/:provider',
			au: 'provider/:provider',
			ba: 'provajder/:provider',
			bb: 'provider/:provider',
			be: 'plateforme/:provider',
			bf: 'plateforme/:provider',
			bg: 'доставчик/:provider',
			bh: 'provider/:provider',
			bm: 'provider/:provider',
			bo: 'proveedor/:provider',
			br: 'provedor/:provider',
			bs: 'provider/:provider',
			by: 'правайдэр/:provider',
			bz: 'provider/:provider',
			ca: 'provider/:provider',
			cd: 'plateforme/:provider',
			ch: 'Anbieter/:provider',
			ci: 'plateforme/:provider',
			cl: 'proveedor/:provider',
			cm: 'provider/:provider',
			co: 'proveedor/:provider',
			cr: 'proveedor/:provider',
			cu: 'proveedor/:provider',
			cv: 'provedor/:provider',
			cy: 'πάροχος/:provider',
			cz: 'poskytovatel/:provider',
			de: 'Anbieter/:provider',
			dk: 'provider/:provider',
			do: 'proveedor/:provider',
			dz: 'provider/:provider',
			ec: 'proveedor/:provider',
			ee: 'teenusepakkuja/:provider',
			eg: 'provider/:provider',
			es: 'proveedor/:provider',
			fi: 'palvelu/:provider',
			fj: 'provider/:provider',
			fr: 'plateforme/:provider',
			gf: 'plateforme/:provider',
			gg: 'provider/:provider',
			gh: 'provider/:provider',
			gi: 'provider/:provider',
			gq: 'proveedor/:provider',
			gr: 'πάροχος/:provider',
			gt: 'proveedor/:provider',
			gy: 'provider/:provider',
			hk: '供應商/:provider',
			hn: 'proveedor/:provider',
			hr: 'pružatelj-usluge/:provider',
			hu: 'szolgaltato/:provider',
			id: 'provider/:provider',
			ie: 'provider/:provider',
			il: 'provider/:provider',
			in: 'provider/:provider',
			iq: 'provider/:provider',
			is: 'veita/:provider',
			it: 'provider/:provider',
			jm: 'provider/:provider',
			jo: 'provider/:provider',
			jp: '動画配信サービス/:provider',
			ke: 'provider/:provider',
			kr: '동영상서비스/:provider',
			kw: 'provider/:provider',
			lb: 'provider/:provider',
			lc: 'provider/:provider',
			li: 'Anbieter/:provider',
			lt: 'tiekėjas/:provider',
			lu: 'plateforme/:provider',
			lv: 'apgādātājs/:provider',
			ly: 'provider/:provider',
			ma: 'provider/:provider',
			mc: 'plateforme/:provider',
			md: 'furnizor/:provider',
			me: 'provajder/:provider',
			mg: 'plateforme/:provider',
			mk: 'провајдер/:provider',
			ml: 'plateforme/:provider',
			mt: 'fornitur/:provider',
			mu: 'plateforme/:provider',
			mw: 'provider/:provider',
			mx: 'proveedor/:provider',
			my: 'provider/:provider',
			mz: 'provedor/:provider',
			ne: 'plateforme/:provider',
			ng: 'provider/:provider',
			ni: 'proveedor/:provider',
			nl: 'provider/:provider',
			no: 'provider/:provider',
			nz: 'provider/:provider',
			om: 'provider/:provider',
			pa: 'proveedor/:provider',
			pe: 'proveedor/:provider',
			pf: 'plateforme/:provider',
			pg: 'provider/:provider',
			ph: 'provider/:provider',
			pk: 'provider/:provider',
			pl: 'serwis-streamingowy/:provider',
			ps: 'provider/:provider',
			pt: 'provedor/:provider',
			py: 'proveedor/:provider',
			qa: 'provider/:provider',
			ro: 'furnizor/:provider',
			rs: 'provajder/:provider',
			ru: 'провайдер/:provider',
			sa: 'provider/:provider',
			sc: 'plateforme/:provider',
			se: 'leverantör/:provider',
			sg: 'provider/:provider',
			sk: 'poskytovateľ/:provider',
			si: 'ponudnik/:provider',
			sm: 'provider/:provider',
			sn: 'plateforme/:provider',
			sv: 'proveedor/:provider',
			tc: 'provider/:provider',
			td: 'provider/:provider',
			th: 'provider/:provider',
			tn: 'provider/:provider',
			tr: 'saglayici/:provider',
			tt: 'provider/:provider',
			tw: '平台/:provider',
			tz: 'mtoa-huduma/:provider',
			ua: 'провайдер/:provider',
			ug: 'provider/:provider',
			uk: 'provider/:provider',
			us: 'provider/:provider',
			uy: 'proveedor/:provider',
			va: 'provider/:provider',
			ve: 'proveedor/:provider',
			xk: 'ofruesi/:provider',
			ye: 'provider/:provider',
			za: 'provider/:provider',
			zm: 'provider/:provider',
			zw: 'provider/:provider',
		},
		redirect: {
			dk: 'udbyder/:provider',
			id: 'penyedia/:provider',
			lt: 'tiekėjas/:provider',
			lv: 'apgādātājs/:provider',
			my: 'pemberi/:provider',
			no: 'tjeneste/:provider',
			se: 'leverantör/:provider',
			th: 'ผู้ให้บริการ/:provider',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.POPULAR,
			contexts: ['filter'],
			tab: 'popular',
			pageType: PageType.PROVIDER_POPULAR,
		},
		beforeEnter: beforeEnterSubgenres,
	},

	'app.titles.popular.provider-movie': {
		component: Popular,
		urls: {
			ad: 'plataforma/:provider/pellicules',
			ae: 'provider/:provider/movies',
			ag: 'provider/:provider/movies',
			al: 'ofruesi/:provider/filmat',
			az: 'provayder/:provider/filmlər',
			ao: 'provedor/:provider/filmes',
			ar: 'proveedor/:provider/peliculas',
			at: 'Anbieter/:provider/Filme',
			au: 'provider/:provider/movies',
			ba: 'provajder/:provider/filmovi',
			bb: 'provider/:provider/movies',
			be: 'plateforme/:provider/films',
			bf: 'plateforme/:provider/films',
			bg: 'доставчик/:provider/филми',
			bh: 'provider/:provider/movies',
			bm: 'provider/:provider/movies',
			bo: 'proveedor/:provider/peliculas',
			br: 'provedor/:provider/filmes',
			bs: 'provider/:provider/movies',
			by: 'правайдэр/:provider/фільмы',
			bz: 'provider/:provider/movies',
			ca: 'provider/:provider/movies',
			cd: 'plateforme/:provider/films',
			ch: 'Anbieter/:provider/Filme',
			ci: 'plateforme/:provider/films',
			cl: 'proveedor/:provider/peliculas',
			cm: 'provider/:provider/movies',
			co: 'proveedor/:provider/peliculas',
			cr: 'proveedor/:provider/peliculas',
			cu: 'proveedor/:provider/peliculas',
			cv: 'provedor/:provider/filmes',
			cy: 'πάροχος/:provider/ταινίες',
			cz: 'poskytovatel/:provider/filmy',
			de: 'Anbieter/:provider/Filme',
			dk: 'provider/:provider/movies',
			do: 'proveedor/:provider/peliculas',
			dz: 'provider/:provider/movies',
			ec: 'proveedor/:provider/peliculas',
			ee: 'teenusepakkuja/:provider/filmid',
			eg: 'provider/:provider/movies',
			es: 'proveedor/:provider/peliculas',
			fi: 'palvelu/:provider/elokuvat',
			fj: 'provider/:provider/movies',
			fr: 'plateforme/:provider/films',
			gf: 'plateforme/:provider/films',
			gg: 'provider/:provider/movies',
			gh: 'provider/:provider/movies',
			gi: 'provider/:provider/movies',
			gq: 'proveedor/:provider/peliculas',
			gr: 'πάροχος/:provider/ταινίες',
			gt: 'proveedor/:provider/peliculas',
			gy: 'provider/:provider/movies',
			hk: '供應商/:provider/電影',
			hn: 'proveedor/:provider/peliculas',
			hr: 'pružatelj-usluge/:provider/filmovi',
			hu: 'szolgaltato/:provider/filmek',
			id: 'provider/:provider/movies',
			ie: 'provider/:provider/movies',
			il: 'provider/:provider/movies',
			in: 'provider/:provider/movies',
			iq: 'provider/:provider/movies',
			is: 'veita/:provider/kvikmyndir',
			it: 'provider/:provider/film',
			jm: 'provider/:provider/movies',
			jo: 'provider/:provider/movies',
			jp: '動画配信サービス/:provider/映画',
			ke: 'provider/:provider/movies',
			kr: '동영상서비스/:provider/영화산업',
			kw: 'provider/:provider/movies',
			lb: 'provider/:provider/movies',
			lc: 'provider/:provider/movies',
			li: 'Anbieter/:provider/Filme',
			lt: 'tiekėjas/:provider/filmai',
			lu: 'plateforme/:provider/films',
			lv: 'apgādātājs/:provider/filmas',
			ly: 'provider/:provider/movies',
			ma: 'provider/:provider/movies',
			mc: 'plateforme/:provider/films',
			md: 'furnizor/:provider/filme',
			me: 'provajder/:provider/filmovi',
			mg: 'plateforme/:provider/films',
			mk: 'провајдер/:provider/филмови',
			ml: 'plateforme/:provider/films',
			mt: 'fornitur/:provider/films',
			mu: 'plateforme/:provider/films',
			mw: 'provider/:provider/movies',
			mx: 'proveedor/:provider/peliculas',
			my: 'provider/:provider/movies',
			mz: 'provedor/:provider/filmes',
			ne: 'plateforme/:provider/films',
			ng: 'provider/:provider/movies',
			ni: 'proveedor/:provider/peliculas',
			nl: 'provider/:provider/movies',
			no: 'provider/:provider/movies',
			nz: 'provider/:provider/movies',
			om: 'provider/:provider/movies',
			pa: 'proveedor/:provider/peliculas',
			pe: 'proveedor/:provider/peliculas',
			pf: 'plateforme/:provider/films',
			pg: 'provider/:provider/movies',
			ph: 'provider/:provider/movies',
			pk: 'provider/:provider/movies',
			pl: 'serwis-streamingowy/:provider/filmy',
			ps: 'provider/:provider/movies',
			pt: 'provedor/:provider/filmes',
			py: 'proveedor/:provider/peliculas',
			qa: 'provider/:provider/movies',
			ro: 'furnizor/:provider/filme',
			rs: 'provajder/:provider/filmovi',
			ru: 'провайдер/:provider/фильмы',
			sa: 'provider/:provider/movies',
			sc: 'plateforme/:provider/films',
			se: 'leverantör/:provider/filmer',
			sg: 'provider/:provider/movies',
			sk: 'poskytovateľ/:provider/filmy',
			si: 'ponudnik/:provider/filmi',
			sm: 'provider/:provider/film',
			sn: 'plateforme/:provider/films',
			sv: 'proveedor/:provider/peliculas',
			tc: 'provider/:provider/movies',
			td: 'provider/:provider/movies',
			th: 'provider/:provider/movies',
			tn: 'provider/:provider/movies',
			tr: 'saglayici/:provider/filmler',
			tt: 'provider/:provider/movies',
			tw: '平台/:provider/電影',
			tz: 'mtoa-huduma/:provider/filamu',
			ua: 'провайдер/:provider/фільми',
			ug: 'provider/:provider/movies',
			uk: 'provider/:provider/movies',
			us: 'provider/:provider/movies',
			uy: 'proveedor/:provider/peliculas',
			va: 'provider/:provider/film',
			ve: 'proveedor/:provider/peliculas',
			xk: 'ofruesi/:provider/filmat',
			ye: 'provider/:provider/movies',
			za: 'provider/:provider/movies',
			zm: 'provider/:provider/movies',
			zw: 'provider/:provider/movies',
		},
		redirect: {
			dk: 'udbyder/:provider/film',
			id: 'penyedia/:provider/film',
			lt: 'tiekėjas/:provider/filmai',
			lv: 'apgādātājs/:provider/filmas',
			my: 'pemberi/:provider/filem',
			no: 'tjeneste/:provider/filmer',
			se: 'leverantör/:provider/filmer',
			th: 'ผู้ให้บริการ/:provider/ภาพยนต์',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.POPULAR,
			initialRouteState: { content_types: [TitleObjectType.MOVIE] },
			contexts: ['filter'],
			tab: 'popular',
			pageType: PageType.PROVIDER_POPULAR_MOVIES,
		},
		beforeEnter: beforeEnterSubgenres,
	},
	'app.titles.popular.provider-show': {
		component: Popular,
		urls: {
			ad: 'plataforma/:provider/series',
			ae: 'provider/:provider/tv-shows',
			ag: 'provider/:provider/tv-shows',
			al: 'ofruesi/:provider/seriale-televizive',
			az: 'provayder/:provider/televiziya-şouları',
			ao: 'provedor/:provider/series',
			ar: 'proveedor/:provider/series',
			at: 'Anbieter/:provider/Serien',
			au: 'provider/:provider/tv-shows',
			ba: 'provajder/:provider/tv-serije',
			bb: 'provider/:provider/tv-shows',
			be: 'plateforme/:provider/series',
			bf: 'plateforme/:provider/series',
			bg: 'доставчик/:provider/сериали',
			bh: 'provider/:provider/tv-shows',
			bm: 'provider/:provider/tv-shows',
			bo: 'proveedor/:provider/series',
			br: 'provedor/:provider/series',
			bs: 'provider/:provider/tv-shows',
			by: 'правайдэр/:provider/сезон',
			bz: 'provider/:provider/tv-series',
			ca: 'provider/:provider/tv-shows',
			cd: 'plateforme/:provider/series',
			ch: 'Anbieter/:provider/Serien',
			ci: 'plateforme/:provider/series',
			cl: 'proveedor/:provider/series',
			cm: 'provider/:provider/tv-series',
			co: 'proveedor/:provider/series',
			cr: 'proveedor/:provider/series',
			cu: 'proveedor/:provider/series',
			cv: 'provedor/:provider/series',
			cy: 'πάροχος/:provider/τηλεοπτικές-σειρές',
			cz: 'poskytovatel/:provider/serialy',
			de: 'Anbieter/:provider/Serien',
			dk: 'provider/:provider/tv-series',
			do: 'proveedor/:provider/series',
			dz: 'provider/:provider/tv-shows',
			ec: 'proveedor/:provider/series',
			ee: 'teenusepakkuja/:provider/seriaalid',
			eg: 'provider/:provider/tv-shows',
			es: 'proveedor/:provider/series',
			fi: 'palvelu/:provider/sarjat',
			fj: 'provider/:provider/tv-shows',
			fr: 'plateforme/:provider/series',
			gf: 'plateforme/:provider/series',
			gg: 'provider/:provider/tv-shows',
			gh: 'provider/:provider/tv-shows',
			gi: 'provider/:provider/tv-shows',
			gq: 'proveedor/:provider/series',
			gr: 'πάροχος/:provider/τηλεοπτικές-σειρές',
			gt: 'proveedor/:provider/series',
			gy: 'provider/:provider/tv-series',
			hk: '供應商/:provider/節目',
			hn: 'proveedor/:provider/series',
			hr: 'pružatelj-usluge/:provider/serije',
			hu: 'szolgaltato/:provider/tv-sorozat',
			id: 'provider/:provider/tv-shows',
			ie: 'provider/:provider/tv-series',
			il: 'provider/:provider/tv-shows',
			in: 'provider/:provider/tv-shows',
			iq: 'provider/:provider/tv-shows',
			is: 'veita/:provider/þættir',
			it: 'provider/:provider/serie-tv',
			jm: 'provider/:provider/tv-shows',
			jo: 'provider/:provider/tv-shows',
			jp: '動画配信サービス/:provider/テレビ番組',
			ke: 'provider/:provider/tv-shows',
			kr: '동영상서비스/:provider/TV-프로그램',
			kw: 'provider/:provider/tv-shows',
			lb: 'provider/:provider/tv-shows',
			lc: 'provider/:provider/tv-shows',
			li: 'Anbieter/:provider/Serien',
			lt: 'tiekėjas/:provider/tv-laidos',
			lu: 'plateforme/:provider/series',
			lv: 'apgādātājs/:provider/tv-pārraide',
			ly: 'provider/:provider/tv-shows',
			ma: 'provider/:provider/tv-shows',
			mc: 'plateforme/:provider/series',
			md: 'furnizor/:provider/seriale',
			me: 'provajder/:provider/tv-programi',
			mg: 'plateforme/:provider/series',
			mk: 'провајдер/:provider/тв-серии',
			ml: 'plateforme/:provider/series',
			mt: 'fornitur/:provider/serje-televiżivi',
			mu: 'plateforme/:provider/series',
			mw: 'provider/:provider/tv-series',
			mx: 'proveedor/:provider/series',
			my: 'provider/:provider/tv-shows',
			mz: 'provedor/:provider/series',
			ne: 'plateforme/:provider/series',
			ng: 'provider/:provider/tv-shows',
			ni: 'proveedor/:provider/series',
			nl: 'provider/:provider/tv-series',
			no: 'provider/:provider/tv-series',
			nz: 'provider/:provider/tv-shows',
			om: 'provider/:provider/tv-shows',
			pa: 'proveedor/:provider/series',
			pe: 'proveedor/:provider/series',
			pf: 'plateforme/:provider/series',
			pg: 'provider/:provider/tv-series',
			ph: 'provider/:provider/tv-shows',
			pk: 'provider/:provider/tv-shows',
			pl: 'serwis-streamingowy/:provider/seriale',
			ps: 'provider/:provider/tv-shows',
			pt: 'provedor/:provider/series',
			py: 'proveedor/:provider/series',
			qa: 'provider/:provider/tv-shows',
			ro: 'furnizor/:provider/seriale',
			rs: 'provajder/:provider/tv-programi',
			ru: 'провайдер/:provider/сериалы',
			sa: 'provider/:provider/tv-shows',
			sc: 'plateforme/:provider/series',
			se: 'leverantör/:provider/tv-serier',
			sg: 'provider/:provider/tv-shows',
			sk: 'poskytovateľ/:provider/seriály',
			si: 'ponudnik/:provider/serije',
			sm: 'provider/:provider/serie-tv',
			sn: 'plateforme/:provider/series',
			sv: 'proveedor/:provider/series',
			tc: 'provider/:provider/tv-shows',
			td: 'provider/:provider/tv-shows',
			th: 'provider/:provider/tv-shows',
			tn: 'provider/:provider/tv-shows',
			tr: 'saglayici/:provider/tv-dizileri',
			tt: 'provider/:provider/tv-shows',
			tw: '平台/:provider/節目',
			tz: 'mtoa-huduma/:provider/maonyesho-ya-tv',
			ua: 'провайдер/:provider/телесеріали',
			ug: 'provider/:provider/tv-shows',
			uk: 'provider/:provider/tv-series',
			us: 'provider/:provider/tv-shows',
			uy: 'proveedor/:provider/series',
			va: 'provider/:provider/serie-tv',
			ve: 'proveedor/:provider/series',
			xk: 'ofruesi/:provider/seriale-televizive',
			ye: 'provider/:provider/tv-shows',
			za: 'provider/:provider/tv-series',
			zm: 'provider/:provider/tv-shows',
			zw: 'provider/:provider/tv-series',
		},
		redirect: {
			dk: 'udbyder/:provider/tv-serier',
			id: 'penyedia/:provider/acara-tv',
			lt: 'tiekėjas/:provider/tv-laidos',
			lv: 'apgādātājs/:provider/tv-pārraide',
			my: 'pemberi/:provider/rancangan-tv',
			no: 'tjeneste/:provider/tv-serier',
			se: 'leverantör/:provider/tv-serier',
			th: 'ผู้ให้บริการ/:provider/รายการทีวี',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.POPULAR,
			initialRouteState: { content_types: [TitleObjectType.SHOW] },
			contexts: ['filter'],
			tab: 'popular',
			pageType: PageType.PROVIDER_POPULAR_TVSHOWS,
		},
		beforeEnter: beforeEnterSubgenres,
	},

	// --- NEW ---
	'app.titles.new.list': {
		component: New,
		urls: {
			ad: 'nou',
			ae: 'new',
			ag: 'new',
			al: 'risitë',
			az: 'yeni',
			ao: 'novo',
			ar: 'nuevo',
			at: 'Neu',
			au: 'new',
			ba: 'novo',
			bb: 'new',
			be: 'nouveau',
			bf: 'nouveau',
			bg: 'нови',
			bh: 'new',
			bm: 'new',
			bo: 'nuevo',
			br: 'novo',
			bs: 'new',
			by: 'новыя',
			bz: 'new',
			ca: 'new',
			cd: 'nouveau',
			ch: 'Neu',
			ci: 'nouveau',
			cl: 'nuevo',
			cm: 'new',
			co: 'nuevo',
			cr: 'nuevo',
			cu: 'nuevo',
			cv: 'novo',
			cy: 'νέα',
			cz: 'novinky',
			de: 'Neu',
			dk: 'new',
			do: 'nuevo',
			dz: 'new',
			ec: 'nuevo',
			ee: 'uus',
			eg: 'new',
			es: 'nuevo',
			fi: 'uudet',
			fj: 'new',
			fr: 'nouveau',
			gf: 'nouveau',
			gg: 'new',
			gh: 'new',
			gi: 'new',
			gq: 'nuevo',
			gr: 'νέα',
			gt: 'nuevo',
			gy: 'new',
			hk: '最新',
			hn: 'nuevo',
			hr: 'novo',
			hu: 'uj',
			id: 'new',
			ie: 'new',
			il: 'new',
			in: 'new',
			iq: 'new',
			is: 'ný',
			it: 'novità',
			jm: 'new',
			jo: 'new',
			jp: '新作',
			ke: 'new',
			kr: '최신',
			kw: 'new',
			lb: 'new',
			lc: 'new',
			li: 'Neu',
			lt: 'nauji',
			lu: 'nouveau',
			lv: 'jauns',
			ly: 'new',
			ma: 'new',
			mc: 'nouveau',
			md: 'nou',
			me: 'novo',
			mg: 'nouveau',
			mk: 'ново',
			ml: 'nouveau',
			mt: 'ġdid',
			mu: 'nouveau',
			mw: 'new',
			mx: 'nuevo',
			my: 'new',
			mz: 'novo',
			ne: 'nouveau',
			ng: 'new',
			ni: 'nuevo',
			nl: 'new',
			no: 'new',
			nz: 'new',
			om: 'new',
			pa: 'nuevo',
			pe: 'nuevo',
			pf: 'nouveau',
			pg: 'new',
			ph: 'new',
			pk: 'new',
			pl: 'nowe',
			ps: 'new',
			pt: 'novo',
			py: 'nuevo',
			qa: 'new',
			ro: 'nou',
			rs: 'novo',
			ru: 'новые',
			sa: 'new',
			sc: 'nouveau',
			se: 'nya',
			sg: 'new',
			sk: 'nový',
			si: 'novo',
			sm: 'novità',
			sn: 'nouveau',
			sv: 'nuevo',
			tc: 'new',
			td: 'new',
			th: 'new',
			tn: 'new',
			tr: 'yeni',
			tt: 'new',
			tw: '最新',
			tz: 'mpya',
			ua: 'новинки',
			ug: 'new',
			uk: 'new',
			us: 'new',
			uy: 'nuevo',
			va: 'novità',
			ve: 'nuevo',
			xk: 'risitë',
			ye: 'new',
			za: 'new',
			zm: 'new',
			zw: 'new',
		},
		redirect: {
			dk: 'nyt',
			id: 'baru',
			lt: 'nauji',
			lv: 'jauns',
			my: 'terkini',
			no: 'nyheter',
			se: 'nya',
			th: 'ใหม่',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.NEW,
			newPageType: NewPageType.New,
			contexts: ['filter'],
			tab: 'new',
			pageType: PageType.NEW,
		},
	},
	'app.titles.new.movie': {
		component: New,
		urls: {
			ad: 'pellicules/nou',
			ae: 'movies/new',
			ag: 'movies/new',
			al: 'filmat/risitë',
			az: 'filmlər/yeni',
			ao: 'filmes/novo',
			ar: 'peliculas/nuevo',
			at: 'Filme/Neu',
			au: 'movies/new',
			ba: 'filmovi/novo',
			bb: 'movies/new',
			be: 'films/nouveau',
			bf: 'films/nouveau',
			bg: 'филми/нови',
			bh: 'movies/new',
			bm: 'movies/new',
			bo: 'peliculas/nuevo',
			br: 'filmes/novo',
			bs: 'movies/new',
			by: 'фільмы/новыя',
			bz: 'movies/new',
			ca: 'movies/new',
			cd: 'films/nouveau',
			ch: 'Filme/Neu',
			ci: 'films/nouveau',
			cl: 'peliculas/nuevo',
			cm: 'movies/new',
			co: 'peliculas/nuevo',
			cr: 'peliculas/nuevo',
			cu: 'peliculas/nuevo',
			cv: 'filmes/novo',
			cy: 'ταινίες/νέα',
			cz: 'filmy/novinky',
			de: 'Filme/Neu',
			dk: 'movies/new',
			do: 'peliculas/nuevo',
			dz: 'movies/new',
			ec: 'peliculas/nuevo',
			ee: 'filmid/uus',
			eg: 'movies/new',
			es: 'peliculas/nuevo',
			fi: 'elokuvat/uudet',
			fj: 'movies/new',
			fr: 'films/nouveau',
			gf: 'films/nouveau',
			gg: 'movies/new',
			gh: 'movies/new',
			gi: 'movies/new',
			gq: 'peliculas/nuevo',
			gr: 'ταινίες/νέα',
			gt: 'peliculas/nuevo',
			gy: 'movies/new',
			hk: '電影/最新',
			hn: 'peliculas/nuevo',
			hr: 'filmovi/novo',
			hu: 'filmek/uj',
			id: 'movies/new',
			ie: 'movies/new',
			il: 'movies/new',
			in: 'movies/new',
			iq: 'movies/new',
			is: 'kvikmyndir/ný',
			it: 'film/novità',
			jm: 'movies/new',
			jo: 'movies/new',
			jp: '映画/新作',
			ke: 'movies/new',
			kr: '영화/최신',
			kw: 'movies/new',
			lb: 'movies/new',
			lc: 'movies/new',
			li: 'Filme/Neu',
			lt: 'filmai/nauji',
			lu: 'films/nouveau',
			lv: 'filmas/jauns',
			ly: 'movies/new',
			ma: 'movies/new',
			mc: 'films/nouveau',
			md: 'filme/nou',
			me: 'filmovi/novo',
			mg: 'films/nouveau',
			mk: 'филмови/ново',
			ml: 'films/nouveau',
			mt: 'films/ġdid',
			mu: 'films/nouveau',
			mw: 'movies/new',
			mx: 'peliculas/nuevo',
			my: 'movies/new',
			mz: 'filmes/novo',
			ne: 'films/nouveau',
			ng: 'movies/new',
			ni: 'peliculas/nuevo',
			nl: 'movies/new',
			no: 'movies/new',
			nz: 'movies/new',
			om: 'movies/new',
			pa: 'peliculas/nuevo',
			pe: 'peliculas/nuevo',
			pf: 'films/nouveau',
			pg: 'movies/new',
			ph: 'movies/new',
			pk: 'movies/new',
			pl: 'filmy/nowe',
			ps: 'movies/new',
			pt: 'filmes/novo',
			py: 'peliculas/nuevo',
			qa: 'movies/new',
			ro: 'filme/nou',
			rs: 'filmovi/novo',
			ru: 'фильмы/новые',
			sa: 'movies/new',
			sc: 'films/nouveau',
			se: 'filmer/nya',
			sg: 'movies/new',
			sk: 'filmy/nový',
			si: 'filmi/novo',
			sm: 'film/novità',
			sn: 'films/nouveau',
			sv: 'peliculas/nuevo',
			tc: 'movies/new',
			td: 'movies/new',
			th: 'movies/new',
			tn: 'movies/new',
			tr: 'filmler/yeni',
			tt: 'movies/new',
			tw: '電影/最新',
			tz: 'filamu/mpya',
			ua: 'фільми/новинки',
			ug: 'movies/new',
			uk: 'movies/new',
			us: 'movies/new',
			uy: 'peliculas/nuevo',
			va: 'film/novità',
			ve: 'peliculas/nuevo',
			xk: 'filmat/risitë',
			ye: 'movies/new',
			za: 'movies/new',
			zm: 'movies/new',
			zw: 'movies/new',
		},
		redirect: {
			dk: 'film/nye',
			id: 'film/baru',
			lt: 'filmai/nauji',
			lv: 'filmas/jauns',
			my: 'filem/terbaru',
			no: 'filmer/nyheter',
			se: 'filmer/nya',
			th: 'ภาพยนต์/ใหม่',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.NEW,
			newPageType: NewPageType.New,
			initialRouteState: { content_types: [TitleObjectType.MOVIE] },
			contexts: ['filter'],
			tab: 'new',
			pageType: PageType.NEW,
		},
	},
	'app.titles.new.show': {
		component: New,
		urls: {
			ad: 'series/nou',
			ae: 'tv-shows/new',
			ag: 'tv-shows/new',
			al: 'seriale-televizive/risitë',
			az: 'televiziya-şouları/yeni',
			ao: 'series/novo',
			ar: 'series/nuevo',
			at: 'Serien/Neu',
			au: 'tv-shows/new',
			ba: 'tv-serije/novo',
			bb: 'tv-shows/new',
			be: 'series/nouveau',
			bf: 'series/nouveau',
			bg: 'сериали/нови',
			bh: 'tv-shows/new',
			bm: 'tv-shows/new',
			bo: 'series/nuevo',
			br: 'series/novo',
			bs: 'tv-shows/new',
			by: 'сезон/новыя',
			bz: 'tv-shows/new',
			ca: 'tv-shows/new',
			cd: 'series/nouveau',
			ch: 'Serien/Neu',
			ci: 'series/nouveau',
			cl: 'series/nuevo',
			cm: 'tv-shows/new',
			co: 'series/nuevo',
			cr: 'series/nuevo',
			cu: 'sericu/nuevo',
			cv: 'series/novo',
			cy: 'τηλεοπτικές-σειρές/νέα',
			cz: 'serialy/novinky',
			de: 'Serien/Neu',
			dk: 'tv-series/new',
			do: 'serido/nuevo',
			dz: 'tv-shows/new',
			ec: 'series/nuevo',
			ee: 'seriaalid/uus',
			eg: 'tv-shows/new',
			es: 'series/nuevo',
			fi: 'sarjat/uudet',
			fj: 'tv-shows/new',
			fr: 'series/nouveau',
			gf: 'series/nouveau',
			gg: 'tv-shows/new',
			gh: 'tv-shows/new',
			gi: 'tv-shows/new',
			gq: 'serigq/nuevo',
			gr: 'τηλεοπτικές-σειρές/νέα',
			gt: 'series/nuevo',
			gy: 'tv-shows/new',
			hk: '節目/最新',
			hn: 'series/nuevo',
			hr: 'serije/novo',
			hu: 'tv-sorozat/uj',
			id: 'tv-shows/new',
			ie: 'tv-series/new',
			il: 'tv-shows/new',
			in: 'tv-shows/new',
			iq: 'tv-shows/new',
			is: 'þættir/ný',
			it: 'serie-tv/novità',
			jm: 'tv-shows/new',
			jo: 'tv-shows/new',
			jp: 'テレビ番組/新作',
			ke: 'tv-shows/new',
			kr: 'TV-프로그램/최신',
			kw: 'tv-shows/new',
			lb: 'tv-shows/new',
			lc: 'tv-shows/new',
			li: 'Serien/Neu',
			lt: 'tv-laidos/nauji',
			lu: 'series/nouveau',
			lv: 'tv-pārraide/jauns',
			ly: 'tv-shows/new',
			ma: 'tv-shows/new',
			mc: 'series/nouveau',
			md: 'seriale/nou',
			me: 'tv-programi/novo',
			mg: 'series/nouveau',
			mk: 'тв-серии/ново',
			ml: 'series/nouveau',
			mt: 'serje-televiżivi/ġdid',
			mu: 'series/nouveau',
			mw: 'tv-shows/new',
			mx: 'series/nuevo',
			my: 'tv-shows/new',
			mz: 'series/novo',
			ne: 'series/nouveau',
			ng: 'tv-shows/new',
			ni: 'serini/nuevo',
			nl: 'tv-series/new',
			no: 'tv-series/new',
			nz: 'tv-shows/new',
			om: 'tv-shows/new',
			pa: 'seripa/nuevo',
			pe: 'series/nuevo',
			pf: 'series/nouveau',
			pg: 'tv-series/new',
			ph: 'tv-shows/new',
			pk: 'tv-shows/new',
			pl: 'seriale/nowe',
			ps: 'tv-shows/new',
			pt: 'series/novo',
			py: 'series/nuevo',
			qa: 'tv-shows/new',
			ro: 'seriale/nou',
			rs: 'tv-programi/novo',
			ru: 'сериалы/новые',
			sa: 'tv-shows/new',
			sc: 'series/nouveau',
			se: 'tv-serier/nya',
			sg: 'tv-shows/new',
			sk: 'seriály/nový',
			si: 'serije/novo',
			sm: 'serie-tv/novità',
			sn: 'series/nouveau',
			sv: 'serisv/nuevo',
			tc: 'tv-shows/new',
			td: 'tv-shows/new',
			th: 'tv-shows/new',
			tn: 'tv-shows/new',
			tr: 'tv-dizileri/yeni',
			tt: 'tv-shows/new',
			tw: '電視節目/最新',
			tz: 'maonyesho-ya-tv/mpya',
			ua: 'телесеріали/новинки',
			ug: 'tv-shows/new',
			uk: 'tv-series/new',
			us: 'tv-shows/new',
			uy: 'seriuy/nuevo',
			va: 'serie-tv/novità',
			ve: 'series/nuevo',
			xk: 'seriale-televizive/risitë',
			ye: 'tv-shows/new',
			za: 'tv-series/new',
			zm: 'tv-shows/new',
			zw: 'tv-shows/new',
		},
		redirect: {
			dk: 'tv-serier/nye',
			id: 'acara-tv/baru',
			lt: 'tv-laidos/nauji',
			lv: 'tv-pārraide/jauns',
			my: 'rancangan-tv/terbaru',
			no: 'tv-serier/nyheter',
			se: 'tv-serier/nya',
			th: 'รายการทีวี/ใหม่',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.NEW,
			newPageType: NewPageType.New,
			initialRouteState: { content_types: [TitleObjectType.SHOW] },
			contexts: ['filter'],
			tab: 'new',
			pageType: PageType.NEW,
		},
	},
	'app.titles.new.provider': {
		component: New,
		urls: {
			ad: 'plataforma/:provider/nou',
			ae: 'provider/:provider/new',
			ag: 'provider/:provider/new',
			al: 'ofruesi/:provider/risitë',
			az: 'provayder/:provider/yeni',
			ao: 'provedor/:provider/novo',
			ar: 'proveedor/:provider/nuevo',
			at: 'Anbieter/:provider/Neu',
			au: 'provider/:provider/new',
			ba: 'provajder/:provider/novo',
			bb: 'provider/:provider/new',
			be: 'plateforme/:provider/nouveau',
			bf: 'plateforme/:provider/nouveau',
			bg: 'доставчик/:provider/нови',
			bh: 'provider/:provider/new',
			bm: 'provider/:provider/new',
			bo: 'proveedor/:provider/nuevo',
			br: 'provedor/:provider/novo',
			bs: 'provider/:provider/new',
			by: 'правайдэр/:provider/новыя',
			bz: 'provider/:provider/new',
			ca: 'provider/:provider/new',
			cd: 'plateforme/:provider/nouveau',
			ch: 'Anbieter/:provider/Neu',
			ci: 'plateforme/:provider/nouveau',
			cl: 'proveedor/:provider/nuevo',
			cm: 'provider/:provider/new',
			co: 'proveedor/:provider/nuevo',
			cr: 'proveedor/:provider/nuevo',
			cu: 'proveedor/:provider/nuevo',
			cv: 'provedor/:provider/novo',
			cy: 'πάροχος/:provider/νέα',
			cz: 'poskytovatel/:provider/novinky',
			de: 'Anbieter/:provider/Neu',
			dk: 'provider/:provider/new',
			do: 'proveedor/:provider/nuevo',
			dz: 'provider/:provider/new',
			ec: 'proveedor/:provider/nuevo',
			ee: 'teenusepakkuja/:provider/uus',
			eg: 'provider/:provider/new',
			es: 'proveedor/:provider/nuevo',
			fi: 'palvelu/:provider/uudet',
			fj: 'provider/:provider/new',
			fr: 'plateforme/:provider/nouveau',
			gf: 'plateforme/:provider/nouveau',
			gg: 'provider/:provider/new',
			gh: 'provider/:provider/new',
			gi: 'provider/:provider/new',
			gq: 'proveedor/:provider/nuevo',
			gr: 'πάροχος/:provider/νέα',
			gt: 'proveedor/:provider/nuevo',
			gy: 'provider/:provider/new',
			hk: '供應商/:provider/最新',
			hn: 'proveedor/:provider/nuevo',
			hr: 'pružatelj-usluge/:provider/novo',
			hu: 'szolgaltato/:provider/uj',
			id: 'provider/:provider/new',
			ie: 'provider/:provider/new',
			il: 'provider/:provider/new',
			in: 'provider/:provider/new',
			iq: 'provider/:provider/new',
			is: 'veita/:provider/ný',
			it: 'provider/:provider/novità',
			jm: 'provider/:provider/new',
			jo: 'provider/:provider/new',
			jp: '動画配信サービス/:provider/新作',
			ke: 'provider/:provider/new',
			kr: '동영상서비스/:provider/최신',
			kw: 'provider/:provider/new',
			lb: 'provider/:provider/new',
			lc: 'provider/:provider/new',
			li: 'Anbieter/:provider/Neu',
			lt: 'tiekėjas/:provider/nauji',
			lu: 'plateforme/:provider/nouveau',
			lv: 'apgādātājs/:provider/jauns',
			ly: 'provider/:provider/new',
			ma: 'provider/:provider/new',
			mc: 'plateforme/:provider/nouveau',
			md: 'furnizor/:provider/nou',
			me: 'provajder/:provider/novo',
			mg: 'plateforme/:provider/nouveau',
			mk: 'провајдер/:provider/ново',
			ml: 'plateforme/:provider/nouveau',
			mt: 'fornitur/:provider/ġdid',
			mu: 'plateforme/:provider/nouveau',
			mw: 'provider/:provider/new',
			mx: 'proveedor/:provider/nuevo',
			my: 'provider/:provider/new',
			mz: 'provedor/:provider/novo',
			ne: 'plateforme/:provider/nouveau',
			ng: 'provider/:provider/new',
			ni: 'proveedor/:provider/nuevo',
			nl: 'provider/:provider/new',
			no: 'provider/:provider/new',
			nz: 'provider/:provider/new',
			om: 'provider/:provider/new',
			pa: 'proveedor/:provider/nuevo',
			pe: 'proveedor/:provider/nuevo',
			pf: 'plateforme/:provider/nouveau',
			pg: 'provider/:provider/new',
			ph: 'provider/:provider/new',
			pk: 'provider/:provider/new',
			pl: 'serwis-streamingowy/:provider/nowe',
			ps: 'provider/:provider/new',
			pt: 'provedor/:provider/novo',
			py: 'proveedor/:provider/nuevo',
			qa: 'provider/:provider/new',
			ro: 'furnizor/:provider/nou',
			rs: 'provajder/:provider/novo',
			ru: 'провайдер/:provider/новые',
			sa: 'provider/:provider/new',
			sc: 'plateforme/:provider/nouveau',
			se: 'leverantör/:provider/nya',
			sg: 'provider/:provider/new',
			sk: 'poskytovateľ/:provider/nový',
			si: 'ponudnik/:provider/novo',
			sm: 'provider/:provider/novità',
			sn: 'plateforme/:provider/nouveau',
			sv: 'proveedor/:provider/nuevo',
			tt: 'provider/:provider/new',
			tc: 'provider/:provider/new',
			td: 'provider/:provider/new',
			th: 'provider/:provider/new',
			tn: 'provider/:provider/new',
			tr: 'saglayici/:provider/yeni',
			tw: '平台/:provider/最新',
			tz: 'mtoa-huduma/:provider/mpya',
			ua: 'провайдер/:provider/новинки',
			ug: 'provider/:provider/new',
			uk: 'provider/:provider/new',
			us: 'provider/:provider/new',
			uy: 'proveedor/:provider/nuevo',
			va: 'provider/:provider/novità',
			ve: 'proveedor/:provider/nuevo',
			xk: 'ofruesi/:provider/risitë',
			ye: 'provider/:provider/new',
			za: 'provider/:provider/new',
			zm: 'provider/:provider/new',
			zw: 'provider/:provider/new',
		},
		redirect: {
			dk: 'udbyder/:provider/nye',
			id: 'penyedia/:provider/baru',
			lt: 'tiekėjas/:provider/nauji',
			lv: 'apgādātājs/:provider/jauns',
			my: 'pemberi/:provider/baru',
			no: 'tjeneste/:provider/nyheter',
			se: 'leverantör/:provider/nya',
			th: 'ผู้ให้บริการ/:provider/ใหม่',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.NEW,
			newPageType: NewPageType.New,
			contexts: ['filter'],
			tab: 'new',
			pageType: PageType.NEW,
		},
	},
	'app.titles.new.provider-movie': {
		component: New,
		urls: {
			ad: 'plataforma/:provider/nou/pellicules',
			ae: 'provider/:provider/new/movies',
			ag: 'provider/:provider/new/movies',
			al: 'ofruesi/:provider/risitë/filmat',
			az: 'provayder/:provider/yeni/filmlər',
			ao: 'provedor/:provider/novo/filmes',
			ar: 'proveedor/:provider/nuevo/peliculas',
			at: 'Anbieter/:provider/Neu/Filme',
			au: 'provider/:provider/new/movies',
			ba: 'provajder/:provider/novo/filmovi',
			bb: 'provider/:provider/new/movies',
			be: 'plateforme/:provider/nouveau/films',
			bf: 'plateforme/:provider/nouveau/films',
			bg: 'доставчик/:provider/нови/филми',
			bh: 'provider/:provider/new/movies',
			bm: 'provider/:provider/new/movies',
			bo: 'proveedor/:provider/nuevo/peliculas',
			br: 'provedor/:provider/novo/filmes',
			bs: 'provider/:provider/new/movies',
			by: 'правайдэр/:provider/новыя/фільмы',
			bz: 'provider/:provider/new/movies',
			ca: 'provider/:provider/new/movies',
			cd: 'plateforme/:provider/nouveau/films',
			ch: 'Anbieter/:provider/Neu/Filme',
			ci: 'plateforme/:provider/nouveau/films',
			cl: 'proveedor/:provider/nuevo/peliculas',
			cm: 'provider/:provider/new/movies',
			co: 'proveedor/:provider/nuevo/peliculas',
			cr: 'proveedor/:provider/nuevo/peliculas',
			cu: 'proveedor/:provider/nuevo/peliculas',
			cv: 'provedor/:provider/novo/filmes',
			cy: 'πάροχος/:provider/νέα/ταινίες',
			cz: 'poskytovatel/:provider/novinky/filmy',
			de: 'Anbieter/:provider/Neu/Filme',
			dk: 'provider/:provider/new/movies',
			do: 'proveedor/:provider/nuevo/peliculas',
			dz: 'provider/:provider/new/movies',
			ec: 'proveedor/:provider/nuevo/peliculas',
			ee: 'teenusepakkuja/:provider/uus/filmid',
			eg: 'provider/:provider/new/movies',
			es: 'proveedor/:provider/nuevo/peliculas',
			fi: 'palvelu/:provider/uudet/elokuvat',
			fj: 'provider/:provider/new/movies',
			fr: 'plateforme/:provider/nouveau/films',
			gf: 'plateforme/:provider/nouveau/films',
			gg: 'provider/:provider/new/movies',
			gh: 'provider/:provider/new/movies',
			gi: 'provider/:provider/new/movies',
			gq: 'proveedor/:provider/nuevo/peliculas',
			gr: 'πάροχος/:provider/νέα/ταινίες',
			gt: 'proveedor/:provider/nuevo/peliculas',
			gy: 'provider/:provider/new/movies',
			hk: '供應商/:provider/最新/電影',
			hn: 'proveedor/:provider/nuevo/peliculas',
			hr: 'pružatelj-usluge/:provider/novo/filmovi',
			hu: 'szolgaltato/:provider/uj/filmek',
			id: 'provider/:provider/new/movies',
			ie: 'provider/:provider/new/movies',
			il: 'provider/:provider/new/movies',
			in: 'provider/:provider/new/movies',
			iq: 'provider/:provider/new/movies',
			is: 'veita/:provider/ný/kvikmyndir',
			it: 'provider/:provider/novità/film',
			jm: 'provider/:provider/new/movies',
			jo: 'provider/:provider/new/movies',
			jp: '動画配信サービス/:provider/新作/映画',
			ke: 'provider/:provider/new/movies',
			kr: '동영상서비스/:provider/최신/영화산업',
			kw: 'provider/:provider/new/movies',
			lb: 'provider/:provider/new/movies',
			lc: 'provider/:provider/new/movies',
			li: 'Anbieter/:provider/Neu/Filme',
			lt: 'tiekėjas/:provider/nauji/filmai',
			lu: 'plateforme/:provider/nouveau/films',
			lv: 'apgādātājs/:provider/jauns/filmas',
			ly: 'provider/:provider/new/movies',
			ma: 'provider/:provider/new/movies',
			mc: 'plateforme/:provider/nouveau/films',
			md: 'furnizor/:provider/nou/filme',
			me: 'provajder/:provider/novo/filmovi',
			mg: 'plateforme/:provider/nouveau/films',
			mk: 'провајдер/:provider/ново/филмови',
			ml: 'plateforme/:provider/nouveau/films',
			mt: 'fornitur/:provider/ġdid/films',
			mu: 'plateforme/:provider/nouveau/films',
			mw: 'provider/:provider/new/movies',
			mx: 'proveedor/:provider/nuevo/peliculas',
			my: 'provider/:provider/new/movies',
			mz: 'provedor/:provider/novo/filmes',
			ne: 'plateforme/:provider/nouveau/films',
			ng: 'provider/:provider/new/movies',
			ni: 'proveedor/:provider/nuevo/peliculas',
			nl: 'provider/:provider/new/movies',
			no: 'provider/:provider/new/movies',
			nz: 'provider/:provider/new/movies',
			om: 'provider/:provider/new/movies',
			pa: 'proveedor/:provider/nuevo/peliculas',
			pe: 'proveedor/:provider/nuevo/peliculas',
			pf: 'plateforme/:provider/nouveau/films',
			pg: 'provider/:provider/new/movies',
			ph: 'provider/:provider/new/movies',
			pk: 'provider/:provider/new/movies',
			pl: 'serwis-streamingowy/:provider/nowe/filmy',
			ps: 'provider/:provider/new/movies',
			pt: 'provedor/:provider/novo/filmes',
			py: 'proveedor/:provider/nuevo/peliculas',
			qa: 'provider/:provider/new/movies',
			ro: 'furnizor/:provider/nou/filme',
			rs: 'provajder/:provider/novo/filmovi',
			ru: 'провайдер/:provider/новые/фильмы',
			sa: 'provider/:provider/new/movies',
			sc: 'plateforme/:provider/nouveau/films',
			se: 'leverantör/:provider/nya/filmer',
			sg: 'provider/:provider/new/movies',
			sk: 'poskytovateľ/:provider/nový/filmy',
			si: 'ponudnik/:provider/novo/filmi',
			sm: 'provider/:provider/novità/film',
			sn: 'plateforme/:provider/nouveau/films',
			sv: 'proveedor/:provider/nuevo/peliculas',
			tc: 'provider/:provider/new/movies',
			td: 'provider/:provider/new/movies',
			th: 'provider/:provider/new/movies',
			tn: 'provider/:provider/new/movies',
			tr: 'saglayici/:provider/yeni/filmler',
			tt: 'provider/:provider/new/movies',
			tw: '平台/:provider/最新/電影',
			tz: 'mtoa-huduma/:provider/mpya/filamu',
			ua: 'провайдер/:provider/новинки/фільми',
			ug: 'provider/:provider/new/movies',
			uk: 'provider/:provider/new/movies',
			us: 'provider/:provider/new/movies',
			uy: 'proveedor/:provider/nuevo/peliculas',
			va: 'provider/:provider/novità/film',
			ve: 'proveedor/:provider/nuevo/peliculas',
			xk: 'ofruesi/:provider/risitë/filmat',
			ye: 'provider/:provider/new/movies',
			za: 'provider/:provider/new/movies',
			zm: 'provider/:provider/new/movies',
			zw: 'provider/:provider/new/movies',
		},
		redirect: {
			dk: 'udbyder/:provider/nye/film',
			id: 'penyedia/:provider/baru/film',
			lt: 'tiekėjas/:provider/nauji/filmai',
			lv: 'apgādātājs/:provider/jauns/filmas',
			my: 'pemberi/:provider/baru/filem',
			no: 'tjeneste/:provider/nyheter/filmer',
			se: 'leverantör/:provider/nya/filmer',
			th: 'ผู้ให้บริการ/:provider/ใหม่/ภาพยนต์',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.NEW,
			newPageType: NewPageType.New,
			initialRouteState: { content_types: [TitleObjectType.MOVIE] },
			contexts: ['filter'],
			tab: 'new',
			pageType: PageType.NEW,
		},
	},
	'app.titles.new.provider-show': {
		component: New,
		urls: {
			ad: 'plataforma/:provider/nou/series',
			ae: 'provider/:provider/new/tv-shows',
			ag: 'provider/:provider/new/tv-shows',
			al: 'ofruesi/:provider/risitë/seriale-televizive',
			az: 'provayder/:provider/yeni/televiziya-şouları',
			ao: 'provedor/:provider/novo/series',
			ar: 'proveedor/:provider/nuevo/series',
			at: 'Anbieter/:provider/Neu/Serien',
			au: 'provider/:provider/new/tv-shows',
			ba: 'provajder/:provider/novo/tv-serije',
			bb: 'provider/:provider/new/tv-shows',
			be: 'plateforme/:provider/nouveau/series',
			bf: 'plateforme/:provider/nouveau/series',
			bg: 'доставчик/:provider/нови/сериали',
			bh: 'provider/:provider/new/tv-shows',
			bm: 'provider/:provider/new/tv-shows',
			bo: 'proveedor/:provider/nuevo/series',
			br: 'provedor/:provider/novo/series',
			bs: 'provider/:provider/new/tv-shows',
			by: 'правайдэр/:provider/новыя/сезон',
			bz: 'provider/:provider/new/tv-series',
			ca: 'provider/:provider/new/tv-shows',
			cd: 'plateforme/:provider/nouveau/series',
			ch: 'Anbieter/:provider/Neu/Serien',
			ci: 'plateforme/:provider/nouveau/series',
			cl: 'proveedor/:provider/nuevo/series',
			cm: 'provider/:provider/new/tv-series',
			co: 'proveedor/:provider/nuevo/series',
			cr: 'proveedor/:provider/nuevo/series',
			cu: 'proveedor/:provider/nuevo/series',
			cv: 'provedor/:provider/novo/series',
			cy: 'πάροχος/:provider/νέα/τηλεοπτικές-σειρές',
			cz: 'poskytovatel/:provider/novinky/serialy',
			de: 'Anbieter/:provider/Neu/Serien',
			dk: 'provider/:provider/new/tv-series',
			do: 'proveedor/:provider/nuevo/series',
			dz: 'provider/:provider/new/tv-shows',
			ec: 'proveedor/:provider/nuevo/series',
			ee: 'teenusepakkuja/:provider/uus/seriaalid',
			eg: 'provider/:provider/new/tv-shows',
			es: 'proveedor/:provider/nuevo/series',
			fi: 'palvelu/:provider/uudet/sarjat',
			fj: 'provider/:provider/new/tv-shows',
			fr: 'plateforme/:provider/nouveau/series',
			gf: 'plateforme/:provider/nouveau/series',
			gg: 'provider/:provider/new/tv-shows',
			gh: 'provider/:provider/new/tv-shows',
			gi: 'provider/:provider/new/tv-shows',
			gq: 'proveedor/:provider/nuevo/series',
			gr: 'πάροχος/:provider/νέα/τηλεοπτικές-σειρές',
			gt: 'proveedor/:provider/nuevo/series',
			gy: 'provider/:provider/new/tv-series',
			hk: '供應商/:provider/最新/節目',
			hn: 'proveedor/:provider/nuevo/series',
			hr: 'pružatelj-usluge/:provider/novo/serije',
			hu: 'szolgaltato/:provider/uj/tv-sorozat',
			id: 'provider/:provider/new/tv-shows',
			ie: 'provider/:provider/new/tv-series',
			il: 'provider/:provider/new/tv-shows',
			in: 'provider/:provider/new/tv-shows',
			iq: 'provider/:provider/new/tv-shows',
			is: 'veita/:provider/ný/þættir',
			it: 'provider/:provider/novità/serie-tv',
			jm: 'provider/:provider/new/tv-shows',
			jo: 'provider/:provider/new/tv-shows',
			jp: '動画配信サービス/:provider/新作/テレビ番組',
			ke: 'provider/:provider/new/tv-shows',
			kr: '동영상서비스/:provider/최신/TV-프로그램',
			kw: 'provider/:provider/new/tv-shows',
			lb: 'provider/:provider/new/tv-shows',
			lc: 'provider/:provider/new/tv-shows',
			li: 'Anbieter/:provider/Neu/Serien',
			lt: 'tiekėjas/:provider/nauji/tv-laidos',
			lu: 'plateforme/:provider/nouveau/series',
			lv: 'apgādātājs/:provider/jauns/tv-pārraide',
			ly: 'provider/:provider/new/tv-shows',
			ma: 'provider/:provider/new/tv-shows',
			mc: 'plateforme/:provider/nouveau/series',
			md: 'furnizor/:provider/nou/seriale',
			me: 'provajder/:provider/novo/tv-programi',
			mg: 'plateforme/:provider/nouveau/series',
			mk: 'провајдер/:provider/ново/тв-серии',
			ml: 'plateforme/:provider/nouveau/series',
			mt: 'fornitur/:provider/ġdid/serje-televiżivi',
			mu: 'plateforme/:provider/nouveau/series',
			mw: 'provider/:provider/new/tv-series',
			mx: 'proveedor/:provider/nuevo/series',
			my: 'provider/:provider/new/tv-shows',
			mz: 'provedor/:provider/novo/series',
			ne: 'plateforme/:provider/nouveau/series',
			ng: 'provider/:provider/new/tv-shows',
			ni: 'proveedor/:provider/nuevo/series',
			nl: 'provider/:provider/new/tv-series',
			no: 'provider/:provider/new/tv-series',
			nz: 'provider/:provider/new/tv-shows',
			om: 'provider/:provider/new/tv-shows',
			pa: 'proveedor/:provider/nuevo/series',
			pe: 'proveedor/:provider/nuevo/series',
			pf: 'plateforme/:provider/nouveau/series',
			pg: 'provider/:provider/new/tv-series',
			ph: 'provider/:provider/new/tv-shows',
			pk: 'provider/:provider/new/tv-shows',
			pl: 'serwis-streamingowy/:provider/nowe/seriale',
			ps: 'provider/:provider/new/tv-shows',
			pt: 'provedor/:provider/novo/series',
			py: 'proveedor/:provider/nuevo/series',
			qa: 'provider/:provider/new/tv-shows',
			ro: 'furnizor/:provider/nou/seriale',
			rs: 'provajder/:provider/novo/tv-programi',
			ru: 'провайдер/:provider/новые/сериалы',
			sa: 'provider/:provider/new/tv-shows',
			sc: 'plateforme/:provider/nouveau/series',
			se: 'leverantör/:provider/nya/tv-serier',
			sg: 'provider/:provider/new/tv-shows',
			sk: 'poskytovateľ/:provider/nový/seriály',
			si: 'ponudnik/:provider/novo/serije',
			sm: 'provider/:provider/novità/serie-tv',
			sn: 'plateforme/:provider/nouveau/series',
			sv: 'proveedor/:provider/nuevo/series',
			tc: 'provider/:provider/new/tv-shows',
			td: 'provider/:provider/new/tv-shows',
			th: 'provider/:provider/new/tv-shows',
			tn: 'provider/:provider/new/tv-shows',
			tr: 'saglayici/:provider/yeni/tv-dizileri',
			tt: 'provider/:provider/new/tv-shows',
			tw: '平台/:provider/最新/節目',
			tz: 'mtoa-huduma/:provider/mpya/maonyesho-ya-tv',
			ua: 'провайдер/:provider/новинки/телесеріали',
			ug: 'provider/:provider/new/tv-shows',
			uk: 'provider/:provider/new/tv-series',
			us: 'provider/:provider/new/tv-shows',
			uy: 'proveedor/:provider/nuevo/series',
			va: 'provider/:provider/novità/serie-tv',
			ve: 'proveedor/:provider/nuevo/series',
			xk: 'ofruesi/:provider/risitë/seriale-televizive',
			ye: 'provider/:provider/new/tv-shows',
			za: 'provider/:provider/new/tv-series',
			zm: 'provider/:provider/new/tv-shows',
			zw: 'provider/:provider/new/tv-series',
		},
		redirect: {
			dk: 'udbyder/:provider/nye/tv-serier',
			id: 'penyedia/:provider/baru/acara-tv',
			lt: 'tiekėjas/:provider/nauji/tv-laidos',
			lv: 'apgādātājs/:provider/jauns/tv-pārraide',
			my: 'pemberi/:provider/baru/rancangan-tv',
			no: 'tjeneste/:provider/nyheter/tv-serier',
			se: 'leverantör/:provider/nya/tv-serier',
			th: 'ผู้ให้บริการ/:provider/ใหม่/รายการทีวี',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.NEW,
			newPageType: NewPageType.New,
			initialRouteState: { content_types: [TitleObjectType.SHOW] },
			contexts: ['filter'],
			tab: 'new',
			pageType: PageType.NEW,
		},
	},

	// --- NEW / ON SALE ---
	'app.titles.pricedrops.list': {
		component: New,
		urls: {
			ad: 'on-sale',
			ae: 'on-sale',
			ag: 'on-sale',
			al: 'on-sale',
			az: 'on-sale',
			ao: 'on-sale',
			ar: 'ofertas',
			at: 'heute-im-sale',
			au: 'on-sale',
			ba: 'on-sale',
			bb: 'on-sale',
			be: 'promos',
			bf: 'on-sale',
			bg: 'в-разпродажба',
			bh: 'on-sale',
			bm: 'on-sale',
			bo: 'ofertas',
			br: 'ofertas',
			bs: 'on-sale',
			by: 'on-sale',
			bz: 'on-sale',
			ca: 'on-sale',
			cd: 'on-sale',
			ch: 'heute-im-sale',
			ci: 'promos',
			cl: 'ofertas',
			cm: 'on-sale',
			co: 'ofertas',
			cr: 'ofertas',
			cu: 'ofertas',
			cv: 'ofertas',
			cy: 'on-sale',
			cz: 'na-prodej',
			de: 'heute-im-sale',
			dk: 'on-sale',
			do: 'ofertas',
			dz: 'on-sale',
			ec: 'ofertas',
			ee: 'muugil',
			eg: 'on-sale',
			es: 'ofertas',
			fi: 'tarjouksessa',
			fj: 'on-sale',
			fr: 'promos',
			gf: 'promos',
			gg: 'on-sale',
			gh: 'on-sale',
			gi: 'on-sale',
			gq: 'ofertas',
			gr: 'σε-προσφορά',
			gt: 'ofertas',
			gy: 'on-sale',
			hk: 'on-sale',
			hn: 'ofertas',
			hr: 'promotivna-ponuda',
			hu: 'elado',
			id: 'on-sale',
			ie: 'on-sale',
			il: 'on-sale',
			in: 'on-sale',
			iq: 'on-sale',
			is: 'utsala',
			it: 'offerte',
			jm: 'on-sale',
			jo: 'on-sale',
			jp: '発売中',
			ke: 'on-sale',
			kr: '할인-중',
			kw: 'on-sale',
			lb: 'on-sale',
			lc: 'on-sale',
			li: 'heute-im-sale',
			lt: 'ispardavimas',
			lu: 'on-sale',
			lv: 'izpardosana',
			ly: 'on-sale',
			ma: 'on-sale',
			mc: 'promos',
			md: 'la-reducere',
			me: 'on-sale',
			mg: 'on-sale',
			mk: 'on-sale',
			ml: 'on-sale',
			mt: 'on-sale',
			mu: 'promos',
			mw: 'on-sale',
			mx: 'ofertas',
			my: 'on-sale',
			mz: 'ofertas',
			ne: 'promos',
			ng: 'on-sale',
			ni: 'on-sale',
			nl: 'on-sale',
			no: 'on-sale',
			nz: 'on-sale',
			om: 'on-sale',
			pa: 'ofertas',
			pe: 'ofertas',
			pf: 'promos',
			pg: 'on-sale',
			ph: 'on-sale',
			pk: 'on-sale',
			pl: 'na-wyprzedazy',
			ps: 'on-sale',
			pt: 'ofertas',
			py: 'ofertas',
			qa: 'on-sale',
			ro: 'la-reducere',
			rs: 'on-sale',
			ru: 'продается',
			sa: 'on-sale',
			sc: 'promos',
			se: 'rabatterat-pris',
			sg: 'on-sale',
			sk: 'v-zlave',
			si: 'on-sale',
			sm: 'offerte',
			sn: 'promos',
			sv: 'ofertas',
			tc: 'on-sale',
			td: 'on-sale',
			th: 'on-sale',
			tn: 'on-sale',
			tr: 'indirimde',
			tt: 'on-sale',
			tw: 'on-sale',
			tz: 'on-sale',
			ua: 'on-sale',
			ug: 'on-sale',
			uk: 'on-sale',
			us: 'on-sale',
			uy: 'ofertas',
			va: 'offerte',
			ve: 'ofertas',
			xk: 'on-sale',
			ye: 'on-sale',
			za: 'on-sale',
			zm: 'on-sale',
			zw: 'on-sale',
		},
		redirect: {
			dk: 'on-sale',
			id: 'on-sale',
			lt: 'išpardavimas',
			lv: 'izpārdošanā',
			my: 'on-sale',
			no: 'salg',
			se: 'on-sale',
			th: 'on-sale',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.NEW,
			newPageType: NewPageType.PriceDrop,
			contexts: ['filter'],
			tab: 'on-sale',
			pageType: PageType.PRICEDROP,
		},
	},
	'app.titles.pricedrops.movie': {
		component: New,
		urls: {
			ad: 'on-sale/pellicules',
			ae: 'on-sale/movies',
			ag: 'on-sale/movies',
			al: 'on-sale/filmat',
			az: 'on-sale/filmlər',
			ao: 'on-sale/filmes',
			ar: 'ofertas/peliculas',
			at: 'heute-im-sale/Filme',
			au: 'on-sale/movies',
			ba: 'on-sale/filmovi',
			bb: 'on-sale/movies',
			be: 'promos/films',
			bf: 'on-sale/films',
			bg: 'в-разпродажба/филми',
			bh: 'on-sale/movies',
			bm: 'on-sale/movies',
			bo: 'ofertas/peliculas',
			br: 'ofertas/filmes',
			bs: 'on-sale/movies',
			by: 'on-sale/фільмы',
			bz: 'on-sale/movies',
			ca: 'on-sale/movies',
			cd: 'on-sale/films',
			ch: 'heute-im-sale/Filme',
			ci: 'promos/films',
			cl: 'ofertas/peliculas',
			cm: 'on-sale/movies',
			co: 'ofertas/peliculas',
			cr: 'ofertas/peliculas',
			cu: 'ofertas/peliculas',
			cv: 'ofertas/filmes',
			cy: 'on-sale/ταινίες',
			cz: 'na-prodej/filmy',
			de: 'heute-im-sale/Filme',
			dk: 'on-sale/movies',
			do: 'ofertas/peliculas',
			dz: 'on-sale/movies',
			ec: 'ofertas/peliculas',
			ee: 'muugil/filmid',
			eg: 'on-sale/movies',
			es: 'ofertas/peliculas',
			fi: 'tarjouksessa/elokuvat',
			fj: 'on-sale/movies',
			fr: 'promos/films',
			gf: 'promos/films',
			gg: 'on-sale/movies',
			gh: 'on-sale/movies',
			gi: 'on-sale/movies',
			gq: 'ofertas/peliculas',
			gr: 'σε-προσφορά/ταινίες',
			gt: 'ofertas/peliculas',
			gy: 'on-sale/movies',
			hk: 'on-sale/電影',
			hn: 'ofertas/peliculas',
			hr: 'promotivna-ponuda/filmovi',
			hu: 'elado/filmek',
			id: 'on-sale/movies',
			ie: 'on-sale/movies',
			il: 'on-sale/movies',
			in: 'on-sale/movies',
			iq: 'on-sale/movies',
			is: 'utsala/kvikmyndir',
			it: 'offerte/film',
			jm: 'on-sale/movies',
			jo: 'on-sale/movies',
			jp: '発売中/映画',
			ke: 'on-sale/movies',
			kr: '할인-중/영화산업',
			kw: 'on-sale/movies',
			lb: 'on-sale/movies',
			lc: 'on-sale/movies',
			li: 'heute-im-sale/Filme',
			lt: 'ispardavimas/filmai',
			lu: 'on-sale/films',
			lv: 'izpardosana/filmas',
			ly: 'on-sale/movies',
			ma: 'on-sale/movies',
			mc: 'promos/films',
			md: 'la-reducere/filme',
			me: 'on-sale/filmovi',
			mg: 'on-sale/films',
			mk: 'on-sale/филмови',
			ml: 'on-sale/films',
			mt: 'on-sale/films',
			mu: 'promos/films',
			mw: 'on-sale/movies',
			mx: 'ofertas/peliculas',
			my: 'on-sale/movies',
			mz: 'ofertas/filmes',
			ne: 'promos/films',
			ng: 'on-sale/movies',
			ni: 'on-sale/peliculas',
			nl: 'on-sale/movies',
			no: 'on-sale/movies',
			nz: 'on-sale/movies',
			om: 'on-sale/movies',
			pa: 'ofertas/peliculas',
			pe: 'ofertas/peliculas',
			pf: 'promos/films',
			pg: 'on-sale/movies',
			ph: 'on-sale/movies',
			pk: 'on-sale/movies',
			pl: 'na-wyprzedazy/filmy',
			ps: 'on-sale/movies',
			pt: 'ofertas/filmes',
			py: 'ofertas/peliculas',
			qa: 'on-sale/movies',
			ro: 'la-reducere/filme',
			rs: 'on-sale/filmovi',
			ru: 'продается/фильмы',
			sa: 'on-sale/movies',
			sc: 'promos/films',
			se: 'rabatterat-pris/filmer',
			sg: 'on-sale/movies',
			sk: 'v-zlave/filmy',
			si: 'on-sale/filmi',
			sm: 'offerte/film',
			sn: 'promos/films',
			sv: 'ofertas/peliculas',
			tc: 'on-sale/movies',
			td: 'on-sale/movies',
			th: 'on-sale/movies',
			tn: 'on-sale/movies',
			tr: 'indirimde/filmler',
			tt: 'on-sale/movies',
			tw: 'on-sale/電影',
			tz: 'on-sale/filamu',
			ua: 'on-sale/фільми',
			ug: 'on-sale/movies',
			uk: 'on-sale/movies',
			us: 'on-sale/movies',
			uy: 'ofertas/peliculas',
			va: 'offerte/film',
			ve: 'ofertas/peliculas',
			xk: 'on-sale/filmat',
			ye: 'on-sale/movies',
			za: 'on-sale/movies',
			zm: 'on-sale/movies',
			zw: 'on-sale/movies',
		},
		redirect: {
			dk: 'on-sale/film',
			id: 'on-sale/film',
			lt: 'išpardavimas/filmai',
			lv: 'izpārdošanā/filmas',
			my: 'on-sale/filem',
			no: 'salg/filmer',
			se: 'on-sale/filmer',
			th: 'on-sale/ภาพยนต์',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.NEW,
			newPageType: NewPageType.PriceDrop,
			initialRouteState: { content_types: [TitleObjectType.MOVIE] },
			contexts: ['filter'],
			tab: 'on-sale',
			pageType: PageType.PRICEDROP,
		},
	},
	'app.titles.pricedrops.show': {
		component: New,
		urls: {
			ad: 'on-sale/series',
			ae: 'on-sale/tv-shows',
			ag: 'on-sale/tv-shows',
			al: 'on-sale/seriale-televizive',
			az: 'on-sale/televiziya-şouları',
			ao: 'on-sale/series',
			ar: 'ofertas/series',
			at: 'heute-im-sale/Serien',
			au: 'on-sale/tv-shows',
			ba: 'on-sale/tv-serije',
			bb: 'on-sale/tv-shows',
			be: 'promos/series',
			bf: 'on-sale/series',
			bg: 'в-разпродажба/сериали',
			bh: 'on-sale/tv-shows',
			bm: 'on-sale/tv-shows',
			bo: 'ofertas/series',
			br: 'ofertas/series',
			bs: 'on-sale/tv-shows',
			by: 'on-sale/сезон',
			bz: 'on-sale/tv-series',
			ca: 'on-sale/tv-shows',
			cd: 'on-sale/series',
			ch: 'heute-im-sale/Serien',
			ci: 'promos/series',
			cl: 'ofertas/series',
			cm: 'on-sale/tv-shows',
			co: 'ofertas/series',
			cr: 'ofertas/series',
			cu: 'ofertas/series',
			cv: 'ofertas/series',
			cy: 'on-sale/τηλεοπτικές-σειρές',
			cz: 'na-prodej/serialy',
			de: 'heute-im-sale/Serien',
			dk: 'on-sale/tv-series',
			do: 'ofertas/series',
			dz: 'on-sale/tv-shows',
			ec: 'ofertas/series',
			ee: 'muugil/seriaalid',
			eg: 'on-sale/tv-shows',
			es: 'ofertas/series',
			fi: 'tarjouksessa/sarjat',
			fj: 'on-sale/tv-shows',
			fr: 'promos/series',
			gf: 'promos/series',
			gg: 'on-sale/tv-shows',
			gh: 'on-sale/tv-shows',
			gi: 'on-sale/tv-shows',
			gq: 'ofertas/series',
			gr: 'σε-προσφορά/τηλεοπτικές-σειρές',
			gt: 'ofertas/series',
			gy: 'on-sale/tv-series',
			hk: 'on-sale/節目',
			hn: 'ofertas/series',
			hr: 'promotivna-ponuda/serije',
			hu: 'elado/tv-sorozat',
			id: 'on-sale/tv-shows',
			ie: 'on-sale/tv-series',
			il: 'on-sale/tv-shows',
			in: 'on-sale/tv-shows',
			iq: 'on-sale/tv-shows',
			is: 'utsala/þættir',
			it: 'offerte/serie-tv',
			jm: 'on-sale/tv-shows',
			jo: 'on-sale/tv-shows',
			jp: '発売中/テレビ番組',
			ke: 'on-sale/tv-shows',
			kr: '할인-중/TV-프로그램',
			kw: 'on-sale/tv-shows',
			lb: 'on-sale/tv-shows',
			lc: 'on-sale/tv-shows',
			li: 'heute-im-sale/Serien',
			lt: 'ispardavimas/tv-laidos',
			lu: 'on-sale/series',
			lv: 'izpardosana/tv-pārraide',
			ly: 'on-sale/tv-shows',
			ma: 'on-sale/tv-shows',
			mc: 'promos/series',
			md: 'la-reducere/seriale',
			me: 'on-sale/tv-programi',
			mg: 'on-sale/series',
			mk: 'on-sale/тв-серии',
			ml: 'on-sale/series',
			mt: 'on-sale/serje-televiżivi',
			mu: 'promos/series',
			mw: 'on-sale/tv-series',
			mx: 'ofertas/series',
			my: 'on-sale/tv-shows',
			mz: 'ofertas/series',
			ne: 'promos/series',
			ng: 'on-sale/tv-shows',
			ni: 'on-sale/series',
			nl: 'on-sale/tv-series',
			no: 'on-sale/tv-series',
			nz: 'on-sale/tv-shows',
			om: 'on-sale/tv-shows',
			pa: 'ofertas/series',
			pe: 'ofertas/series',
			pf: 'promos/series',
			pg: 'on-sale/tv-series',
			ph: 'on-sale/tv-shows',
			pk: 'on-sale/tv-shows',
			pl: 'na-wyprzedazy/seriale',
			ps: 'on-sale/tv-shows',
			pt: 'ofertas/series',
			py: 'ofertas/series',
			qa: 'on-sale/tv-shows',
			ro: 'la-reducere/seriale',
			rs: 'on-sale/tv-programi',
			ru: 'продается/сериалы',
			sa: 'on-sale/tv-shows',
			sc: 'promos/series',
			se: 'rabatterat-pris/tv-serier',
			sg: 'on-sale/tv-shows',
			sk: 'v-zlave/seriály',
			si: 'on-sale/serije',
			sm: 'offerte/serie-tv',
			sn: 'promos/series',
			sv: 'ofertas/series',
			tc: 'on-sale/tv-shows',
			td: 'on-sale/tv-shows',
			th: 'on-sale/tv-shows',
			tn: 'on-sale/tv-shows',
			tr: 'indirimde/tv-dizileri',
			tt: 'on-sale/tv-shows',
			tw: 'on-sale/節目',
			tz: 'on-sale/maonyesho-ya-tv',
			ua: 'on-sale/телесеріали',
			ug: 'on-sale/tv-shows',
			uk: 'on-sale/tv-series',
			us: 'on-sale/tv-shows',
			uy: 'ofertas/series',
			va: 'offerte/serie-tv',
			ve: 'ofertas/series',
			xk: 'on-sale/seriale-televizive',
			ye: 'on-sale/tv-shows',
			za: 'on-sale/tv-series',
			zm: 'on-sale/tv-shows',
			zw: 'on-sale/tv-series',
		},
		redirect: {
			dk: 'on-sale/tv-serier',
			id: 'on-sale/acara-tv',
			lt: 'išpardavimas/tv-laidos',
			lv: 'izpārdošanā/tv-pārraide',
			my: 'on-sale/rancangan-tv',
			no: 'salg/tv-serier',
			se: 'on-sale/tv-serier',
			th: 'on-sale/รายการทีวี',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.NEW,
			newPageType: NewPageType.PriceDrop,
			initialRouteState: { content_types: [TitleObjectType.SHOW] },
			contexts: ['filter'],
			tab: 'on-sale',
			pageType: PageType.PRICEDROP,
		},
	},

	// --- UPCOMING ---
	'app.titles.upcoming.list': {
		component: Upcoming,
		urls: {
			de: 'demnaechst',
			es: 'proximamente',
			fr: 'prochainement',
			uk: 'upcoming',
			us: 'upcoming',
			tr: 'yakinda',
			au: 'upcoming',
			it: 'prossimamente',
			in: 'upcoming',
			ca: 'upcoming',
			ar: 'proximamente',
			br: 'lancamentos',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.UPCOMING,
			contexts: ['filter'],
			tab: 'upcoming',
			pageType: PageType.UPCOMING,
		},
	},
	'app.titles.upcoming.movie': {
		component: Upcoming,
		urls: {
			de: 'Filme/demnaechst',
			es: 'peliculas/proximamente',
			fr: 'films/prochainement',
			uk: 'movies/upcoming',
			us: 'movies/upcoming',
			tr: 'filmler/yakinda',
			au: 'movies/upcoming',
			it: 'film/prossimamente',
			in: 'movies/upcoming',
			ca: 'movies/upcoming',
			ar: 'peliculas/proximamente',
			br: 'filmes/lancamentos',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.UPCOMING,
			initialRouteState: { content_types: [TitleObjectType.MOVIE] },
			contexts: ['filter'],
			tab: 'upcoming',
			pageType: PageType.UPCOMING,
		},
	},
	'app.titles.upcoming.show': {
		component: Upcoming,
		urls: {
			de: 'Serien/demnaechst',
			es: 'series/proximamente',
			fr: 'series/prochainement',
			uk: 'tv-series/upcoming',
			us: 'tv-shows/upcoming',
			tr: 'tv-dizileri/yakinda',
			au: 'tv-shows/upcoming',
			it: 'serie-tv/prossimamente',
			in: 'tv-shows/upcoming',
			ca: 'tv-shows/upcoming',
			ar: 'series/proximamente',
			br: 'series/lancamentos',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.UPCOMING,
			initialRouteState: { content_types: [TitleObjectType.SHOW, TitleObjectType.SEASON] },
			contexts: ['filter'],
			tab: 'upcoming',
			pageType: PageType.UPCOMING,
		},
	},
	'app.titles.upcoming.provider': {
		component: Upcoming,
		urls: {
			de: 'Anbieter/:provider/demnaechst',
			es: 'proveedor/:provider/proximamente',
			fr: 'plateforme/:provider/prochainement',
			uk: 'provider/:provider/upcoming',
			us: 'provider/:provider/upcoming',
			tr: 'saglayici/:provider/yakinda',
			au: 'provider/:provider/upcoming',
			it: 'provider/:provider/prossimamente',
			in: 'provider/:provider/upcoming',
			ca: 'provider/:provider/upcoming',
			ar: 'proveedor/:provider/proximamente',
			br: 'provedor/:provider/lancamentos',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.UPCOMING,
			contexts: ['filter'],
			tab: 'upcoming',
			pageType: PageType.UPCOMING,
		},
	},
	'app.titles.upcoming.provider-movie': {
		component: Upcoming,
		urls: {
			de: 'Anbieter/:provider/demnaechst/Filme',
			es: 'proveedor/:provider/proximamente/peliculas',
			fr: 'plateforme/:provider/prochainement/films',
			uk: 'provider/:provider/upcoming/movies',
			us: 'provider/:provider/upcoming/movies',
			tr: 'saglayici/:provider/yakinda/filmler',
			au: 'provider/:provider/upcoming/movies',
			it: 'provider/:provider/prossimamente/film',
			in: 'provider/:provider/upcoming/movies',
			ca: 'provider/:provider/upcoming/movies',
			ar: 'proveedor/:provider/proximamente/peliculas',
			br: 'provedor/:provider/lancamentos/filmes',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.UPCOMING,
			contexts: ['filter'],
			tab: 'upcoming',
			pageType: PageType.UPCOMING,
		},
	},
	'app.titles.upcoming.provider-show': {
		component: Upcoming,
		urls: {
			de: 'Anbieter/:provider/demnaechst/Serien',
			es: 'proveedor/:provider/proximamente/series',
			fr: 'plateforme/:provider/prochainement/series',
			uk: 'provider/:provider/upcoming/tv-series',
			us: 'provider/:provider/upcoming/tv-shows',
			tr: 'saglayici/:provider/yakinda/tv-dizileri',
			au: 'provider/:provider/upcoming/tv-shows',
			it: 'provider/:provider/prossimamente/serie-tv',
			in: 'provider/:provider/upcoming/tv-shows',
			ca: 'provider/:provider/upcoming/tv-shows',
			ar: 'proveedor/:provider/proximamente/series',
			br: 'provedor/:provider/lancamentos/series',
		},
		pageType: PageType.UPCOMING,
		meta: { hasFilterBar: true, collectionType: CollectionType.UPCOMING, contexts: ['filter'], tab: 'upcoming' },
	},

	// --- TITLE DETAIL ---
	'app.detail.movies': {
		component: TitleDetail,
		urls: {
			ad: 'pellicula/:title',
			ae: 'movie/:title',
			ag: 'movie/:title',
			al: 'filmi/:title',
			ao: 'filme/:title',
			ar: 'pelicula/:title',
			at: 'Film/:title',
			au: 'movie/:title',
			az: 'film/:title',
			ba: 'film/:title',
			bb: 'movie/:title',
			be: 'film/:title',
			bf: 'film/:title',
			bg: 'филм/:title',
			bh: 'movie/:title',
			bm: 'movie/:title',
			bo: 'pelicula/:title',
			br: 'filme/:title',
			bs: 'movie/:title',
			by: 'фільм/:title',
			bz: 'movie/:title',
			ca: 'movie/:title',
			cd: 'film/:title',
			ch: 'Film/:title',
			ci: 'film/:title',
			cl: 'pelicula/:title',
			cm: 'movie/:title',
			co: 'pelicula/:title',
			cr: 'pelicula/:title',
			cu: 'pelicula/:title',
			cv: 'filme/:title',
			cy: 'ταινία/:title',
			cz: 'film/:title',
			de: 'Film/:title',
			dk: 'movie/:title',
			do: 'pelicula/:title',
			dz: 'movie/:title',
			ec: 'pelicula/:title',
			ee: 'filmi/:title',
			eg: 'movie/:title',
			es: 'pelicula/:title',
			fi: 'elokuva/:title',
			fj: 'movie/:title',
			fr: 'film/:title',
			gf: 'film/:title',
			gg: 'movie/:title',
			gh: 'movie/:title',
			gi: 'movie/:title',
			gq: 'pelicula/:title',
			gr: 'ταινία/:title',
			gt: 'pelicula/:title',
			gy: 'movie/:title',
			hk: '電影/:title',
			hn: 'pelicula/:title',
			hr: 'film/:title',
			hu: 'film/:title',
			id: 'movie/:title',
			ie: 'movie/:title',
			il: 'movie/:title',
			in: 'movie/:title',
			iq: 'movie/:title',
			is: 'kvikmynd/:title',
			it: 'film/:title',
			jm: 'movie/:title',
			jo: 'movie/:title',
			jp: '映画/:title',
			ke: 'movie/:title',
			kr: '영화/:title',
			kw: 'movie/:title',
			lb: 'movie/:title',
			lc: 'movie/:title',
			li: 'Film/:title',
			lt: 'filmas/:title',
			lu: 'film/:title',
			lv: 'filma/:title',
			ly: 'movie/:title',
			ma: 'movie/:title',
			mc: 'film/:title',
			md: 'film/:title',
			me: 'film/:title',
			mg: 'film/:title',
			mk: 'филм/:title',
			ml: 'film/:title',
			mt: 'film/:title',
			mu: 'film/:title',
			mw: 'movie/:title',
			mx: 'pelicula/:title',
			my: 'movie/:title',
			mz: 'filme/:title',
			ne: 'film/:title',
			ng: 'movie/:title',
			ni: 'pelicula/:title',
			nl: 'movie/:title',
			no: 'movie/:title',
			nz: 'movie/:title',
			om: 'movie/:title',
			pa: 'pelicula/:title',
			pe: 'pelicula/:title',
			pf: 'film/:title',
			pg: 'movie/:title',
			ph: 'movie/:title',
			pk: 'movie/:title',
			pl: 'film/:title',
			ps: 'movie/:title',
			pt: 'filme/:title',
			py: 'pelicula/:title',
			qa: 'movie/:title',
			ro: 'film/:title',
			rs: 'film/:title',
			ru: 'фильм/:title',
			sa: 'movie/:title',
			sc: 'film/:title',
			se: 'film/:title',
			sg: 'movie/:title',
			si: 'film/:title',
			sk: 'film/:title',
			sm: 'film/:title',
			sn: 'film/:title',
			sv: 'pelicula/:title',
			tc: 'movie/:title',
			td: 'movie/:title',
			th: 'movie/:title',
			tn: 'movie/:title',
			tr: 'film/:title',
			tt: 'movie/:title',
			tw: '電影/:title',
			tz: 'filamu/:title',
			ua: 'фільм/:title',
			ug: 'movie/:title',
			uk: 'movie/:title',
			us: 'movie/:title',
			uy: 'pelicula/:title',
			va: 'film/:title',
			ve: 'pelicula/:title',
			xk: 'filmi/:title',
			ye: 'movie/:title',
			za: 'movie/:title',
			zm: 'movie/:title',
			zw: 'movie/:title',
		},
		redirect: {
			dk: 'film/:title',
			id: 'film/:title',
			lt: 'filmas/:title',
			lv: 'filma/:title',
			my: 'filem/:title',
			no: 'film/:title',
			se: 'film/:title',
			th: 'ภาพยนต์/:title',
		},
		meta: {
			contexts: ['title'],
			tab: 'detail',
			pageType: PageType.TITLE_DETAIL,
		},
	},
	'app.detail.show': {
		component: TitleDetail,
		urls: {
			ad: 'serie/:title',
			ae: 'tv-show/:title',
			ag: 'tv-show/:title',
			al: 'serial-televiziv/:title',
			ao: 'serie/:title',
			ar: 'serie/:title',
			at: 'Serie/:title',
			au: 'tv-show/:title',
			az: 'televiziya-şousu/:title',
			ba: 'tv-serija/:title',
			bb: 'tv-show/:title',
			be: 'serie/:title',
			bf: 'serie/:title',
			bg: 'сериал/:title',
			bh: 'tv-show/:title',
			bm: 'tv-show/:title',
			bo: 'serie/:title',
			br: 'serie/:title',
			bs: 'tv-show/:title',
			by: 'серыял/:title',
			bz: 'tv-series/:title',
			ca: 'tv-show/:title',
			cd: 'serie/:title',
			ch: 'Serie/:title',
			ci: 'serie/:title',
			cl: 'serie/:title',
			cm: 'tv-series/:title',
			co: 'serie/:title',
			cr: 'serie/:title',
			cu: 'serie/:title',
			cv: 'serie/:title',
			cy: 'τηλεοπτική-σειρά/:title',
			cz: 'serial/:title',
			de: 'Serie/:title',
			dk: 'tv-series/:title',
			do: 'serie/:title',
			dz: 'tv-show/:title',
			ec: 'serie/:title',
			ee: 'tv-saated/:title',
			eg: 'tv-show/:title',
			es: 'serie/:title',
			fi: 'sarja/:title',
			fj: 'tv-show/:title',
			fr: 'serie/:title',
			gf: 'serie/:title',
			gg: 'tv-show/:title',
			gh: 'tv-show/:title',
			gi: 'tv-show/:title',
			gq: 'serie/:title',
			gr: 'τηλεοπτική-σειρά/:title',
			gt: 'serie/:title',
			gy: 'tv-series/:title',
			hk: '節目/:title',
			hn: 'serie/:title',
			hr: 'serija/:title',
			hu: 'tv-sorozat/:title',
			id: 'tv-show/:title',
			ie: 'tv-series/:title',
			il: 'tv-show/:title',
			in: 'tv-show/:title',
			iq: 'tv-show/:title',
			is: 'þáttur/:title',
			it: 'serie-tv/:title',
			jm: 'tv-show/:title',
			jo: 'tv-show/:title',
			jp: 'テレビ番組/:title',
			ke: 'tv-show/:title',
			kr: 'TV-프로그램/:title',
			kw: 'tv-show/:title',
			lb: 'tv-show/:title',
			lc: 'tv-show/:title',
			li: 'Serie/:title',
			lt: 'tv-laidos/:title',
			lu: 'serie/:title',
			lv: 'tv-šova/:title',
			ly: 'tv-show/:title',
			ma: 'tv-show/:title',
			mc: 'serie/:title',
			md: 'seriale/:title',
			me: 'tv-program/:title',
			mg: 'serie/:title',
			mk: 'тв-серија/:title',
			ml: 'serie/:title',
			mt: 'serje-televiżiva/:title',
			mu: 'serie/:title',
			mw: 'tv-series/:title',
			mx: 'serie/:title',
			my: 'tv-show/:title',
			mz: 'serie/:title',
			ne: 'serie/:title',
			ng: 'tv-show/:title',
			ni: 'serie/:title',
			nl: 'tv-series/:title',
			no: 'tv-series/:title',
			nz: 'tv-show/:title',
			om: 'tv-show/:title',
			pa: 'serie/:title',
			pe: 'serie/:title',
			pf: 'serie/:title',
			pg: 'tv-series/:title',
			ph: 'tv-show/:title',
			pk: 'tv-show/:title',
			pl: 'serial/:title',
			ps: 'tv-show/:title',
			pt: 'serie/:title',
			py: 'serie/:title',
			qa: 'tv-show/:title',
			ro: 'seriale/:title',
			rs: 'tv-program/:title',
			ru: 'сериал/:title',
			sa: 'tv-show/:title',
			sc: 'serie/:title',
			se: 'tv-serie/:title',
			sg: 'tv-show/:title',
			si: 'serija/:title',
			sk: 'seriál/:title',
			sm: 'serie-tv/:title',
			sn: 'serie/:title',
			sv: 'serie/:title',
			tc: 'tv-show/:title',
			td: 'tv-show/:title',
			th: 'tv-show/:title',
			tn: 'tv-show/:title',
			tr: 'tv-sovu/:title',
			tt: 'tv-show/:title',
			tw: '節目/:title',
			tz: 'onyesho-la-tv/:title',
			ua: 'телеcеріал/:title',
			ug: 'tv-show/:title',
			uk: 'tv-series/:title',
			us: 'tv-show/:title',
			uy: 'serie/:title',
			va: 'serie-tv/:title',
			ve: 'serie/:title',
			xk: 'serial-televiziv/:title',
			ye: 'tv-show/:title',
			za: 'tv-series/:title',
			zm: 'tv-show/:title',
			zw: 'tv-series/:title',
		},
		redirect: {
			dk: 'tv-serie/:title',
			id: 'acara-tv/:title',
			lt: 'tv-laidos/:title',
			lv: 'tv-šova/:title',
			my: 'rancangan-tv/:title',
			no: 'tv-serien/:title',
			se: 'tv-serie/:title',
			th: 'รายการทีวี/:title',
		},
		meta: {
			contexts: ['title'],
			tab: 'detail',
			pageType: PageType.TITLE_DETAIL,
		},
	},
	'app.detail.show.season': {
		component: TitleDetail,
		urls: {
			ad: 'serie/:title/temporada-:season',
			ae: 'tv-show/:title/season-:season',
			ag: 'tv-show/:title/season-:season',
			al: 'serial-televiziv/:title/sezoni-:season',
			ao: 'serie/:title/temporada-:season',
			ar: 'serie/:title/temporada-:season',
			at: 'Serie/:title/Staffel-:season',
			au: 'tv-show/:title/season-:season',
			az: 'televiziya-şousu/:title/mövsüm-:season',
			ba: 'tv-serija/:title/sezona-:season',
			bb: 'tv-show/:title/season-:season',
			be: 'serie/:title/saison-:season',
			bf: 'serie/:title/saison-:season',
			bg: 'сериал/:title/сезон-:season',
			bh: 'tv-show/:title/season-:season',
			bm: 'tv-show/:title/season-:season',
			bo: 'serie/:title/temporada-:season',
			br: 'serie/:title/temporada-:season',
			bs: 'tv-show/:title/season-:season',
			by: 'серыял/:title/сезон-:season',
			bz: 'tv-series/:title/season-:season',
			ca: 'tv-show/:title/season-:season',
			cd: 'serie/:title/saison-:season',
			ch: 'Serie/:title/Staffel-:season',
			ci: 'serie/:title/saison-:season',
			cl: 'serie/:title/temporada-:season',
			cm: 'tv-series/:title/season-:season',
			co: 'serie/:title/temporada-:season',
			cr: 'serie/:title/temporada-:season',
			cu: 'serie/:title/temporada-:season',
			cv: 'serie/:title/temporada-:season',
			cy: 'τηλεοπτική-σειρά/:title/σεζόν-:season',
			cz: 'serial/:title/rada-:season',
			de: 'Serie/:title/Staffel-:season',
			dk: 'tv-series/:title/season-:season',
			do: 'serie/:title/temporada-:season',
			dz: 'tv-show/:title/season-:season',
			ec: 'serie/:title/temporada-:season',
			ee: 'tv-saated/:title/hooaeg-:season',
			eg: 'tv-show/:title/season-:season',
			es: 'serie/:title/temporada-:season',
			fi: 'sarja/:title/kausi-:season',
			fj: 'tv-show/:title/season-:season',
			fr: 'serie/:title/saison-:season',
			gf: 'serie/:title/saison-:season',
			gg: 'tv-show/:title/season-:season',
			gh: 'tv-show/:title/season-:season',
			gi: 'tv-show/:title/season-:season',
			gq: 'serie/:title/temporada-:season',
			gr: 'τηλεοπτική-σειρά/:title/σεζόν-:season',
			gt: 'serie/:title/temporada-:season',
			gy: 'tv-series/:title/season-:season',
			hk: '節目/:title/季-:season',
			hn: 'serie/:title/temporada-:season',
			hr: 'serija/:title/sezona-:season',
			hu: 'tv-sorozat/:title/evad-:season',
			id: 'tv-show/:title/season-:season',
			ie: 'tv-series/:title/season-:season',
			il: 'tv-show/:title/season-:season',
			in: 'tv-show/:title/season-:season',
			iq: 'tv-show/:title/season-:season',
			is: 'þáttur/:title/þáttaröð-:season',
			it: 'serie-tv/:title/stagione-:season',
			jm: 'tv-show/:title/season-:season',
			jo: 'tv-show/:title/season-:season',
			jp: 'テレビ番組/:title/シーズン-:season',
			ke: 'tv-show/:title/season-:season',
			kr: 'TV-프로그램/:title/시즌-:season',
			kw: 'tv-show/:title/season-:season',
			lb: 'tv-show/:title/season-:season',
			lc: 'tv-show/:title/season-:season',
			li: 'Serie/:title/Staffel-:season',
			lt: 'tv-laidos/:title/sezonas-:season',
			lu: 'serie/:title/saison-:season',
			lv: 'tv-šova/:title/sezonu-:season',
			ly: 'tv-show/:title/season-:season',
			ma: 'tv-show/:title/season-:season',
			mc: 'serie/:title/saison-:season',
			md: 'seriale/:title/sezon-:season',
			me: 'tv-program/:title/sezona-:season',
			mg: 'serie/:title/saison-:season',
			mk: 'тв-серија/:title/сезона-:season',
			ml: 'serie/:title/saison-:season',
			mt: 'serje-televiżiva/:title/staġun-:season',
			mu: 'serie/:title/saison-:season',
			mw: 'tv-series/:title/season-:season',
			mx: 'serie/:title/temporada-:season',
			my: 'tv-show/:title/season-:season',
			mz: 'serie/:title/temporada-:season',
			ne: 'serie/:title/saison-:season',
			ng: 'tv-show/:title/season-:season',
			ni: 'serie/:title/temporada-:season',
			nl: 'tv-series/:title/season-:season',
			no: 'tv-series/:title/season-:season',
			nz: 'tv-show/:title/season-:season',
			om: 'tv-show/:title/season-:season',
			pa: 'serie/:title/temporada-:season',
			pe: 'serie/:title/temporada-:season',
			pf: 'serie/:title/saison-:season',
			pg: 'tv-series/:title/season-:season',
			ph: 'tv-show/:title/season-:season',
			pk: 'tv-show/:title/season-:season',
			pl: 'serial/:title/sezon-:season',
			ps: 'tv-show/:title/season-:season',
			pt: 'serie/:title/temporada-:season',
			py: 'serie/:title/temporada-:season',
			qa: 'tv-show/:title/season-:season',
			ro: 'seriale/:title/sezon-:season',
			rs: 'tv-program/:title/sezona-:season',
			ru: 'сериал/:title/сезон-:season',
			sa: 'tv-show/:title/season-:season',
			sc: 'serie/:title/saison-:season',
			se: 'tv-serie/:title/säsong-:season',
			sg: 'tv-show/:title/season-:season',
			si: 'serija/:title/sezona-:season',
			sk: 'seriál/:title/sezóna-:season',
			sm: 'serie-tv/:title/stagione-:season',
			sn: 'serie/:title/saison-:season',
			sv: 'serie/:title/temporada-:season',
			tc: 'tv-show/:title/season-:season',
			td: 'tv-show/:title/season-:season',
			th: 'tv-show/:title/season-:season',
			tn: 'tv-show/:title/season-:season',
			tr: 'tv-sovu/:title/sezon-:season',
			tt: 'tv-show/:title/season-:season',
			tw: '節目/:title/季-:season',
			tz: 'onyesho-la-tv/:title/msimu-:season',
			ua: 'телеcеріал/:title/сезон-:season',
			ug: 'tv-show/:title/season-:season',
			uk: 'tv-series/:title/season-:season',
			us: 'tv-show/:title/season-:season',
			uy: 'serie/:title/temporada-:season',
			va: 'serie-tv/:title/stagione-:season',
			ve: 'serie/:title/temporada-:season',
			xk: 'serial-televiziv/:title/sezoni-:season',
			ye: 'tv-show/:title/season-:season',
			za: 'tv-series/:title/season-:season',
			zm: 'tv-show/:title/season-:season',
			zw: 'tv-series/:title/season-:season',
		},
		redirect: {
			dk: 'tv-serie/:title/sæson-:season',
			id: 'acara-tv/:title/musim-:season',
			lt: 'tv-laidos/:title/sezonas-:season',
			lv: 'tv-šova/:title/sezonu-:season',
			my: 'rancangan-tv/:title/siri-:season',
			no: 'tv-serien/:title/sesonger-:season',
			se: 'tv-serie/:title/säsong-:season',
			th: 'รายการทีวี/:title/ซีซั่น-:season',
		},
		meta: {
			contexts: ['title'],
			tab: 'detail',
			pageType: PageType.TITLE_DETAIL,
		},
	},

	// SEARCH
	'app.search.list': {
		component: Search,
		urls: {
			ad: 'cerca',
			ae: 'search',
			ag: 'search',
			al: 'kërko',
			az: 'axtarış',
			ao: 'busca',
			ar: 'buscar',
			at: 'Suche',
			au: 'search',
			ba: 'traži',
			bb: 'search',
			be: 'recherche',
			bf: 'recherche',
			bg: 'search',
			bh: 'search',
			bm: 'search',
			bo: 'buscar',
			br: 'busca',
			bs: 'search',
			by: 'пошук',
			bz: 'search',
			ca: 'search',
			cd: 'recherche',
			ch: 'Suche',
			ci: 'recherche',
			cl: 'buscar',
			cm: 'search',
			co: 'buscar',
			cr: 'buscar',
			cu: 'buscar',
			cv: 'busca',
			cy: 'search',
			cz: 'vyhledání',
			de: 'Suche',
			dk: 'search',
			do: 'buscar',
			dz: 'search',
			ec: 'buscar',
			ee: 'otsing',
			eg: 'search',
			es: 'buscar',
			fi: 'etsi',
			fj: 'search',
			fr: 'recherche',
			gf: 'recherche',
			gg: 'search',
			gh: 'search',
			gi: 'search',
			gq: 'buscar',
			gr: 'search',
			gt: 'buscar',
			gy: 'search',
			hk: 'search',
			hn: 'buscar',
			hr: 'pretraživanje',
			hu: 'search',
			id: 'search',
			ie: 'search',
			il: 'search',
			in: 'search',
			iq: 'search',
			is: 'leita',
			it: 'cerca',
			jm: 'search',
			jo: 'search',
			jp: '検索',
			ke: 'search',
			kr: '검색',
			kw: 'search',
			lb: 'search',
			lc: 'search',
			li: 'Suche',
			lt: 'search',
			lu: 'recherche',
			lv: 'search',
			ly: 'search',
			ma: 'search',
			mc: 'recherche',
			md: 'search',
			me: 'pretraga',
			mg: 'recherche',
			mk: 'пребарување',
			ml: 'recherche',
			mt: 'fittex',
			mu: 'recherche',
			mw: 'search',
			mx: 'buscar',
			my: 'search',
			mz: 'busca',
			ne: 'recherche',
			ng: 'search',
			ni: 'buscar',
			nl: 'search',
			no: 'search',
			nz: 'search',
			om: 'search',
			pa: 'buscar',
			pe: 'buscar',
			pf: 'recherche',
			pg: 'search',
			ph: 'search',
			pk: 'search',
			pl: 'search',
			ps: 'search',
			pt: 'busca',
			py: 'buscar',
			qa: 'search',
			ro: 'search',
			rs: 'pretraga',
			ru: 'поиск',
			sa: 'search',
			sc: 'recherche',
			se: 'search',
			sg: 'search',
			sk: 'vyhľadávať',
			si: 'iskanje',
			sm: 'cerca',
			sn: 'recherche',
			sv: 'buscar',
			tc: 'search',
			td: 'search',
			th: 'search',
			tn: 'search',
			tr: 'arama',
			tt: 'search',
			tw: 'search',
			tz: 'tafuta',
			ua: 'пошук',
			ug: 'search',
			uk: 'search',
			us: 'search',
			uy: 'buscar',
			va: 'cerca',
			ve: 'buscar',
			xk: 'kërko',
			ye: 'search',
			za: 'search',
			zm: 'search',
			zw: 'search',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.SEARCH,
			contexts: ['filter'],
			tab: 'search',
			pageType: PageType.SEARCH,
		},
	},

	// Lists
	'app.lists.my-lists': {
		component: Watchlist,
		urls: {
			ad: 'lists/my-lists',
			ae: 'lists/my-lists',
			ag: 'lists/my-lists',
			al: 'lists/my-lists',
			az: 'lists/my-lists',
			ao: 'lists/my-lists',
			ar: 'lists/my-lists',
			at: 'lists/my-lists',
			au: 'lists/my-lists',
			ba: 'lists/my-lists',
			bb: 'lists/my-lists',
			be: 'lists/my-lists',
			bf: 'lists/my-lists',
			bg: 'lists/my-lists',
			bh: 'lists/my-lists',
			bm: 'lists/my-lists',
			bo: 'lists/my-lists',
			br: 'lists/my-lists',
			bs: 'lists/my-lists',
			by: 'lists/my-lists',
			bz: 'lists/my-lists',
			ca: 'lists/my-lists',
			cd: 'lists/my-lists',
			ch: 'lists/my-lists',
			ci: 'lists/my-lists',
			cl: 'lists/my-lists',
			cm: 'lists/my-lists',
			co: 'lists/my-lists',
			cr: 'lists/my-lists',
			cu: 'lists/my-lists',
			cv: 'lists/my-lists',
			cy: 'lists/my-lists',
			cz: 'lists/my-lists',
			de: 'lists/my-lists',
			dk: 'lists/my-lists',
			do: 'lists/my-lists',
			dz: 'lists/my-lists',
			ec: 'lists/my-lists',
			ee: 'lists/my-lists',
			eg: 'lists/my-lists',
			es: 'lists/my-lists',
			fi: 'lists/my-lists',
			fj: 'lists/my-lists',
			fr: 'lists/my-lists',
			gf: 'lists/my-lists',
			gg: 'lists/my-lists',
			gh: 'lists/my-lists',
			gi: 'lists/my-lists',
			gq: 'lists/my-lists',
			gr: 'lists/my-lists',
			gt: 'lists/my-lists',
			gy: 'lists/my-lists',
			hk: 'lists/my-lists',
			hn: 'lists/my-lists',
			hr: 'lists/my-lists',
			hu: 'lists/my-lists',
			id: 'lists/my-lists',
			ie: 'lists/my-lists',
			il: 'lists/my-lists',
			in: 'lists/my-lists',
			iq: 'lists/my-lists',
			is: 'lists/my-lists',
			it: 'lists/my-lists',
			jm: 'lists/my-lists',
			jo: 'lists/my-lists',
			jp: 'lists/my-lists',
			ke: 'lists/my-lists',
			kr: 'lists/my-lists',
			kw: 'lists/my-lists',
			lb: 'lists/my-lists',
			lc: 'lists/my-lists',
			li: 'lists/my-lists',
			lt: 'lists/my-lists',
			lu: 'lists/my-lists',
			lv: 'lists/my-lists',
			ly: 'lists/my-lists',
			ma: 'lists/my-lists',
			mc: 'lists/my-lists',
			md: 'lists/my-lists',
			me: 'lists/my-lists',
			mg: 'lists/my-lists',
			mk: 'lists/my-lists',
			ml: 'lists/my-lists',
			mt: 'lists/my-lists',
			mu: 'lists/my-lists',
			mw: 'lists/my-lists',
			mx: 'lists/my-lists',
			my: 'lists/my-lists',
			mz: 'lists/my-lists',
			ne: 'lists/my-lists',
			ng: 'lists/my-lists',
			ni: 'lists/my-lists',
			nl: 'lists/my-lists',
			no: 'lists/my-lists',
			nz: 'lists/my-lists',
			om: 'lists/my-lists',
			pa: 'lists/my-lists',
			pe: 'lists/my-lists',
			pf: 'lists/my-lists',
			pg: 'lists/my-lists',
			ph: 'lists/my-lists',
			pk: 'lists/my-lists',
			pl: 'lists/my-lists',
			ps: 'lists/my-lists',
			pt: 'lists/my-lists',
			py: 'lists/my-lists',
			qa: 'lists/my-lists',
			ro: 'lists/my-lists',
			rs: 'lists/my-lists',
			ru: 'lists/my-lists',
			sa: 'lists/my-lists',
			sc: 'lists/my-lists',
			se: 'lists/my-lists',
			sg: 'lists/my-lists',
			sk: 'lists/my-lists',
			si: 'lists/my-lists',
			sm: 'lists/my-lists',
			sn: 'lists/my-lists',
			sv: 'lists/my-lists',
			tc: 'lists/my-lists',
			td: 'lists/my-lists',
			th: 'lists/my-lists',
			tn: 'lists/my-lists',
			tr: 'lists/my-lists',
			tt: 'lists/my-lists',
			tw: 'lists/my-lists',
			tz: 'lists/my-lists',
			ua: 'lists/my-lists',
			ug: 'lists/my-lists',
			uk: 'lists/my-lists',
			us: 'lists/my-lists',
			uy: 'lists/my-lists',
			va: 'lists/my-lists',
			ve: 'lists/my-lists',
			xk: 'lists/my-lists',
			ye: 'lists/my-lists',
			za: 'lists/my-lists',
			zm: 'lists/my-lists',
			zw: 'lists/my-lists',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.MY_LISTS,
			tab: 'lists',
		},
	},

	'app.lists.tv-show-tracking': {
		component: Watchlist,
		urls: {
			ad: 'lists/tv-show-tracking',
			ae: 'lists/tv-show-tracking',
			ag: 'lists/tv-show-tracking',
			al: 'lists/tv-show-tracking',
			az: 'lists/tv-show-tracking',
			ao: 'lists/tv-show-tracking',
			ar: 'lists/tv-show-tracking',
			at: 'lists/tv-show-tracking',
			au: 'lists/tv-show-tracking',
			ba: 'lists/tv-show-tracking',
			bb: 'lists/tv-show-tracking',
			be: 'lists/tv-show-tracking',
			bf: 'lists/tv-show-tracking',
			bg: 'lists/tv-show-tracking',
			bh: 'lists/tv-show-tracking',
			bm: 'lists/tv-show-tracking',
			bo: 'lists/tv-show-tracking',
			br: 'lists/tv-show-tracking',
			bs: 'lists/tv-show-tracking',
			by: 'lists/tv-show-tracking',
			bz: 'lists/tv-show-tracking',
			ca: 'lists/tv-show-tracking',
			cd: 'lists/tv-show-tracking',
			ch: 'lists/tv-show-tracking',
			ci: 'lists/tv-show-tracking',
			cl: 'lists/tv-show-tracking',
			cm: 'lists/tv-show-tracking',
			co: 'lists/tv-show-tracking',
			cr: 'lists/tv-show-tracking',
			cu: 'lists/tv-show-tracking',
			cv: 'lists/tv-show-tracking',
			cy: 'lists/tv-show-tracking',
			cz: 'lists/tv-show-tracking',
			de: 'lists/tv-show-tracking',
			dk: 'lists/tv-show-tracking',
			do: 'lists/tv-show-tracking',
			dz: 'lists/tv-show-tracking',
			ec: 'lists/tv-show-tracking',
			ee: 'lists/tv-show-tracking',
			eg: 'lists/tv-show-tracking',
			es: 'lists/tv-show-tracking',
			fi: 'lists/tv-show-tracking',
			fj: 'lists/tv-show-tracking',
			fr: 'lists/tv-show-tracking',
			gf: 'lists/tv-show-tracking',
			gg: 'lists/tv-show-tracking',
			gh: 'lists/tv-show-tracking',
			gi: 'lists/tv-show-tracking',
			gq: 'lists/tv-show-tracking',
			gr: 'lists/tv-show-tracking',
			gt: 'lists/tv-show-tracking',
			gy: 'lists/tv-show-tracking',
			hk: 'lists/tv-show-tracking',
			hn: 'lists/tv-show-tracking',
			hr: 'lists/tv-show-tracking',
			hu: 'lists/tv-show-tracking',
			id: 'lists/tv-show-tracking',
			ie: 'lists/tv-show-tracking',
			il: 'lists/tv-show-tracking',
			in: 'lists/tv-show-tracking',
			iq: 'lists/tv-show-tracking',
			is: 'lists/tv-show-tracking',
			it: 'lists/tv-show-tracking',
			jm: 'lists/tv-show-tracking',
			jo: 'lists/tv-show-tracking',
			jp: 'lists/tv-show-tracking',
			ke: 'lists/tv-show-tracking',
			kr: 'lists/tv-show-tracking',
			kw: 'lists/tv-show-tracking',
			lb: 'lists/tv-show-tracking',
			lc: 'lists/tv-show-tracking',
			li: 'lists/tv-show-tracking',
			lt: 'lists/tv-show-tracking',
			lu: 'lists/tv-show-tracking',
			lv: 'lists/tv-show-tracking',
			ly: 'lists/tv-show-tracking',
			ma: 'lists/tv-show-tracking',
			mc: 'lists/tv-show-tracking',
			md: 'lists/tv-show-tracking',
			me: 'lists/tv-show-tracking',
			mg: 'lists/tv-show-tracking',
			mk: 'lists/tv-show-tracking',
			ml: 'lists/tv-show-tracking',
			mt: 'lists/tv-show-tracking',
			mu: 'lists/tv-show-tracking',
			mw: 'lists/tv-show-tracking',
			mx: 'lists/tv-show-tracking',
			my: 'lists/tv-show-tracking',
			mz: 'lists/tv-show-tracking',
			ne: 'lists/tv-show-tracking',
			ng: 'lists/tv-show-tracking',
			ni: 'lists/tv-show-tracking',
			nl: 'lists/tv-show-tracking',
			no: 'lists/tv-show-tracking',
			nz: 'lists/tv-show-tracking',
			om: 'lists/tv-show-tracking',
			pa: 'lists/tv-show-tracking',
			pe: 'lists/tv-show-tracking',
			pf: 'lists/tv-show-tracking',
			pg: 'lists/tv-show-tracking',
			ph: 'lists/tv-show-tracking',
			pk: 'lists/tv-show-tracking',
			pl: 'lists/tv-show-tracking',
			ps: 'lists/tv-show-tracking',
			pt: 'lists/tv-show-tracking',
			py: 'lists/tv-show-tracking',
			qa: 'lists/tv-show-tracking',
			ro: 'lists/tv-show-tracking',
			rs: 'lists/tv-show-tracking',
			ru: 'lists/tv-show-tracking',
			sa: 'lists/tv-show-tracking',
			sc: 'lists/tv-show-tracking',
			se: 'lists/tv-show-tracking',
			sg: 'lists/tv-show-tracking',
			sk: 'lists/tv-show-tracking',
			si: 'lists/tv-show-tracking',
			sm: 'lists/tv-show-tracking',
			sn: 'lists/tv-show-tracking',
			sv: 'lists/tv-show-tracking',
			tc: 'lists/tv-show-tracking',
			td: 'lists/tv-show-tracking',
			th: 'lists/tv-show-tracking',
			tn: 'lists/tv-show-tracking',
			tr: 'lists/tv-show-tracking',
			tt: 'lists/tv-show-tracking',
			tw: 'lists/tv-show-tracking',
			tz: 'lists/tv-show-tracking',
			ua: 'lists/tv-show-tracking',
			ug: 'lists/tv-show-tracking',
			uk: 'lists/tv-show-tracking',
			us: 'lists/tv-show-tracking',
			uy: 'lists/tv-show-tracking',
			va: 'lists/tv-show-tracking',
			ve: 'lists/tv-show-tracking',
			xk: 'lists/tv-show-tracking',
			ye: 'lists/tv-show-tracking',
			za: 'lists/tv-show-tracking',
			zm: 'lists/tv-show-tracking',
			zw: 'lists/tv-show-tracking',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.TV_SHOW_TRACKING,
			tab: 'lists',
		},
	},

	'app.lists.public-lists': {
		component: Watchlist,
		urls: {
			ad: 'lists/public-lists',
			ae: 'lists/public-lists',
			ag: 'lists/public-lists',
			al: 'lists/public-lists',
			az: 'lists/public-lists',
			ao: 'lists/public-lists',
			ar: 'lists/public-lists',
			at: 'lists/public-lists',
			au: 'lists/public-lists',
			ba: 'lists/public-lists',
			bb: 'lists/public-lists',
			be: 'lists/public-lists',
			bf: 'lists/public-lists',
			bg: 'lists/public-lists',
			bh: 'lists/public-lists',
			bm: 'lists/public-lists',
			bo: 'lists/public-lists',
			br: 'lists/public-lists',
			bs: 'lists/public-lists',
			by: 'lists/public-lists',
			bz: 'lists/public-lists',
			ca: 'lists/public-lists',
			cd: 'lists/public-lists',
			ch: 'lists/public-lists',
			ci: 'lists/public-lists',
			cl: 'lists/public-lists',
			cm: 'lists/public-lists',
			co: 'lists/public-lists',
			cr: 'lists/public-lists',
			cu: 'lists/public-lists',
			cv: 'lists/public-lists',
			cy: 'lists/public-lists',
			cz: 'lists/public-lists',
			de: 'lists/public-lists',
			dk: 'lists/public-lists',
			do: 'lists/public-lists',
			dz: 'lists/public-lists',
			ec: 'lists/public-lists',
			ee: 'lists/public-lists',
			eg: 'lists/public-lists',
			es: 'lists/public-lists',
			fi: 'lists/public-lists',
			fj: 'lists/public-lists',
			fr: 'lists/public-lists',
			gf: 'lists/public-lists',
			gg: 'lists/public-lists',
			gh: 'lists/public-lists',
			gi: 'lists/public-lists',
			gq: 'lists/public-lists',
			gr: 'lists/public-lists',
			gt: 'lists/public-lists',
			gy: 'lists/public-lists',
			hk: 'lists/public-lists',
			hn: 'lists/public-lists',
			hr: 'lists/public-lists',
			hu: 'lists/public-lists',
			id: 'lists/public-lists',
			ie: 'lists/public-lists',
			il: 'lists/public-lists',
			in: 'lists/public-lists',
			iq: 'lists/public-lists',
			is: 'lists/public-lists',
			it: 'lists/public-lists',
			jm: 'lists/public-lists',
			jo: 'lists/public-lists',
			jp: 'lists/public-lists',
			ke: 'lists/public-lists',
			kr: 'lists/public-lists',
			kw: 'lists/public-lists',
			lb: 'lists/public-lists',
			lc: 'lists/public-lists',
			li: 'lists/public-lists',
			lt: 'lists/public-lists',
			lu: 'lists/public-lists',
			lv: 'lists/public-lists',
			ly: 'lists/public-lists',
			ma: 'lists/public-lists',
			mc: 'lists/public-lists',
			md: 'lists/public-lists',
			me: 'lists/public-lists',
			mg: 'lists/public-lists',
			mk: 'lists/public-lists',
			ml: 'lists/public-lists',
			mt: 'lists/public-lists',
			mu: 'lists/public-lists',
			mw: 'lists/public-lists',
			mx: 'lists/public-lists',
			my: 'lists/public-lists',
			mz: 'lists/public-lists',
			ne: 'lists/public-lists',
			ng: 'lists/public-lists',
			ni: 'lists/public-lists',
			nl: 'lists/public-lists',
			no: 'lists/public-lists',
			nz: 'lists/public-lists',
			om: 'lists/public-lists',
			pa: 'lists/public-lists',
			pe: 'lists/public-lists',
			pf: 'lists/public-lists',
			pg: 'lists/public-lists',
			ph: 'lists/public-lists',
			pk: 'lists/public-lists',
			pl: 'lists/public-lists',
			ps: 'lists/public-lists',
			pt: 'lists/public-lists',
			py: 'lists/public-lists',
			qa: 'lists/public-lists',
			ro: 'lists/public-lists',
			rs: 'lists/public-lists',
			ru: 'lists/public-lists',
			sa: 'lists/public-lists',
			sc: 'lists/public-lists',
			se: 'lists/public-lists',
			sg: 'lists/public-lists',
			sk: 'lists/public-lists',
			si: 'lists/public-lists',
			sm: 'lists/public-lists',
			sn: 'lists/public-lists',
			sv: 'lists/public-lists',
			tc: 'lists/public-lists',
			td: 'lists/public-lists',
			th: 'lists/public-lists',
			tn: 'lists/public-lists',
			tr: 'lists/public-lists',
			tt: 'lists/public-lists',
			tw: 'lists/public-lists',
			tz: 'lists/public-lists',
			ua: 'lists/public-lists',
			ug: 'lists/public-lists',
			uk: 'lists/public-lists',
			us: 'lists/public-lists',
			uy: 'lists/public-lists',
			va: 'lists/public-lists',
			ve: 'lists/public-lists',
			xk: 'lists/public-lists',
			ye: 'lists/public-lists',
			za: 'lists/public-lists',
			zm: 'lists/public-lists',
			zw: 'lists/public-lists',
		},
		meta: {
			hasFilterBar: true,
			collectionType: CollectionType.PUBLIC_LISTS,
			tab: 'lists',
		},
	},

	// TV LOGIN
	'app.tv.locale': {
		component: TvRegistration,
		urls: {
			ad: 'tv',
			ae: 'tv',
			ag: 'tv',
			al: 'tv',
			az: 'tv',
			ao: 'tv',
			ar: 'tv',
			at: 'tv',
			au: 'tv',
			ba: 'tv',
			bb: 'tv',
			be: 'tv',
			bf: 'tv',
			bg: 'tv',
			bh: 'tv',
			bm: 'tv',
			bo: 'tv',
			br: 'tv',
			bs: 'tv',
			by: 'tv',
			bz: 'tv',
			ca: 'tv',
			cd: 'tv',
			ch: 'tv',
			ci: 'tv',
			cl: 'tv',
			cm: 'tv',
			co: 'tv',
			cr: 'tv',
			cu: 'tv',
			cv: 'tv',
			cy: 'tv',
			cz: 'tv',
			de: 'tv',
			dk: 'tv',
			do: 'tv',
			dz: 'tv',
			ec: 'tv',
			ee: 'tv',
			eg: 'tv',
			es: 'tv',
			fi: 'tv',
			fj: 'tv',
			fr: 'tv',
			gf: 'tv',
			gg: 'tv',
			gh: 'tv',
			gi: 'tv',
			gq: 'tv',
			gr: 'tv',
			gt: 'tv',
			gy: 'tv',
			hk: 'tv',
			hn: 'tv',
			hr: 'tv',
			hu: 'tv',
			id: 'tv',
			ie: 'tv',
			il: 'tv',
			in: 'tv',
			iq: 'tv',
			is: 'tv',
			it: 'tv',
			jm: 'tv',
			jo: 'tv',
			jp: 'tv',
			ke: 'tv',
			kr: 'tv',
			kw: 'tv',
			lb: 'tv',
			lc: 'tv',
			li: 'tv',
			lt: 'tv',
			lu: 'tv',
			lv: 'tv',
			ly: 'tv',
			ma: 'tv',
			mc: 'tv',
			md: 'tv',
			me: 'tv',
			mg: 'tv',
			mk: 'тв',
			ml: 'tv',
			mt: 'tv',
			mu: 'tv',
			mw: 'tv',
			mx: 'tv',
			my: 'tv',
			mz: 'tv',
			ne: 'tv',
			ng: 'tv',
			ni: 'tv',
			nl: 'tv',
			no: 'tv',
			nz: 'tv',
			om: 'tv',
			pa: 'tv',
			pe: 'tv',
			pf: 'tv',
			pg: 'tv',
			ph: 'tv',
			pk: 'tv',
			pl: 'tv',
			ps: 'tv',
			pt: 'tv',
			py: 'tv',
			qa: 'tv',
			ro: 'tv',
			rs: 'tv',
			ru: 'tv',
			sa: 'tv',
			sc: 'tv',
			se: 'tv',
			sg: 'tv',
			sk: 'tv',
			si: 'tv',
			sm: 'tv',
			sn: 'tv',
			sv: 'tv',
			tc: 'tv',
			td: 'tv',
			th: 'tv',
			tn: 'tv',
			tr: 'tv',
			tt: 'tv',
			tw: 'tv',
			tz: 'tv',
			ua: 'tv',
			ug: 'tv',
			uk: 'tv',
			us: 'tv',
			uy: 'tv',
			va: 'tv',
			ve: 'tv',
			xk: 'tv',
			ye: 'tv',
			za: 'tv',
			zm: 'tv',
			zw: 'tv',
		},
		meta: {
			hasFilterBar: false,
			collectionType: CollectionType.GLOBAL,
			contexts: [],
			tab: 'tv',
		},
	},

	// TV LOGIN
	'app.tv.code': {
		component: TvRegistration,
		urls: {
			ad: 'tv/:code',
			ae: 'tv/:code',
			ag: 'tv/:code',
			al: 'tv/:code',
			az: 'tv/:code',
			ao: 'tv/:code',
			ar: 'tv/:code',
			at: 'tv/:code',
			au: 'tv/:code',
			ba: 'tv/:code',
			bb: 'tv/:code',
			be: 'tv/:code',
			bf: 'tv/:code',
			bg: 'tv/:code',
			bh: 'tv/:code',
			bm: 'tv/:code',
			bo: 'tv/:code',
			br: 'tv/:code',
			bs: 'tv/:code',
			by: 'tv/:code',
			bz: 'tv/:code',
			ca: 'tv/:code',
			cd: 'tv/:code',
			ch: 'tv/:code',
			ci: 'tv/:code',
			cl: 'tv/:code',
			cm: 'tv/:code',
			co: 'tv/:code',
			cr: 'tv/:code',
			cu: 'tv/:code',
			cv: 'tv/:code',
			cy: 'tv/:code',
			cz: 'tv/:code',
			de: 'tv/:code',
			dk: 'tv/:code',
			do: 'tv/:code',
			dz: 'tv/:code',
			ec: 'tv/:code',
			ee: 'tv/:code',
			eg: 'tv/:code',
			es: 'tv/:code',
			fi: 'tv/:code',
			fj: 'tv/:code',
			fr: 'tv/:code',
			gf: 'tv/:code',
			gg: 'tv/:code',
			gh: 'tv/:code',
			gi: 'tv/:code',
			gq: 'tv/:code',
			gr: 'tv/:code',
			gt: 'tv/:code',
			gy: 'tv/:code',
			hk: 'tv/:code',
			hn: 'tv/:code',
			hr: 'tv/:code',
			hu: 'tv/:code',
			id: 'tv/:code',
			ie: 'tv/:code',
			il: 'tv/:code',
			in: 'tv/:code',
			iq: 'tv/:code',
			is: 'tv/:code',
			it: 'tv/:code',
			jm: 'tv/:code',
			jo: 'tv/:code',
			jp: 'tv/:code',
			ke: 'tv/:code',
			kr: 'tv/:code',
			kw: 'tv/:code',
			lb: 'tv/:code',
			lc: 'tv/:code',
			li: 'tv/:code',
			lt: 'tv/:code',
			lu: 'tv/:code',
			lv: 'tv/:code',
			ly: 'tv/:code',
			ma: 'tv/:code',
			mc: 'tv/:code',
			md: 'tv/:code',
			me: 'tv/:code',
			mg: 'tv/:code',
			mk: 'tv/:code',
			ml: 'tv/:code',
			mt: 'tv/:code',
			mu: 'tv/:code',
			mw: 'tv/:code',
			mx: 'tv/:code',
			my: 'tv/:code',
			mz: 'tv/:code',
			ne: 'tv/:code',
			ng: 'tv/:code',
			ni: 'tv/:code',
			nl: 'tv/:code',
			no: 'tv/:code',
			nz: 'tv/:code',
			om: 'tv/:code',
			pa: 'tv/:code',
			pe: 'tv/:code',
			pf: 'tv/:code',
			pg: 'tv/:code',
			ph: 'tv/:code',
			pk: 'tv/:code',
			pl: 'tv/:code',
			ps: 'tv/:code',
			pt: 'tv/:code',
			py: 'tv/:code',
			qa: 'tv/:code',
			ro: 'tv/:code',
			rs: 'tv/:code',
			ru: 'tv/:code',
			sa: 'tv/:code',
			sc: 'tv/:code',
			se: 'tv/:code',
			sg: 'tv/:code',
			sk: 'tv/:code',
			si: 'tv/:code',
			sm: 'tv/:code',
			sn: 'tv/:code',
			sv: 'tv/:code',
			tc: 'tv/:code',
			td: 'tv/:code',
			th: 'tv/:code',
			tn: 'tv/:code',
			tr: 'tv/:code',
			tt: 'tv/:code',
			tw: 'tv/:code',
			tz: 'tv/:code',
			ua: 'tv/:code',
			ug: 'tv/:code',
			uk: 'tv/:code',
			us: 'tv/:code',
			uy: 'tv/:code',
			va: 'tv/:code',
			ve: 'tv/:code',
			xk: 'tv/:code',
			ye: 'tv/:code',
			za: 'tv/:code',
			zm: 'tv/:code',
			zw: 'tv/:code',
		},
		meta: {
			hasFilterBar: false,
			collectionType: CollectionType.GLOBAL,
			contexts: [],
			tab: 'tv',
		},
	},

	// STREAMING CHARTS
	'app.streaming-charts': {
		component: StreamingCharts,
		beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext<Vue>, store: Store<any>) => {
			await Promise.all([store.dispatch('language/loadLocales')]);
			next();
		},
		urls: {
			ar: 'streaming-charts',
			au: 'streaming-charts',
			br: 'streaming-charts',
			ca: 'streaming-charts',
			es: 'streaming-charts',
			eg: 'streaming-charts',
			fr: 'streaming-charts',
			de: 'streaming-charts',
			in: 'streaming-charts',
			it: 'streaming-charts',
			mx: 'streaming-charts',
			po: 'streaming-charts',
			pl: 'streaming-charts',
			tr: 'streaming-charts',
			uk: 'streaming-charts',
			us: 'streaming-charts',
		},
		meta: {
			hasFilterBar: false,
			initialRouteState: {},
			contexts: [],
			tab: 'streaming-charts',
			pageType: PageType.STREAMING_CHARTS,
		},
	},

	// LANDING PAGES
	'app.landingpage.talent': {
		component: Talent,
		urls: {
			ad: 'oportunitats',
			ae: 'talent',
			ag: 'talent',
			al: 'talenti',
			az: 'əməkdaşlar',
			ao: 'talent',
			ar: 'talent',
			at: 'talent',
			au: 'talent',
			ba: 'talenat',
			bb: 'talent',
			be: 'talent',
			bf: 'talent',
			bg: 'talent',
			bh: 'talent',
			bm: 'talent',
			bo: 'talent',
			br: 'talent',
			bs: 'talent',
			by: 'таленты',
			bz: 'talent',
			ca: 'talent',
			cd: 'talent',
			ch: 'talent',
			ci: 'talent',
			cl: 'talent',
			cm: 'talent',
			co: 'talent',
			cr: 'talent',
			cu: 'talent',
			cv: 'talent',
			cy: 'talent',
			cz: 'talent',
			de: 'talent',
			dk: 'talent',
			do: 'talent',
			dz: 'talent',
			ec: 'talent',
			ee: 'talent',
			eg: 'talent',
			es: 'talent',
			fi: 'talent',
			fj: 'talent',
			fr: 'talent',
			gf: 'talent',
			gg: 'talent',
			gh: 'talent',
			gi: 'talent',
			gq: 'talent',
			gr: 'talent',
			gt: 'talent',
			gy: 'talent',
			hk: 'talent',
			hn: 'talent',
			hr: 'talent',
			hu: 'talent',
			id: 'talent',
			ie: 'talent',
			il: 'talent',
			in: 'talent',
			iq: 'talent',
			is: 'talent',
			it: 'talent',
			jm: 'talent',
			jo: 'talent',
			jp: 'talent',
			ke: 'talent',
			kr: 'talent',
			kw: 'talent',
			lb: 'talent',
			lc: 'talent',
			li: 'talent',
			lt: 'talent',
			lu: 'talent',
			lv: 'talent',
			ly: 'talent',
			ma: 'talent',
			mc: 'talent',
			md: 'talent',
			me: 'talenat',
			mg: 'talent',
			mk: 'талент',
			ml: 'talent',
			si: 'ustvarjalec',
			mt: 'talent',
			mu: 'talent',
			mw: 'talent',
			mx: 'talent',
			my: 'talent',
			mz: 'talent',
			ne: 'talent',
			ng: 'talent',
			ni: 'talent',
			nl: 'talent',
			no: 'talent',
			nz: 'talent',
			om: 'talent',
			pa: 'talent',
			pe: 'talent',
			pf: 'talent',
			pg: 'talent',
			ph: 'talent',
			pk: 'talent',
			pl: 'talent',
			ps: 'talent',
			pt: 'talent',
			py: 'talent',
			qa: 'talent',
			ro: 'talent',
			rs: 'talenat',
			ru: 'talent',
			sa: 'talent',
			sc: 'talent',
			se: 'talent',
			sg: 'talent',
			sk: 'talent',
			sm: 'talent',
			sn: 'talent',
			sv: 'talent',
			tc: 'talent',
			td: 'talent',
			th: 'talent',
			tn: 'talent',
			tr: 'talent',
			tt: 'talent',
			tw: 'talent',
			tz: 'kipaji',
			ua: 'таланти',
			ug: 'talent',
			uk: 'talent',
			us: 'talent',
			uy: 'talent',
			va: 'talent',
			ve: 'talent',
			xk: 'talenti',
			ye: 'talent',
			za: 'talent',
			zm: 'talent',
			zw: 'talent',
		},
		meta: { tab: 'landingpage', data: 'talent' },
	},
	'app.landingpage.streamgreen': {
		component: StreamGreen,
		urls: {
			ad: 'co2-calculadora-streaming',
			ag: 'co2-streaming-calculator',
			al: 'co2-streaming-calculator',
			az: 'co2-streaming-calculator',
			ao: 'co2-streaming-calculadora',
			ba: 'co2-streaming-calculator',
			bb: 'co2-streaming-calculator',
			bm: 'co2-streaming-calculator',
			br: 'co2-streaming-calculadora',
			bf: 'co2-calculateur-streaming',
			bs: 'co2-streaming-calculator',
			by: 'co2-streaming-калькулятар',
			bz: 'co2-streaming-calculator',
			cd: 'co2-calculateur-streaming',
			ci: 'co2-calculateur-streaming',
			cm: 'co2-streaming-calculator',
			cu: 'co2-calculadora-streaming',
			cv: 'co2-streaming-calculadora',
			cy: 'co2-streaming-αριθμομηχανή',
			de: 'co2-streaming-rechner',
			do: 'co2-calculadora-streaming',
			es: 'co2-calculadora-streaming',
			fj: 'co2-streaming-calculator',
			fr: 'co2-calculateur-streaming',
			gf: 'co2-calculateur-streaming',
			gg: 'co2-streaming-calculator',
			gh: 'co2-streaming-calculator',
			gi: 'co2-streaming-calculator',
			gq: 'co2-calculadora-streaming',
			gy: 'co2-streaming-calculator',
			il: 'co2-streaming-calculator',
			iq: 'co2-streaming-calculator',
			it: 'co2-calcolatore-streaming',
			jm: 'co2-streaming-calculator',
			ke: 'co2-streaming-calculator',
			lb: 'co2-streaming-calculator',
			lc: 'co2-streaming-calculator',
			li: 'co2-streaming-rechner',
			lu: 'co2-calculateur-streaming',
			mc: 'co2-calculateur-streaming',
			me: 'co2-streaming-calculator',
			mg: 'co2-calculateur-streaming',
			mk: 'co2-streaming-calculator',
			ml: 'co2-calculateur-streaming',
			mt: 'co2-streaming-calculator',
			mu: 'co2-calculateur-streaming',
			mw: 'co2-streaming-calculator',
			mx: 'co2-calculadora-streaming',
			mz: 'co2-streaming-calculadora',
			ng: 'co2-streaming-calculator',
			ni: 'co2-calculadora-streaming',
			pa: 'co2-calculadora-streaming',
			pf: 'co2-calculateur-streaming',
			pg: 'co2-streaming-calculator',
			ps: 'co2-streaming-calculator',
			pt: 'co2-streaming-calculadora',
			rs: 'co2-streaming-calculator',
			sc: 'co2-calculateur-streaming',
			si: 'co2-streaming-calculator',
			sm: 'co2-calcolatore-streaming',
			sn: 'co2-calculateur-streaming',
			sv: 'co2-calculadora-streaming',
			tc: 'co2-streaming-calculator',
			td: 'co2-streaming-calculator',
			tt: 'co2-streaming-calculator',
			tz: 'co2-streaming-kikokotoo',
			ua: 'co2-streaming-калькулятор',
			ug: 'co2-streaming-calculator',
			us: 'co2-streaming-calculator',
			uy: 'co2-calculadora-streaming',
			va: 'co2-calcolatore-streaming',
			xk: 'co2-streaming-calculator',
			zm: 'co2-streaming-calculator',
			zw: 'co2-streaming-calculator',
		},
		meta: { tab: 'landingpage', data: 'streamgreen' },
	},
	'app.landingpage.plotrecommender': {
		component: PlotRecommender,
		urls: {
			ad: 'recomendador-de-peliculas-series',
			ag: 'tv-show-movie-generator',
			al: 'plot-recommender-movies-tv-shows',
			az: 'plot-recommender-movies-tv-shows',
			ao: 'recomendacao-filmes-programas',
			au: 'tv-show-movie-generator',
			ba: 'tv-show-movie-generator',
			bb: 'plot-recommender-movies-tv-shows',
			bf: 'intrigue-recommandation-films-series-tv',
			bm: 'plot-recommender-movies-tv-shows',
			br: 'recomendacao-filmes-programas',
			bs: 'plot-recommender-movies-tv-shows',
			by: 'сюжэт-рэкаменд-фільмы-сезон',
			bz: 'plot-recommender-movies-tv-shows',
			ca: 'tv-show-movie-finder',
			cd: 'intrigue-recommandation-films-series-tv',
			ci: 'intrigue-recommandation-films-series-tv',
			cm: 'tv-series-movie-finder',
			cu: 'recomendador-de-peliculas-series',
			cv: 'recomendacao-filmes-programas',
			cy: 'συντάκτης-πλοκής-ταινίες-τηλεοπτικές-σειρές',
			de: 'empfohlene-handlung-filme-und-serien',
			es: 'recomendador-de-peliculas-series',
			fj: 'tv-show-movie-finder',
			fr: 'intrigue-recommandation-films-series-tv',
			gf: 'intrigue-recommandation-films-series-tv',
			gg: 'tv-series-movie-generator',
			gh: 'plot-recommender-movies-tv-shows',
			gi: 'plot-recommender-movies-tv-shows',
			gq: 'recomendador-de-peliculas-series',
			gy: 'tv-show-movie-finder',
			ie: 'tv-series-movie-finder',
			il: 'plot-recommender-movies-tv-shows',
			in: 'tv-show-movie-finder',
			iq: 'tv-show-movie-finder',
			it: 'consigli-trama-film-serie-tv',
			jm: 'plot-recommender-movies-tv-shows',
			ke: 'plot-recommender-movies-tv-shows',
			lb: 'tv-show-movie-finder',
			lc: 'plot-recommender-movies-tv-shows',
			li: 'empfohlene-handlung-filme-und-serien',
			lu: 'intrigue-recommandation-films-series-tv',
			mc: 'intrigue-recommandation-films-series-tv',
			me: 'plot-recommender-movies-tv-shows',
			mg: 'intrigue-recommandation-films-series-tv',
			mk: 'plot-recommender-movies-tv-shows',
			ml: 'intrigue-recommandation-films-series-tv',
			mt: 'plot-recommender-movies-tv-shows',
			mu: 'intrigue-recommandation-films-series-tv',
			mw: 'plot-recommender-movies-tv-shows',
			mz: 'recomendacao-filmes-programas',
			ne: 'intrigue-recommandation-films-series-tv',
			ng: 'plot-recommender-movies-tv-shows',
			ni: 'recomendador-de-peliculas-series',
			nz: 'tv-show-movie-finder',
			pf: 'intrigue-recommandation-films-series-tv',
			pg: 'plot-recommender-movies-tv-shows',
			ps: 'tv-show-movie-finder',
			pt: 'recomendacao-filmes-programas',
			rs: 'plot-recommender-movies-tv-shows',
			sc: 'intrigue-recommandation-films-series-tv',
			si: 'plot-recommender-movies-tv-shows',
			sm: 'consigli-trama-film-serie-tv',
			sn: 'intrigue-recommandation-films-series-tv',
			tc: 'plot-recommender-movies-tv-shows',
			td: 'tv-show-movie-generator',
			tt: 'plot-recommender-movies-tv-shows',
			tz: 'mshauri-wa-njama-filamu-maonyesho-ya-tv',
			ua: 'рекомендувач-сюжету-фільми-телесеріали',
			ug: 'plot-recommender-movies-tv-shows',
			uk: 'tv-series-movie-generator',
			us: 'plot-recommender-movies-tv-shows',
			va: 'consigli-trama-film-serie-tv',
			xk: 'plot-recommender-movies-tv-shows',
			za: 'tv-series-movie-finder',
			zm: 'plot-recommender-movies-tv-shows',
			zw: 'plot-recommender-movies-tv-shows',
		},
		meta: { tab: 'landingpage', data: 'plotrecommender' },
	},
	'app.landingpage.tastyads': {
		component: TastyAds,

		urls: {
			us: 'tasty-ads/:genre',
			de: 'genre/:genre',
		},
		meta: { tab: 'landingpage', data: 'tastyads' },
	},
	'app.landingpage.whatculture': {
		component: WhatCulture,
		urls: {
			au: 'whatculture',
			ca: 'whatculture',
			ie: 'whatculture',
			uk: 'whatculture',
			us: 'whatculture',
		},
		meta: { tab: 'landingpage', collectionType: CollectionType.TITLELIST, hasFilterBar: true },
	},
	'app.landingpage.oscars2023': {
		component: Oscars2023,
		urls: {
			ad: 'oscars-2023',
			ae: 'oscars-2023',
			ag: 'oscars-2023',
			ao: 'oscars-2023',
			ar: 'oscars-2023',
			at: 'oscars-2023',
			au: 'oscars-2023',
			al: 'oscars-2023',
			az: 'oscars-2023',
			ba: 'oscars-2023',
			bb: 'oscars-2023',
			be: 'oscars-2023',
			bf: 'oscars-2023',
			bg: 'oscars-2023',
			bh: 'oscars-2023',
			bm: 'oscars-2023',
			bo: 'oscars-2023',
			br: 'oscars-2023',
			bs: 'oscars-2023',
			by: 'oscars-2023',
			bz: 'oscars-2023',
			ca: 'oscars-2023',
			cd: 'oscars-2023',
			ch: 'oscars-2023',
			ci: 'oscars-2023',
			cl: 'oscars-2023',
			cm: 'oscars-2023',
			co: 'oscars-2023',
			cr: 'oscars-2023',
			cu: 'oscars-2023',
			cv: 'oscars-2023',
			cy: 'oscars-2023',
			cz: 'oscars-2023',
			de: 'oscars-2023',
			dk: 'oscars-2023',
			do: 'oscars-2023',
			dz: 'oscars-2023',
			ec: 'oscars-2023',
			ee: 'oscars-2023',
			eg: 'oscars-2023',
			es: 'oscars-2023',
			fi: 'oscars-2023',
			fj: 'oscars-2023',
			fr: 'oscars-2023',
			gf: 'oscars-2023',
			gg: 'oscars-2023',
			gh: 'oscars-2023',
			gi: 'oscars-2023',
			gq: 'oscars-2023',
			gr: 'oscars-2023',
			gt: 'oscars-2023',
			gy: 'oscars-2023',
			hk: 'oscars-2023',
			hn: 'oscars-2023',
			hr: 'oscars-2023',
			hu: 'oscars-2023',
			id: 'oscars-2023',
			ie: 'oscars-2023',
			il: 'oscars-2023',
			in: 'oscars-2023',
			iq: 'oscars-2023',
			is: 'oscars-2023',
			it: 'oscars-2023',
			jm: 'oscars-2023',
			jo: 'oscars-2023',
			jp: 'oscars-2023',
			ke: 'oscars-2023',
			kr: 'oscars-2023',
			kw: 'oscars-2023',
			lb: 'oscars-2023',
			lc: 'oscars-2023',
			li: 'oscars-2023',
			lt: 'oscars-2023',
			lu: 'oscars-2023',
			lv: 'oscars-2023',
			ly: 'oscars-2023',
			ma: 'oscars-2023',
			mc: 'oscars-2023',
			md: 'oscars-2023',
			me: 'oscars-2023',
			mg: 'oscars-2023',
			mk: 'oscars-2023',
			ml: 'oscars-2023',
			mt: 'oscars-2023',
			mu: 'oscars-2023',
			mw: 'oscars-2023',
			mx: 'oscars-2023',
			my: 'oscars-2023',
			mz: 'oscars-2023',
			ne: 'oscars-2023',
			ng: 'oscars-2023',
			ni: 'oscars-2023',
			nl: 'oscars-2023',
			no: 'oscars-2023',
			nz: 'oscars-2023',
			om: 'oscars-2023',
			pa: 'oscars-2023',
			pe: 'oscars-2023',
			pf: 'oscars-2023',
			pg: 'oscars-2023',
			ph: 'oscars-2023',
			pk: 'oscars-2023',
			pl: 'oscars-2023',
			ps: 'oscars-2023',
			pt: 'oscars-2023',
			py: 'oscars-2023',
			qa: 'oscars-2023',
			ro: 'oscars-2023',
			rs: 'oscars-2023',
			ru: 'oscars-2023',
			sa: 'oscars-2023',
			sc: 'oscars-2023',
			se: 'oscars-2023',
			sg: 'oscars-2023',
			si: 'oscars-2023',
			sk: 'oscars-2023',
			sm: 'oscars-2023',
			sn: 'oscars-2023',
			sv: 'oscars-2023',
			tc: 'oscars-2023',
			td: 'oscars-2023',
			th: 'oscars-2023',
			tn: 'oscars-2023',
			tr: 'oscars-2023',
			tt: 'oscars-2023',
			tw: 'oscars-2023',
			tz: 'oscars-2023',
			ua: 'oscars-2023',
			ug: 'oscars-2023',
			uk: 'oscars-2023',
			us: 'oscars-2023',
			uy: 'oscars-2023',
			va: 'oscars-2023',
			ve: 'oscars-2023',
			xk: 'oscars-2023',
			ye: 'oscars-2023',
			za: 'oscars-2023',
			zm: 'oscars-2023',
			zw: 'oscars-2023',
		},
		meta: { tab: 'landingpage', collectionType: CollectionType.TITLELIST, hasFilterBar: true },
	},
	'app.landingpage.valentine2023': {
		component: Valentine2023,
		urls: {
			ad: 'valentine-2023',
			ae: 'valentine-2023',
			ag: 'valentine-2023',
			ar: 'valentine-2023',
			at: 'valentine-2023',
			au: 'valentine-2023',
			al: 'valentine-2023',
			az: 'valentine-2023',
			ao: 'valentine-2023',
			ba: 'valentine-2023',
			bb: 'valentine-2023',
			bf: 'valentine-2023',
			be: 'valentine-2023',
			bg: 'valentine-2023',
			bh: 'valentine-2023',
			bm: 'valentine-2023',
			bo: 'valentine-2023',
			br: 'valentine-2023',
			bs: 'valentine-2023',
			by: 'valentine-2023',
			bz: 'valentine-2023',
			ca: 'valentine-2023',
			cd: 'valentine-2023',
			ch: 'valentine-2023',
			ci: 'valentine-2023',
			cm: 'valentine-2023',
			cl: 'valentine-2023',
			co: 'valentine-2023',
			cr: 'valentine-2023',
			cu: 'valentine-2023',
			cv: 'valentine-2023',
			cy: 'valentine-2023',
			cz: 'valentine-2023',
			de: 'valentine-2023',
			dk: 'valentine-2023',
			do: 'valentine-2023',
			dz: 'valentine-2023',
			ec: 'valentine-2023',
			ee: 'valentine-2023',
			eg: 'valentine-2023',
			es: 'valentine-2023',
			fi: 'valentine-2023',
			fj: 'valentine-2023',
			fr: 'valentine-2023',
			gf: 'valentine-2023',
			gg: 'valentine-2023',
			gh: 'valentine-2023',
			gi: 'valentine-2023',
			gq: 'valentine-2023',
			gy: 'valentine-2023',
			gr: 'valentine-2023',
			gt: 'valentine-2023',
			hk: 'valentine-2023',
			hn: 'valentine-2023',
			hr: 'valentine-2023',
			hu: 'valentine-2023',
			id: 'valentine-2023',
			ie: 'valentine-2023',
			il: 'valentine-2023',
			in: 'valentine-2023',
			iq: 'valentine-2023',
			is: 'valentine-2023',
			it: 'valentine-2023',
			jm: 'valentine-2023',
			jo: 'valentine-2023',
			jp: 'valentine-2023',
			ke: 'valentine-2023',
			kr: 'valentine-2023',
			kw: 'valentine-2023',
			lb: 'valentine-2023',
			lc: 'valentine-2023',
			li: 'valentine-2023',
			lu: 'valentine-2023',
			lt: 'valentine-2023',
			lv: 'valentine-2023',
			ly: 'valentine-2023',
			ma: 'valentine-2023',
			mc: 'valentine-2023',
			me: 'valentine-2023',
			mg: 'valentine-2023',
			md: 'valentine-2023',
			mk: 'valentine-2023',
			ml: 'valentine-2023',
			mt: 'valentine-2023',
			mu: 'valentine-2023',
			mw: 'valentine-2023',
			mx: 'valentine-2023',
			my: 'valentine-2023',
			mz: 'valentine-2023',
			ne: 'valentine-2023',
			ng: 'valentine-2023',
			ni: 'valentine-2023',
			nl: 'valentine-2023',
			no: 'valentine-2023',
			nz: 'valentine-2023',
			om: 'valentine-2023',
			pa: 'valentine-2023',
			pe: 'valentine-2023',
			pf: 'valentine-2023',
			pg: 'valentine-2023',
			ph: 'valentine-2023',
			pk: 'valentine-2023',
			pl: 'valentine-2023',
			ps: 'valentine-2023',
			pt: 'valentine-2023',
			py: 'valentine-2023',
			qa: 'valentine-2023',
			ro: 'valentine-2023',
			rs: 'valentine-2023',
			ru: 'valentine-2023',
			sa: 'valentine-2023',
			sc: 'valentine-2023',
			se: 'valentine-2023',
			sg: 'valentine-2023',
			si: 'valentine-2023',
			sk: 'valentine-2023',
			sm: 'valentine-2023',
			sn: 'valentine-2023',
			sv: 'valentine-2023',
			tc: 'valentine-2023',
			td: 'valentine-2023',
			th: 'valentine-2023',
			tn: 'valentine-2023',
			tr: 'valentine-2023',
			tt: 'valentine-2023',
			tz: 'valentine-2023',
			ua: 'valentine-2023',
			tw: 'valentine-2023',
			ug: 'valentine-2023',
			uk: 'valentine-2023',
			us: 'valentine-2023',
			uy: 'valentine-2023',
			va: 'valentine-2023',
			ve: 'valentine-2023',
			xk: 'valentine-2023',
			ye: 'valentine-2023',
			za: 'valentine-2023',
			zm: 'valentine-2023',
			zw: 'valentine-2023',
		},
		meta: { tab: 'landingpage', collectionType: CollectionType.TITLELIST, hasFilterBar: true },
	},
	'app.landingpage.collegesmostsuperbowlplayers': {
		component: CollegesMostSuperBowlPlayers,
		urls: {
			ad: 'sports/most-superbowl-players-by-college',
			ae: 'sports/most-superbowl-players-by-college',
			ag: 'sports/most-superbowl-players-by-college',
			ar: 'sports/most-superbowl-players-by-college',
			at: 'sports/most-superbowl-players-by-college',
			au: 'sports/most-superbowl-players-by-college',
			al: 'sports/most-superbowl-players-by-college',
			az: 'sports/most-superbowl-players-by-college',
			ba: 'sports/most-superbowl-players-by-college',
			bb: 'sports/most-superbowl-players-by-college',
			be: 'sports/most-superbowl-players-by-college',
			bg: 'sports/most-superbowl-players-by-college',
			bh: 'sports/most-superbowl-players-by-college',
			bm: 'sports/most-superbowl-players-by-college',
			bo: 'sports/most-superbowl-players-by-college',
			br: 'sports/most-superbowl-players-by-college',
			bs: 'sports/most-superbowl-players-by-college',
			ca: 'sports/most-superbowl-players-by-college',
			ch: 'sports/most-superbowl-players-by-college',
			ci: 'sports/most-superbowl-players-by-college',
			cl: 'sports/most-superbowl-players-by-college',
			co: 'sports/most-superbowl-players-by-college',
			cr: 'sports/most-superbowl-players-by-college',
			cu: 'sports/most-superbowl-players-by-college',
			cv: 'sports/most-superbowl-players-by-college',
			cz: 'sports/most-superbowl-players-by-college',
			de: 'sports/most-superbowl-players-by-college',
			dk: 'sports/most-superbowl-players-by-college',
			do: 'sports/most-superbowl-players-by-college',
			dz: 'sports/most-superbowl-players-by-college',
			ec: 'sports/most-superbowl-players-by-college',
			ee: 'sports/most-superbowl-players-by-college',
			eg: 'sports/most-superbowl-players-by-college',
			es: 'sports/most-superbowl-players-by-college',
			fi: 'sports/most-superbowl-players-by-college',
			fj: 'sports/most-superbowl-players-by-college',
			fr: 'sports/most-superbowl-players-by-college',
			gf: 'sports/most-superbowl-players-by-college',
			gg: 'sports/most-superbowl-players-by-college',
			gh: 'sports/most-superbowl-players-by-college',
			gi: 'sports/most-superbowl-players-by-college',
			gq: 'sports/most-superbowl-players-by-college',
			gr: 'sports/most-superbowl-players-by-college',
			gt: 'sports/most-superbowl-players-by-college',
			hk: 'sports/most-superbowl-players-by-college',
			hn: 'sports/most-superbowl-players-by-college',
			hr: 'sports/most-superbowl-players-by-college',
			hu: 'sports/most-superbowl-players-by-college',
			id: 'sports/most-superbowl-players-by-college',
			ie: 'sports/most-superbowl-players-by-college',
			il: 'sports/most-superbowl-players-by-college',
			in: 'sports/most-superbowl-players-by-college',
			iq: 'sports/most-superbowl-players-by-college',
			is: 'sports/most-superbowl-players-by-college',
			it: 'sports/most-superbowl-players-by-college',
			jm: 'sports/most-superbowl-players-by-college',
			jo: 'sports/most-superbowl-players-by-college',
			jp: 'sports/most-superbowl-players-by-college',
			ke: 'sports/most-superbowl-players-by-college',
			kr: 'sports/most-superbowl-players-by-college',
			kw: 'sports/most-superbowl-players-by-college',
			lb: 'sports/most-superbowl-players-by-college',
			lc: 'sports/most-superbowl-players-by-college',
			li: 'sports/most-superbowl-players-by-college',
			lt: 'sports/most-superbowl-players-by-college',
			lv: 'sports/most-superbowl-players-by-college',
			ly: 'sports/most-superbowl-players-by-college',
			ma: 'sports/most-superbowl-players-by-college',
			mc: 'sports/most-superbowl-players-by-college',
			md: 'sports/most-superbowl-players-by-college',
			mk: 'sports/most-superbowl-players-by-college',
			mt: 'sports/most-superbowl-players-by-college',
			mu: 'sports/most-superbowl-players-by-college',
			mx: 'sports/most-superbowl-players-by-college',
			my: 'sports/most-superbowl-players-by-college',
			mz: 'sports/most-superbowl-players-by-college',
			ne: 'sports/most-superbowl-players-by-college',
			ng: 'sports/most-superbowl-players-by-college',
			nl: 'sports/most-superbowl-players-by-college',
			no: 'sports/most-superbowl-players-by-college',
			nz: 'sports/most-superbowl-players-by-college',
			om: 'sports/most-superbowl-players-by-college',
			pa: 'sports/most-superbowl-players-by-college',
			pe: 'sports/most-superbowl-players-by-college',
			pf: 'sports/most-superbowl-players-by-college',
			ph: 'sports/most-superbowl-players-by-college',
			pk: 'sports/most-superbowl-players-by-college',
			pl: 'sports/most-superbowl-players-by-college',
			ps: 'sports/most-superbowl-players-by-college',
			pt: 'sports/most-superbowl-players-by-college',
			py: 'sports/most-superbowl-players-by-college',
			qa: 'sports/most-superbowl-players-by-college',
			ro: 'sports/most-superbowl-players-by-college',
			rs: 'sports/most-superbowl-players-by-college',
			ru: 'sports/most-superbowl-players-by-college',
			sa: 'sports/most-superbowl-players-by-college',
			sc: 'sports/most-superbowl-players-by-college',
			se: 'sports/most-superbowl-players-by-college',
			sg: 'sports/most-superbowl-players-by-college',
			si: 'sports/most-superbowl-players-by-college',
			sk: 'sports/most-superbowl-players-by-college',
			sm: 'sports/most-superbowl-players-by-college',
			sn: 'sports/most-superbowl-players-by-college',
			sv: 'sports/most-superbowl-players-by-college',
			tc: 'sports/most-superbowl-players-by-college',
			th: 'sports/most-superbowl-players-by-college',
			tn: 'sports/most-superbowl-players-by-college',
			tr: 'sports/most-superbowl-players-by-college',
			tt: 'sports/most-superbowl-players-by-college',
			tw: 'sports/most-superbowl-players-by-college',
			ug: 'sports/most-superbowl-players-by-college',
			uk: 'sports/most-superbowl-players-by-college',
			us: 'sports/most-superbowl-players-by-college',
			uy: 'sports/most-superbowl-players-by-college',
			va: 'sports/most-superbowl-players-by-college',
			ve: 'sports/most-superbowl-players-by-college',
			xk: 'sports/most-superbowl-players-by-college',
			ye: 'sports/most-superbowl-players-by-college',
			za: 'sports/most-superbowl-players-by-college',
			zm: 'most-superbowl-players-by-college',
		},
		meta: { tab: 'landingpage', collectionType: CollectionType.SPORTS },
	},
	'app.landingpage.expansionseasonsmls': {
		component: ExpansionSeasonsMLS,
		urls: {
			us: 'sports/best-expansion-season-mls',
		},
		meta: { tab: 'landingpage', collectionType: CollectionType.SPORTS },
	},
	'app.landingpage.nbaplayoffs': {
		component: NBAPlayoffs,
		urls: {
			us: 'sports/nba-players-most-games-not-making-playoffs',
		},
		meta: { tab: 'landingpage', collectionType: CollectionType.SPORTS },
	},
	'app.landingpage.statistics': {
		component: StatisticPage,
		urls: {
			us: 'netflix-statistics',
		},
		meta: { tab: 'landingpage' },
	},

	'app.landingpage.subscriberform': {
		component: SubscriberForm,
		urls: {
			bb: 'insight-mails',
			bm: 'insight-mails',
			bs: 'insight-mails',
			gi: 'insight-mails',
			jm: 'insight-mails',
			us: 'insight-mails',
		},
		meta: { tab: 'landingpage', data: 'subscriberform' },
	},
	'app.landingpage.curatedlist': {
		component: CuratedList,
		urls: {
			li: 'DavidHain',
			de: 'DavidHain',
		},
		meta: { tab: 'landingpage', collectionType: CollectionType.TITLELIST, hasFilterBar: true },
	},

	// POPULAR SUBGNERES
	'app.titles.popular.subgenre': {
		urls: {
			us: 'lists/:subgenre',
		},
		component: Popular,
		meta: {
			tab: 'subgenre',
			contexts: ['filter'],
			collectionType: CollectionType.POPULAR,
			hasFilterBar: true,
			pageType: PageType.SUBGENRE_POPULAR,
		},
		beforeEnter: beforeEnterSubgenres,
	},
	'app.titles.popular.subgenre-movie': {
		urls: {
			us: 'lists/:subgenre/movies',
		},
		component: Popular,
		meta: {
			tab: 'subgenre',
			contexts: ['filter'],
			collectionType: CollectionType.POPULAR,
			hasFilterBar: true,
			pageType: PageType.SUBGENRE_POPULAR_MOVIES,
			initialRouteState: { content_types: [TitleObjectType.MOVIE] },
		},
		beforeEnter: beforeEnterSubgenres,
	},
	'app.titles.popular.subgenre-show': {
		urls: {
			us: 'lists/:subgenre/tv-shows',
		},
		component: Popular,
		meta: {
			tab: 'subgenre',
			contexts: ['filter'],
			collectionType: CollectionType.POPULAR,
			hasFilterBar: true,
			pageType: PageType.SUBGENRE_POPULAR_TVSHOWS,
			initialRouteState: { content_types: [TitleObjectType.SHOW] },
		},
		beforeEnter: beforeEnterSubgenres,
	},
};

const globalPressRoute = {
	path: '/press',
	name: 'app.landingpage.global-press',
	component: GlobalPress,
};

export function makeAppRoutes(webLocale: WebLocale, store: Store<any>) {
	const routes: VueRouteConfig[] = [];

	for (const name in routeConfig) {
		const rawRoute = <RouteConfig>routeConfig[name as RouteNames];

		// skip the route config item if it doesn't have an url for the current webLocale
		if (rawRoute.urls[webLocale] != null) {
			const encodedPath = `/${webLocale}/${encodePath(rawRoute.urls[webLocale] || '')}`;
			const rawPath = `/${webLocale}/${rawRoute.urls[webLocale] || ''}`;
			routes.push({
				name,
				path: encodedPath,
				alias: encodedPath !== rawPath ? rawPath : undefined,
				component: rawRoute.component,
				...(rawRoute.beforeEnter && { beforeEnter: (...args) => rawRoute.beforeEnter(...args, store) }),
				meta: { ...rawRoute.meta },
			});
		}

		if (rawRoute.redirect?.[webLocale] != null) {
			const encodedRedirectPath = `/${webLocale}/${encodePath(rawRoute.redirect[webLocale] || '')}`;
			const rawRedirectPath = `/${webLocale}/${rawRoute.redirect[webLocale] || ''}`;
			routes.push({
				path: encodedRedirectPath,
				alias: encodedRedirectPath !== rawRedirectPath ? rawRedirectPath : undefined,
				redirect: `/${webLocale}/${encodePath(rawRoute.urls[webLocale] || '')}`,
			});
		}
	}

	routes.push(globalPressRoute);

	return routes;
}

/**
 * used to determine the initial routing state (FilterCollection) by its routeName alone, without parameters.
 * d.g. app.titles.popular.movie => { content_types: ['movie'] }
 */
export const initialRouteState = Object.keys(routeConfig).reduce((root, key) => {
	const route = <RouteConfig>routeConfig[key as RouteNames];
	root[key as RouteNames] = route.meta?.initialRouteState ?? {};
	return root;
}, {} as Record<RouteNames, Partial<FilterCollection>>);

// encodes path with encodeURI but skips ":" that is used for parameters
function encodePath(path: string) {
	return path
		.split(':')
		.map(part => encodeURI(part))
		.join(':');
}
