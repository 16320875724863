
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import StyleInjector from '@/components/StyleInjector.vue';
import AppFooter from '@/components/footer/AppFooter.vue';
import AppHeader from './components/nav/AppHeader.vue';
import ConstantsProvider from '@/constants/ConstantsProvider.vue';
import ListsCountProvider from '@/constants/ListsCountProvider.vue';
// import ClickoutAnchor from '@/components/nav/links/ClickoutAnchor.vue';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

import { useUserStats } from '@/helpers/composables/useUserStats';

import {
	RegisterSessionStartDocument,
	RegisterSessionStartMutation,
	RegisterSessionStartMutationVariables,
} from '@/graphql/mutation/RegisterSessionStart.mutation';

const Experiments = () => import(/* webpackChunkName: "experiments" */ '@/components/experiments/Experiments.vue');
const LowPriorityComponents = () =>
	import(/* webpackChunkName: "low-priority-components" */ '@/components/LowPriorityComponents.vue');
const LoginFlow = () => import(/* webpackChunkName: "login-flow" */ '@/components/login/LoginFlow.vue');

import { CollectionType } from '@/enums/collection-type';

// @ts-ignore
import LazyHydrate from 'vue-lazy-hydration';
// @ts-ignore
import NoSSR from 'vue-no-ssr';

// make NoSSR a globaly available component
Vue.component('NoSSR', NoSSR);
Vue.component('LazyHydrate', LazyHydrate);

// AppFooter global to avoid CLS
Vue.component('AppFooter', AppFooter);

// SSR Content
import ContentSSR from '@/components/ContentSSR.vue';
// eslint-disable-next-line
Vue.component('Content', ContentSSR);

// Vue.component('ClickoutAnchor', ClickoutAnchor);

import '@/styles/global.scss';
import { Platform } from '@/@types/graphql-types';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { enableScroll } from './helpers/scroll-helper';
import { until } from '@vueuse/core';
import { UrlMetadataResponse } from '@/interfaces/responses/url-metadata';

const Language = namespace('language');
const Meta = namespace('meta');
const Routing = namespace('routing');
const User = namespace('user');
@Component({
	name: 'SSRApp',
	components: {
		AppHeader,
		Experiments,
		LowPriorityComponents,
		StyleInjector,
		LoginFlow,
		ConstantsProvider,
		ListsCountProvider,
	},
	metaInfo() {
		return this.head;
	},
})
export default class App extends Vue {
	@Language.Getter locale: string;
	@Meta.Getter head: any;
	@Routing.Getter lastActiveCollectionType: null | CollectionType;
	@Routing.Getter urlMetadata: UrlMetadataResponse | null;
	@User.State jwId: string;

	async mounted() {
		const { initStoreWatchers } = await import(/* webpackChunkName: "main.common" */ '@/main.common');
		initStoreWatchers(this.$context);

		const { initCoreWebVitalsMonitor } = await import('@/helpers/tracking/cwv-helper');
		initCoreWebVitalsMonitor();

		Vue.nextTick(() => {
			const int = setInterval(() => {
				// remove loader
				const loader = document.querySelector('#loader');
				loader?.parentNode?.removeChild(loader);
				clearInterval(int);
			}, 50);

			// reactivate scroll on page rendered
			enableScroll();
		});

		const { TrackingHelper } = await import('@/helpers/tracking/tracking-helper');
		TrackingHelper.isInitializedPromise.then(async () => {
			const { loading, permanentAudiences } = useUserStats();
			// we wait until the user stats query is done
			await until(loading).toBe(false);
			TrackingHelper.refreshPermanentAudiences(permanentAudiences.value);

			// route.fullPath is for Home as first page, which has incomplete urlMetadata
			if (this.urlMetadata?.full_path || this.$route?.fullPath) {
				const audienceRefreshContext = await TrackingHelper.getAudienceContext(permanentAudiences.value);

				const fullPath = this.urlMetadata?.full_path ?? this.$route.fullPath;

				TrackingHelper.trackAudienceRefresh(fullPath, audienceRefreshContext);
			}
		});

		//need to initialize PASubgenres for it to populate trackers as SEO user
		TrackingHelper.isInitializedPromise.then(() => TrackingHelper.refreshPermanentAudiencesSubgenres([]));

		try {
			this.$apollo.mutate<RegisterSessionStartMutation, RegisterSessionStartMutationVariables>({
				mutation: RegisterSessionStartDocument,
				variables: {
					input: {
						platform: Platform.Web,
					},
				},
			});
		} catch (err: any) {
			const error: Error = err;

			captureMessageForSentry('SSR App: Unable to fire Register Session Start Mutation', {
				where: '[SSRApp.vue] mounted() ',
				error,
			});
		}
	}

	get hideAppHeader() {
		return this.$route.name === 'app.streaming-charts';
	}
}
